import React, { Component, useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import { useEffectOnce } from "../../hooks/useEffectOnce";

import Flickity from "react-flickity-component";
import "../../webflow/flickity.css";

import "../../webflow/img-slider.css";

class ImgSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    const marginHorizontal = 10;
    const marginVertical = 5;
    return (
      <div
        {...props}
        style={{
          transform: "translateZ(0)",
          pointerEvents: "none",
          width: "30%",
          minWidth: "300px",
          maxWidth: "500px",
          paddingTop: `${marginVertical}px`,
          aspectRatio: 4 / 5,
          paddingLeft: `${marginHorizontal}px`,
          paddingRight: `${marginHorizontal}px`,
        }}
      >
        <div
          className="img-slider-img"
          style={{ backgroundImage: `url("${props.src}")` }}
        />
      </div>
    );
  }
}

const HomeVideoSlider = React.forwardRef((props, ref) => {
  const mainSlider = useRef(null);
  const bulletPoints = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  // const pauseAutoplay = () => {
  //   mainSlider.current.slickPause();
  // };

  // const resumeAutoplay = () => {
  //   mainSlider.current.slickPlay();
  // };

  const tickerSpeed = window.innerWidth / 1000;
  const isPaused = useRef(false);

  const update = () => {
    if (isPaused.current) return;
    if (mainSlider.current && mainSlider.current.slides) {
      // mainSlider.current.on("dragStart", (evt) => {
      //   evt.preventDefault();
      //   evt.stopPropagation();
      // });
      mainSlider.current.x =
        (mainSlider.current.x - tickerSpeed) %
        mainSlider.current.slideableWidth;
      mainSlider.current.selectedIndex =
        mainSlider.current.dragEndRestingSelect();
      mainSlider.current.updateSelectedSlide();
      mainSlider.current.settle(mainSlider.current.x);
    }
    window.requestAnimationFrame(update);
  };

  const pause = (evt) => {
    // evt.preventDefault();
    // evt.stopPropagation();
    isPaused.current = true;
  };

  const play = () => {
    if (isPaused.current) {
      isPaused.current = false;
      // window.requestAnimationFrame(update);
    }
  };

  const flickityOptions = {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,

    draggable: true,
    freeScroll: true,
    // wrapAround: true,
    contain: true,
    selectedAttraction: 0.015,
    friction: 0.25,

    initialIndex: 0,
  };

  const myCustomNext = () => {
    // You can use Flickity API

    mainSlider.current.next();
  };

  const requestRef = useRef(null);

  // useEffectOnce(() => {
  //   requestRef.current = requestAnimationFrame(update);

  //   return () => {
  //     cancelAnimationFrame(requestRef.current);
  //   };
  // }, [mainSlider.current]); // Make sure the effect runs only once

  return (
    <div
      className="preventFullscreenSwipe"
      style={{
        position: "relative",
        pointerEvents: "all",
        width: "100%",

        marginLeft: "-20px", //Make sure Slider aligns on the left (contradict slide padding)
        ...(props.credits && { marginBottom: "18px" }),
      }}
      onMouseEnter={pause}
      onTouchMove={pause}
      onMouseMove={pause}
      onMouseLeave={play}
      onTouchEnd={play}
      // onDragStart={(evt) => {
      //   evt.stopPropagation();
      //   console.log(evt);
      // }}
      onClick={pause}
      ref={ref}
    >
      <Flickity
        options={flickityOptions}
        flickityRef={(c) => (mainSlider.current = c)}
      >
        {props.data.map((entry, i) => (
          <ImgSlide key={entry} src={entry} />
        ))}
        {/*     {props.data.map((entry, i) => (
          <ImgSlide src={entry} />
        ))}
        {props.data.map((entry, i) => (
          <ImgSlide src={entry} />
        ))}*/}
      </Flickity>

      <div
        style={{
          position: "absolute",
          right: 0,
          textAlign: "right",
        }}
      >
        <h6>{props.credits}</h6>
      </div>
    </div>
  );
});

export default HomeVideoSlider;
