import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";

import "./CompLeaderboard.css";

import useStateRef from "react-usestateref";
import { gsap } from "gsap";

const PlayerScore = (props) => {
	const curScoreRef = useRef(0);
	const scoreDisplayRef = useRef(null);
	const [isCounting, setIsCounting] = useState(false);

	const updateScore = () => {
		scoreDisplayRef.current.innerText = `${
			Intl.NumberFormat("de-DE").format(parseInt(curScoreRef.current)) ??
			"0"
		}`;
	};
	useEffect(() => {
		gsap.to(curScoreRef, {
			current: props.score,
			duration: 0.5,
			onUpdate: updateScore,
			onStart: () => {
				setIsCounting(true);
			},
			onComplete: () => {
				setIsCounting(false);
			},
			overwrite: "all",
		});
		//update score
	}, [props.score]);

	return (
		<div
			className={`complication-leaderboard-heading score${
				isCounting ? " counting" : ""
			}`}
			style={{}}
			ref={scoreDisplayRef}
		>
			{`${Intl.NumberFormat("de-DE").format(curScoreRef.current) ?? "0"}`}
		</div>
	);
};

const CompLeaderboard = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	const [playerScoreState, setPlayerScoreState, playerScoreStateRef] =
		useStateRef([
			props["player-score-0"],
			props["player-score-1"],
			props["player-score-2"],
		]);

	const curTime = useRef(0);

	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
	};

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function

	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useEffect(() => {
		//dataValidation(state);
	}, [playerScoreState]);
	const addScore = (newAdd = [0, 0, 0]) => {
		setPlayerScoreState([
			playerScoreStateRef.current[0] + newAdd[0],
			playerScoreStateRef.current[1] + newAdd[1],
			playerScoreStateRef.current[2] + newAdd[2],
		]);
	};
	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline();
			animationTimeline.current.add("start");
			animationTimeline.current.add(
				gsap.fromTo(
					curTime,
					{ current: 0 },
					{
						duration: props.duration,
						ease: "none",
						current: props.duration,
					},
				),
			);

			animationTimeline.current.call(
				setPlayerScoreState,
				[
					[
						props["player-score-0"],
						props["player-score-1"],
						props["player-score-2"],
					],
				],
				"start",
			);

			animationTimeline.current.call(addScore, [[0, 500, 50]], "+=1");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=1.2");
			animationTimeline.current.call(addScore, [[1500, 0, 0]], "+=1.5");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=1.6");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=1.8");
			animationTimeline.current.call(addScore, [[1500, 0, 0]], "+=2");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=2");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=2.2");
			animationTimeline.current.call(addScore, [[0, 0, 50]], "+=2.4");
			animationTimeline.current.call(addScore, [[0, 550, 150]], "+=3");

			animationTimeline.current.add("end");
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	return (
		<>
			<div
				className={`complication-el${
					props.transparent ? " transparent" : ""
				}${props.align ? " align-" + props.align : ""}`}
				ref={complicationsRef}
			>
				<div className="complication-title-cnt center">
					<div className="complication-leaderboard-title">
						{props.title || "N/A"}
					</div>
				</div>
				<div className="compilation-slider-cnt">
					<div className="complications-status-cnt">
						<div className="complication-slider-txt-cnt">
							<div className="comp-leaderboard-entry">
								<div className="complication-leaderboard-heading">
									{`1. ${props["player-name-0"] ?? "N/A"}`}
								</div>
								<div className="complication-leaderboard-heading score">
									<PlayerScore score={playerScoreState[0]} />
								</div>
							</div>
							<div className="comp-leaderboard-entry">
								<div className="complication-leaderboard-heading">
									{`2. ${props["player-name-1"] ?? "N/A"}`}
								</div>
								<div className="complication-leaderboard-heading score">
									<PlayerScore score={playerScoreState[1]} />
								</div>
							</div>
							<div className="comp-leaderboard-entry">
								<div className="complication-leaderboard-heading">
									{`3. ${props["player-name-2"] ?? "N/A"}`}
								</div>
								<div className="complication-leaderboard-heading score">
									<PlayerScore score={playerScoreState[2]} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export default CompLeaderboard;
