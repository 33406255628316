import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	QuoteEl,
} from "../../components/Elements/ArticleEl";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import { isMobile } from "../../hooks/isMobile";
import ImageElement from "../../components/Elements/ImageElement";
import BtnParallax from "../../components/Elements/BtnParallax";
import cases from "../cases/cases.json";

const componentName = "MercedesNewFrontiers";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">A real-time car shoot</Header>
				<Desc customType="desc">
					<White>Powered by Unreal Engine</White>, this production
					defies reality's constraints. With instant adjustments and
					real-time possibilities, it gives
					<White>
						{" "}
						a glimpse into the future of automotive advertising.
					</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? null : "33%"}
					customType="image"
					round
					flex
					backgroundPosition={"center"}
					backgroundSize={"cover"}
					ar={isMobile ? 1 : 3 / 4}
					src={`/img/cases/${shorthand}/image/img_03.jpg`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Achievements are a matter of perspective."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",

					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					round
					aspectratio={isMobile ? 3 / 4 : null}
					alt={`Image showing a filmstill from Mercedes New Frontiers`}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Film"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>We invited a professional dancer</White> to record
					his signature moves with our motion capture system and
					seamlessly{" "}
					<White>
						integrated the captured data with Unreal Engine's
						physically accurate car simulation.
					</White>{" "}
					<br />
					<br />
					This resulted in the G63 realistically responding to each
					movement, while{" "}
					<White>
						a human-operated virtual camera captured the cinematic
						experience.
					</White>{" "}
				</div>
			</div>
		</div>

		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Unreal Engine.
						<br />
						Automotive.
						<br />
						Motion Capture.
					</div>
				</div>
				<div className="section-description">
					<White>
						Thanks to the nature of real-time, we were able to{" "}
						achieve fast turnaround times
					</White>{" "}
					and maintain the creative working environment.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					round
				/>
			</div>
		</div>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Postproduction</div>
							<div className="credits-name">
								colors made in germany
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
