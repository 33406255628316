import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	QuoteEl,
	Quote,
	Author,
} from "../../components/Elements/ArticleEl";

import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import ImageElement from "../../components/Elements/ImageElement";
import VideoElement from "../../components/Elements/VideoElement";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageSlider from "../../components/Elements/ImageSlider";
import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "CampariFelliniForward";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					An AI-Powered <p>Short Film</p>
				</Header>
				<Desc customType="desc">
					Long before ChatGPT was the next big thing -{" "}
					<White>
						Campari's AI-film collaboration Fellini Forward{" "}
					</White>
					resurrects the genius of one of the most influential
					filmmakers of the 20th century: Federico Fellini. Supported
					by members of Fellini's original crew, this world-first
					short film{" "}
					<White>
						blends tech innovation with the filmmaker's legacy.{" "}
					</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? null : "33%"}
					customType="image"
					round
					flex
					backgroundSize={"cover"}
					ar={isMobile ? 16 / 9 : 3 / 4}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_02.jpg`
							: `/img/cases/${shorthand}/image/img_05.jpg`
					}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Can AI Direct Movies? This One Just Did."
				</Quote>
				<Author customType="author">
					<White>- pcmag.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Campari assembles original crew to create Fellini short
					film using AI."
				</Quote>
				<Author customType="author">
					<White>- thedrum.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Experience the future of cinema, as Campari has created the
					first short film made with artificial intelligence inspired
					by the creative genius of Fellini."
				</Quote>
				<Author customType="author">
					<White>- koreaherald.com</White>
				</Author>
			</QuoteEl>
		</QuoteSliderSlick>
		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={isMobile ? 1 : 2.35 / 1}
					round
					shorthand={"fellini-preview"}
					resolution={"720p"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Case"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>Fellini Forward is a unique art experiment</White>{" "}
					and as part of the Campari Red Dairies series, it set out to
					create a short film in collaboration between human and AI,
					<White>
						{" "}
						built upon the creative genius of one of the greatest
						filmmakers of the 20th Century, Federico Fellini.
					</White>{" "}
					<br />
					<br />
					It took two years of development and independent validation
					by AI experts and members of Fellini's original crew to
					finally be released during a screening in Venice.
				</div>
			</div>
		</div>

		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/slider_img_05.jpg`,
						`/img/cases/${shorthand}/image/slider_img_02.jpg`,
						`/img/cases/${shorthand}/image/slider_img_03.jpg`,
						`/img/cases/${shorthand}/image/slider_img_04.jpg`,
						`/img/cases/${shorthand}/image/slider_img_01.jpg`,
						`/img/cases/${shorthand}/image/slider_img_06.jpg`,
					]}
				/>
			</div>{" "}
		</ArticleElBackgroundWrapper>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Ping-pong collaboration.
					</div>
				</div>
				<div className="section-description">
					The creation process involved a dynamic and collaborative
					exchange between human creativity and a specially developed
					AI toolkit.{" "}
					<White>
						We were responsible to work alongside the AI to craft
						the script, capture hours of motion data to feed into
						the system, and finally direct the AI's decision-making
						during a live-action shoot in Rome.
					</White>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					aspectratio={2.4}
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					round
					alt={`Award grid of FIVE MINUTES achievements`}
				/>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						AI human collaboration.
						<br />
						Award winning.
						<br />
						2.1 Bln impressions.
					</div>
				</div>
				<div className="section-description">
					<White>
						{" "}
						The project received a wide news echo and was multiple
						awarded for it's creative approach and innovative use of
						artificial intelligence.{" "}
					</White>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_04.jpg`}
					round
					aspectratio={2 / 1}
					alt={`Award grid of FIVE MINUTES achievements`}
				/>
			</div>
		</div>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Agency</div>
							<div className="credits-name">
								Wunderman Thompson
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brand</div>
							<div className="credits-name">Campari</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">UNIT9</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Creative Direction
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
