import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

const sec2time = (timeInSeconds) => {
	var pad = function (num, size) {
			return ("000" + num).slice(size * -1);
		},
		time = parseFloat(timeInSeconds).toFixed(3),
		hours = Math.floor(time / 60 / 60),
		minutes = Math.floor(time / 60) % 60,
		seconds = Math.floor(time - minutes * 60),
		milliseconds = time.slice(-3);

	return (
		pad(minutes, 2) +
		":" +
		pad(seconds, 2) +
		"." +
		pad(Math.floor(milliseconds / 62) * 62, 2)
	);
};

const CompPlayback = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	//restart Function

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function
	const reframe = () => {
		gsap.set(complicationsRef.current, { x: props.offsetX || 0 });
		gsap.set(complicationsRef.current, { y: props.offsetY || 0 });
	};
	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY]);

	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline();
			animationTimeline.current.add("start");
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	return (
		<div
			className={`complication-el ${
				props.transparent ? " transparent" : ""
			}${props.align ? " align-" + props.align : ""}`}
			ref={complicationsRef}
		>
			<div className="complication-title-cnt">
				<div className="complication-logo playback" />
				<div className="complication-title purple">
					{props.title || "N/A"}
				</div>
			</div>
			<div className="compilation-row">
				<div className="complication-title">{props.song || "N/A"}</div>
			</div>
			<div className="compilation-row">
				<div className="complication-title grey">
					{props.artist || "N/A"}
				</div>
			</div>
		</div>
	);
});

export default CompPlayback;
