import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	QuoteEl,
	Quote,
	Author,
} from "../../components/Elements/ArticleEl";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import ImageElement from "../../components/Elements/ImageElement";
import VideoElement from "../../components/Elements/VideoElement";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageSlider from "../../components/Elements/ImageSlider";
import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "ShowcaseVirtualInfluencer";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					A fresh take on influencer marketing
				</Header>
				<Desc customType="desc">
					<White>Virtual influencers,</White> owned by brands and
					created with real-time technology in an innovative pipeline
					to produce content <White>at the speed of culture.</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? "100%" : "25%"}
					customType="image"
					round
					flex
					backgroundSize={"cover"}
					backgroundPosition={"center"}
					ar={isMobile ? 16 / 9 : 1}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_01.jpg`
							: `/img/cases/${shorthand}/image/img_05.jpg`
					}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Influencer marketing meets the digital age."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>
		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={null}
					round
					shorthand={"virtualinfluencer-preview"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Case"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>
						Virtual influencers took the fashion and beauty industry
						by storm.
					</White>{" "}
					These digital avatars have the potential to become the
					ultimate trendsetters, tastemakers, and style icons of the
					next generation. <br />
					<br />
					<White>
						By utilizing Unreal Engine, Motion Capture and
						Artificial Intelligence we overcome the current
						limitations of content creation
					</White>{" "}
					and produce stunning visuals quickly and efficiently.
				</div>
			</div>
		</div>
		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/slider_img_05.jpg`,
						`/img/cases/${shorthand}/image/slider_img_06.jpg`,
						`/img/cases/${shorthand}/image/slider_img_02.jpg`,
						`/img/cases/${shorthand}/image/slider_img_01.jpg`,
						`/img/cases/${shorthand}/image/slider_img_03.jpg`,
						`/img/cases/${shorthand}/image/slider_img_04.jpg`,
					]}
				/>
			</div>
		</ArticleElBackgroundWrapper>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Unreal Engine.
						<br />
						Real-time.
						<br />
						Social Media.
					</div>
				</div>
				<div className="section-description">
					Immediate feedback and adjustments lead to faster project
					completion and turnaround times and{" "}
					<White>empower brands to</White> easily participate into the
					latest social trends and{" "}
					<White>be the hype makers of the next-generation.</White>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					round
				/>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Agency</div>
							<div className="credits-name">UNIT9</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Postproduction</div>
							<div className="credits-name">
								colors made in germany
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
