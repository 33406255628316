import React, {
	useRef,
	useEffect,
	useState,
	useContext,
	useImperativeHandle,
} from "react";

import useOnScreen from "../../hooks/useOnScreen";
import {
	getAdditionalVideo,
	getAdditionalVideoPoster,
} from "../../data/additional-video/getAdditionalVideoHelper";
import { useEffectOnce, destroyVideo } from "../../hooks/useEffectOnce";

import placeholder from "../../img/placeholder.png";

const VideoElement = React.forwardRef((props, ref) => {
	const vidRef = useRef(null);
	const isVisible = useOnScreen(vidRef);
	const [isLoaded, setIsLoaded] = useState(false);

	const onProgress = (evt) => {
		if (!isLoaded) {
			setIsLoaded(true);
		}
	};

	useEffectOnce(() => {
		const vidEl = vidRef.current;
		vidEl.addEventListener("timeupdate", onProgress);

		// vidEl.onprogress = function () {
		// 	alert("Downloading video");
		// };
		return () => {
			vidEl.removeEventListener("timeupdate", onProgress);
			destroyVideo(vidEl);
		};
	});

	useEffect(() => {
		if (vidRef.current) {
			if (isVisible) {
				const playPromise = vidRef.current.play();

				if (playPromise !== undefined) {
					playPromise
						.then((_) => {
							// Automatic playback started!
							// Show playing UI.
						})
						.catch((error) => {
							setIsLoaded(true);
							// Auto-play was prevented
							// Show paused UI.
						});
				}
			} else {
				vidRef.current.pause();
			}
		}
	}, [isVisible]);
	return (
		<>
			<div
				className={`preloader-shimmer`}
				style={{
					// background: "rgba(255,255,255,.1)",

					// opacity: 1,
					width: "100%",
					height: "auto",
					position: "absolute",
					top: 0,
					left: 0,
					aspectRatio: 16 / 9,
					borderRadius: 25,
					...(props.aspectratio && {
						objectFit: "cover",
						aspectRatio: props.aspectratio,
						...(props.centerh
							? {
									transform: "translateX(50%)",
							  }
							: null),
					}),

					...(props.customscale && {
						width: `${100 * (props.customscale ?? 1)}%`,
						height: `${100 * (props.customscale ?? 1)}%`,
					}),

					display: props.nopreloader || isLoaded ? "none" : null,
				}}
			></div>
			<video
				className={`article-video${props.round ? " round" : ""}${
					props.blockfade
						? ""
						: isLoaded
						? " fadeout fadein"
						: " fadeout"
				}${props.homeslider ? " homeslider" : ""}`}
				style={{
					objectFit: "cover",
					aspectRatio: 16 / 9,
					...(props.aspectratio && {
						width: `${100 * (props.customscale ?? 1)}%`,
						height: `${100 * (props.customscale ?? 1)}%`,
						objectFit: "cover",
						aspectRatio: props.aspectratio,
						...(props.centerh
							? {
									transform: "translateX(50%)",
							  }
							: null),
					}),

					padding: 0,
					margin: 0,
				}}
				muted
				playsInline
				loop
				preload={props.preload || "none"}
				src={
					props.shorthand
						? getAdditionalVideo(props.shorthand, props.resolution)
						: props.src
				}
				poster={
					props.poster ||
					getAdditionalVideoPoster(props.shorthand, props.resolution)
				}
				ref={vidRef}
			></video>
		</>
	);
});

export default React.memo(VideoElement);
