import cases from "./cases.json";

export function getCaseVideo(shorthand, type, resolution = "1080p") {
	const isOfflineMode = process.env.REACT_APP_OFFLINE === "offline_mode";

	if (isOfflineMode) {
		return `/offline/batch/cases/${shorthand}/${type}-${resolution}.mp4`;
	} else {
		const videoData = cases.find((x) => x.shorthand === shorthand);

		return videoData?.[`${type}-${resolution}`] ?? "";
	}
}

export function getCaseVideoPoster(shorthand, type) {
	return "";
	// const videoData = cases.find((x) => x.shorthand === shorthand);

	// return videoData?.[`poster`] ?? "";
}
