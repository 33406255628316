import React, { useRef, useContext, useState, useEffect } from "react";
import { gsap } from "gsap";

import { Route, useLocation, Routes } from "react-router-dom";

import Home from "./Home";

import Contact from "./Contact";
import PrivacyPolicy from "./PrivacyPolicy";
import Solutions from "./Solutions";
import AdminMenu from "./AdminMenu";

import CookieConsent from "./components/CookieConsent";
import Header from "./components/Header";
import SEO from "./components/SEO";

import { Link } from "react-router-dom";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { deviceType } from "detect-it";

import { GlobalContext } from "./index";

import { useEffectOnce } from "./hooks/useEffectOnce";
import { disableScroll, enableScroll } from "./hooks/preventScroll";
import useLocalStorage from "./hooks/useLocalStorage";
import useImage from "./hooks/useImage";
import { sendGAPageView, sendGACustomEvent } from "./hooks/googleAnalytics";

import caseData from "./data/cases/cases.json";
import welcomeVideoData from "./data/welcome-video/welcomeVideo.json";

import { useDebounce } from "./hooks/useDebounce";

gsap.registerPlugin(DrawSVGPlugin);

gsap.config({
  // autoSleep: 60,
  force3D: true,
  nullTargetWarn: true,
  trialWarn: false,
  // units: { left: "%", top: "%", rotation: "rad" },
});

const App = React.forwardRef((props, ref) => {
  let location = useLocation();

  const solutionsRef = useRef(null);

  const prevRoute = useRef(["", Date.now()]);
  const [keySuffix, setKeySuffix] = useState(Date.now());

  const routes = [
    { path: "/admin", name: "Config", Component: AdminMenu },
    {
      path: "/get-in-touch",
      name: "Get in Touch",
      Component: Contact,
    },
    {
      path: "/privacy",
      name: "Privacy Policy",
      Component: PrivacyPolicy,
    },
      {
      path: "/cases/*",
      name: "Cases",
      Component: Solutions,
      ref: solutionsRef,
      key: "/cases",
    },
    //  /kickz-mooncourt 
    ...caseData.map((entry) => {
      return {
        path: `/${entry.shorthand}`,
        name: `${entry.name}`,
        Component: Solutions,
        ref: solutionsRef,
        key: "/cases",
      };
    }),
    //  /showcase/kickz-mooncourt
    ...caseData.map((entry) => {
      return {
        path: `/showcase/${entry.shorthand}`,
        name: `${entry.name}`,
        Component: Solutions,
        ref: solutionsRef,
        key: "/cases",
      };
    }),
    { path: "/*", name: "Landing", Component: Home },
  ];

  const [globalContext, setGlobalContext] = useContext(GlobalContext);

  const [windowBounds, setWindowBounds] = useState({ ww: -1, wh: -1 });

  const [appView, setAppView] = useState(null);

  const circleRef = useRef(null);
  const headerRef = useRef(null);
  // const asideRef = useRef();
  // const footerRef = useRef();

  const initLoad = useRef(false);
  const initLoadCallback = useRef(null);

  const circleRefSVG = useRef();
  const mousePos = useRef({ x: 0, y: 0 });

  const [resizeTime, setResizeTime] = useState(Date.now());
  const debResizeTime = useDebounce(resizeTime, 500);

  const [fullAccess, setFullAccess] = useLocalStorage("full-access", true);

  const [SEOInfo, setSEOInfo] = useState({
    title: "Stunning Studio",
    description:
      "We craft tomorrow's social experiences and ads in real-time today. Based in Hamburg, Germany.",
    creator: "",
    type: "",
    image: process.env.PUBLIC_URL + "/img/seo/default.jpg",
  });

  const handleUpdateSEO = (location) => {
    const locationPath = location?.pathname || "";
    const maybeShorthand = locationPath.split("/")?.at(-1);

    // Check if starts with cases
    const curCase = caseData.find(
      (caseItem) => caseItem?.shorthand === maybeShorthand,
    );

    const isCase = curCase?.name?.length;
    const isSolutions = locationPath === "/cases/solutions";
    const isWork = locationPath === "/cases/work";
    const isCases = locationPath === "/cases";

    const isGetInTouch = locationPath === "/get-in-touch";
    const isPrivacy = locationPath === "/privacy";

    let pageName = "";
    let description =
      "We craft tomorrow's social experiences and ads in real-time today. Based in Hamburg, Germany.";
    let image = process.env.PUBLIC_URL + "/img/seo/default.jpg";

    let canonical = `${locationPath}`;

    if (isGetInTouch) {
      pageName = "Get in Touch";
    } else if (isPrivacy) {
      //image = "/img/seo/seo-works.jpg";
      pageName = "Imprint & Privacy Policy";
    } else if (isCase) {
      pageName = curCase?.name;
      canonical = `/${curCase?.shorthand}`;
      image =
        process.env.PUBLIC_URL +
        `/img/cases/${curCase?.shorthand}/poster/${curCase?.shorthand}-poster-landscape-1600p.jpg`;
    } else if (isWork || isSolutions || isCases) {
      if (isSolutions) {
        pageName = "Solutions";
      } else if (isWork) {
        pageName = "Work";
      } else if (isCases) {
        pageName = "Cases";
      }
    }

    if (location.pathname.startsWith("/welcome-")) {
      // Try to find corresponding image

      const curWelcomeVideoData = welcomeVideoData.find(
        (x) => x.shorthand === location?.pathname.replaceAll("/", ""),
      );

      if (
        curWelcomeVideoData &&
        curWelcomeVideoData["poster-landscape-1600p"]
      ) {
        image =
          process.env.PUBLIC_URL +
          `/img/cases/welcome-video/poster/${curWelcomeVideoData["poster-landscape-1600p"]}.jpg`;

        const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";
        description = `Hey ${capitalize(
          curWelcomeVideoData["name"],
        )}, nice to meet you.`;
      }
    }

    setSEOInfo({
      ...SEOInfo,
      ...{
        title: `${pageName?.length ? pageName + " - " : ""}Stunning Studio`,
      },
      ...{ description },
      ...{ image },
      ...{ url: locationPath },
      ...{ canonical },
    });
  };

  useEffect(() => {
    if (location.pathname == "/welcome") {
      setFullAccess(true);
    } else if (
      location.pathname.startsWith("/welcome-") ||
      location.pathname.includes("/showcase/") //Change in Solutions.js aswell
    ) {
      setFullAccess(true);

      // disableScroll();
      // initLoadCallback.current = () => {
      //   setTimeout(() => {
      //     window.scrollTo({
      //       top: -500,
      //       behavior: "smooth",
      //     });
      //     document
      //       .querySelector("#welcome-video")
      //       .scrollIntoView({ behavior: "smooth", block: "center" });
      //     enableScroll();
      //   }, 3000);
      // };
    }
    handleUpdateSEO(location);

    //sendGAPageView(globalContext, location.pathname);

    sendGACustomEvent(globalContext, "p", location.pathname);
  }, [location]);

  useEffectOnce(() => {
    console.log("mount app");
    const setPosition = (e) => {
      const xPosition = e.clientX;
      const yPosition = e.clientY;

      mousePos.current = { x: xPosition, y: yPosition };
    };
    window.addEventListener("mousemove", setPosition);
    window.addEventListener("resize", onResize);
    onResize();

    gsap.set(circleRef.current, { opacity: 0 });

    //Every tick
    gsap.ticker.add(updatePosition);
    gsap.from(circleRefSVG.current, {
      stroke: "rgba(255, 255, 255,0)",
      drawSVG: "0%",
      duration: 2,
      overwrite: true,
      delay: 0.5,
    });

    //fix for preview browsers e.g. instagram
    setTimeout(() => {
      onResize();
    }, 300);

    setGlobalContext({
      // window: windowBounds,
      // initLoad: initLoading,
      changeCursorState: changeCursorState,
      // changeHeaderState: changeHeaderState,
      // changeView: changeView,
      // appView: appView,
      // deviceType: deviceType,
    });
    console.log(globalContext);

    return () => {
      window.removeEventListener("mousemove", setPosition);
      window.removeEventListener("resize", onResize);
      console.log("unmount app");
    };
  });

  const initLoading = () => {
    if (!initLoad.current) {
      console.log("loooad");

      initLoad.current = true;

      initLoadCallback.current?.();
    }
  };

  const changeCursorState = (state) => {
    // console.log(state);
    gsap.killTweensOf(circleRefSVG.current);
    switch (state) {
      case "active":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(241, 3, 136,1)",
          drawSVG: "100% 0%",
          duration: 0.25,
          overwrite: true,
        });
        break;
      case "idle":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(255, 255, 255,1)",
          drawSVG: "100% 0%",

          duration: 0.25,
          overwrite: true,
        });
        break;
      case "none":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(255, 255, 255,1)",
          drawSVG: "0% 0%",
          duration: 0.25,
          overwrite: true,
        });
        break;
      case "load":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(241, 3, 136,1)",

          drawSVG: "0% 0%",
          duration: 0.15,
          overwrite: true,

          onComplete: () => {
            gsap.to(circleRefSVG.current, {
              duration: 1.75,
              ease: "ease.out",
              drawSVG: "100% 0%",
              onComplete: () => {
                gsap.to(circleRefSVG.current, {
                  stroke: "rgba(255, 255, 255,1)",
                  duration: 2,
                  ease: "back.out(4)",
                });
              },
            });
          },
        });

        break;
      default:
        break;
    }
  };

  const changeHeaderState = (state, data = null) => {
    headerRef.current?.changeHeaderState?.(state, data);
  };

  const closeSearch = () => {
    headerRef.current?.closeSearch?.();
  };

  const isSearchOpen = () => {
    return headerRef.current?.isSearchOpen?.();
  };

  const setFilter = (filter) => {
    solutionsRef.current?.setFilter?.(filter);
  };

  const changeView = (view) => {
    // console.log("NEW VIEW", view);
    setAppView(view);
  };

  const updatePosition = () => {
    if (mousePos.current.x === 0 && mousePos.current.y === 0) return;
    gsap.to(circleRef.current, {
      x: mousePos.current.x - 20,
      y: mousePos.current.y - 20,
      opacity: 1,
      duration: 0.3,
    });
  };

  const onResize = () => {
    setResizeTime(Date.now());
    return;
  };

  useEffect(() => {
    let ih = window.innerHeight * 0.01;

    //set ih to have innerHeight for css calculation
    document.documentElement.style.setProperty("--ih", `${ih}px`);
    const ww = window.innerWidth;
    const wh = window.innerHeight;

    setWindowBounds({ ww: ww, wh: wh });
  }, [debResizeTime]);

  if (
    (prevRoute.current?.[0].startsWith("/cases") &&
      !location.pathname.startsWith("/cases")) ||
    (!prevRoute.current?.[0].startsWith("/cases") &&
      location.pathname.startsWith("/cases"))
  ) {
    prevRoute.current[0] = location.pathname;
    prevRoute.current[1] = Date.now();
  }

  useEffect(() => {
    //Handle Back Button
    window.onpopstate = () => {
      setKeySuffix(Date.now());
    };
  });
  return (
    <div className="body">
      <SEO
        title={SEOInfo.title}
        description={SEOInfo.description}
        creator={SEOInfo.creator}
        type={SEOInfo.type}
        image={SEOInfo.image}
      />

      {/*      <div className="footer" style={{ opacity: 0 }} ref={footerRef}>
        <Link
          to="/privacy"
          className={
            location.pathname === "/privacy" ? "disabled-link" : "cookie"
          }
        >
          Legal Notice
        </Link>
      </div>*/}
      <div
        className="progress-wrap"
        style={{ display: deviceType === "mouseOnly" ? "block" : "none" }}
        ref={circleRef}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-8 -8 116 116"
        >
          <path
            ref={circleRefSVG}
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          />
        </svg>
      </div>
      <Header ref={headerRef} setFilter={setFilter} />
      <Routes>
        {routes.map(({ path, Component, key, ref }) => (
          <Route
            key={path}
            path={path}
            element={
              <Component
                globalContext={globalContext}
                key={`${key ?? path}_${keySuffix}`}
                cKey={`${key ?? path}_${keySuffix}`}
                currentPathname={location.pathname}
                window={windowBounds}
                initLoad={initLoading}
                changeCursorState={changeCursorState}
                changeHeaderState={changeHeaderState}
                closeSearch={closeSearch}
                isSearchOpen={isSearchOpen}
                changeView={changeView}
                appView={appView}
                deviceType={deviceType}
                ref={ref}
              />
            }
          />
        ))}
      </Routes>
      <CookieConsent debug={false} />
    </div>
  );
});
export default App;
