import React from "react";

import { isMobileOrTablet } from "../../hooks/isMobile";
import bg_lines from "../../img/bg_lines.svg";

import { maxWidth } from "./ArticleEl";
import TopologyBackground from "./TopologyBackground";

export const ArticleElBackgroundWrapper = (props) => {
	const margin = props.custommargin || isMobileOrTablet ? 50 : 150;
	let variant = props.variant;

	switch (variant) {
		case "article_top":
			variant = "background_legacy";
			break;
		case "article_slider":
			variant = "background_legacy";
			break;
	}
	let outEl = (
		<div
			className="section-div no-p"
			style={{
				marginTop: props.customtopmargin || margin,
				position: "relative",
				paddingTop: margin,
				paddingBottom: props.custompaddingbottom || margin,
				paddingLeft: 0,
				paddingRight: 0,
				maxWidth: "none",
				...maxWidth(props.size),

				overflowX: "clip",
			}}
		>
			<TopologyBackground
				{...props}
				variant={variant}
				cover={isMobileOrTablet ? false : true}
				width={props.width ?? 4}
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
					opacity: 0.3,
					...props.style,
				}}
			/>

			{props.children}
		</div>
	);
	return outEl;
};
