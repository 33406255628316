import React, {
	useRef,
	useEffect,
	useState,
	useContext,
	useImperativeHandle,
} from "react";

import { gsap } from "gsap";

import { useCookies } from "react-cookie";
import { useDebounce } from "../../hooks/useDebounce";

const SwipeDecision = React.forwardRef((props, ref) => {
	const [leftImage, setLeftImage] = useState(props.leftImage);
	const [rightImage, setRightImage] = useState(props.rightImage);
	const leftImageDebounced = useDebounce(leftImage, 500);
	const rightImageDebounced = useDebounce(rightImage, 500);

	useEffect(() => {
		setLeftImage(props.leftImage);
	}, [props.leftImage]);
	useEffect(() => {
		setRightImage(props.rightImage);
	}, [props.rightImage]);

	const debug = true;
	const [cookies, setCookie] = useCookies(["privacy_consent"]);
	const isCookiesAccepted = cookies.privacy_consent === "accept_all";

	const [cookieSwipeTutorial, setCookieSwipeTutorial] = useCookies([
		"swipe_tutorial",
	]);

	const isCookiesSwipeTutorialDone =
		cookieSwipeTutorial.swipe_tutorial === "done";

	const containerRef = useRef(null);
	const mainContentRef = useRef(null);

	const boxLeftRef = useRef(null);
	const boxRightRef = useRef(null);

	const currentAvailable = useRef([true, true]);

	const [blurBg, setBlurBg] = useState(false);
	const fade = (dur = 0, value) => {
		if (blurBg && value === 0) {
			setBlurBg(false);
		}
		gsap.to(containerRef.current, dur, { autoAlpha: value });
	};

	const animatePosition = (dur = 0, position) => {
		const leftAvailable = currentAvailable.current[0];
		const rightAvailable = currentAvailable.current[1];

		const positionAvailable =
			(position < 0 && rightAvailable) || (position > 0 && leftAvailable);

		const pipeBoxLeft = gsap.utils.pipe(
			gsap.utils.clamp(0, 1),
			gsap.utils.mapRange(0, 1, -102, 0),
		);

		const pipeBoxRight = gsap.utils.pipe(
			gsap.utils.clamp(-1, 0),
			gsap.utils.mapRange(-1, 0, 0, 102),
		);

		const pipeMainContent = gsap.utils.pipe(
			gsap.utils.clamp(-1, 1),
			gsap.utils.mapRange(-1, 1, -15, 15),
		);

		gsap.killTweensOf(boxLeftRef.current);
		gsap.killTweensOf(boxRightRef.current);
		gsap.killTweensOf(mainContentRef.current);

		gsap.to(boxLeftRef.current, dur, {
			x: pipeBoxLeft(leftAvailable ? position : 0) + "%",
		});
		gsap.to(boxRightRef.current, dur, {
			x: pipeBoxRight(rightAvailable ? position : 0) + "%",
		});
		gsap.to(mainContentRef.current, dur, {
			xPercent: pipeMainContent(
				position * (positionAvailable ? 1 : 0.25),
			),
		});
	};

	useEffect(() => {
		animatePosition(0, 0);

		if (!isCookiesSwipeTutorialDone || debug) {
			//disabled
			// setBlurBg(true);
			// fade(0.5, 1);
			// if (isCookiesAccepted)
			// 	setCookieSwipeTutorial("swipe_tutorial", "done", { path: "/" });
		}
	}, []);

	const setCurrentAvailable = (availableArray) => {
		currentAvailable.current = availableArray;
	};

	useImperativeHandle(ref, () => {
		return {
			fade(dur, value) {
				fade(dur, value);
			},
			setPosition(position) {
				animatePosition(0, position);
			},
			animatePosition(dur, position) {
				animatePosition(dur, position);
			},
			setCurrentAvailable(availableArray) {
				setCurrentAvailable(availableArray);
			},
		};
	});

	return (
		<div
			className="mobile-swipe-decision"
			ref={containerRef}
			style={{
				background: "rgba(0,0,0,0)",
				zIndex: 1999,
				opacity: 0,
			}}
		>
			<div
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					backgroundColor: "rgba(0,0,0,0.85)",
					...(blurBg && { backgroundColor: "rgba(0,0,0,0.25)" }),
				}}
			/>
			<div className="mobile-swipe-img">
				<div
					className={`swipe-to-nav-row  ${blurBg ? " blur-bg" : ""}`}
					style={{ ...(blurBg && { background: "none" }) }}
				>
					<div className="swipe-cnt">
						<div
							className="swipe-to-nav-box left"
							ref={boxLeftRef}
							style={{
								backgroundImage: `url("${leftImageDebounced}")`,
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						>
							<div className="swipe-divider left"></div>
							{/*<div className="swipe-to-nav-icon left"></div>*/}
						</div>
						<div
							className="swipe-to-nav-box"
							ref={boxRightRef}
							style={{
								backgroundImage: `url("${rightImageDebounced}")`,
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						>
							<div className="swipe-divider"></div>
							{/*<div className="swipe-to-nav-icon"></div>*/}
						</div>
						<div className="swipe-to-nav-item" ref={mainContentRef}>
							<div className="swipe-to-nav-content-center">
								<div className="section-description router">
									Swipe navigation
								</div>
								<div className="section-title-sub router">
									Swipe to discover more
								</div>
								<div className="section-router-spacer"></div>
								<div className="router-img swipe"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default React.memo(SwipeDecision);
