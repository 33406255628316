import React, {
	useImperativeHandle,
	useRef,
	useEffect,
	useState,
	useContext,
} from "react";
import { useCookies } from "react-cookie";

import { gsap, Power2 } from "gsap";

import { GlobalContext } from "../../index";
import { isTouchDevice } from "../../hooks/utils";

import { buttonGradient } from "./BtnParallax";
const BtnParallax = React.forwardRef((props, ref) => {
	const [refresh, setRefresh] = useState(Date.now);
	const innerRef = useRef(null);
	const btnOverlay = useRef(null);
	const btnOutline = useRef(null);

	const btnSelectorRef = useRef(null);
	const selectionDisabled = useRef(true);

	const currentSelect = useRef(-1);
	const btn01Ref = useRef(null);
	const btn02Ref = useRef(null);

	const [context, setContext] = useContext(GlobalContext);

	const parallaxIt = (e, parent, target, movement, mobile) => {
		var boundingRect = parent.getBoundingClientRect();
		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		var relX = pageX - boundingRect.left;
		var relY = pageY - boundingRect.top;

		var movementX =
			((relX - boundingRect.width / 2) / boundingRect.width) * movement;
		var movementY =
			((relY - boundingRect.height / 2) / boundingRect.height) * movement;

		const nextSelectState = relX > boundingRect.width / 2 ? 1 : 0;
		changeSelect(nextSelectState);

		const selectItemsArray = target.querySelectorAll(".btnslider-option");

		var relXMove =
			((relX / boundingRect.width) * 2 -
				(nextSelectState == 1 ? 1.5 : 0.5)) *
			movement;

		if (selectItemsArray.length == 2) {
			let resetItem = nextSelectState === 1 ? 0 : 1;

			gsap.to(selectItemsArray[nextSelectState], {
				duration: 0.3,
				overwrite: "auto",
				x: mobile
					? gsap.utils.clamp(
							nextSelectState === 1
								? -boundingRect.height / 5
								: -boundingRect.height / 5,
							nextSelectState === 1
								? boundingRect.height / 5
								: boundingRect.height / 5,
							relXMove,
					  )
					: relXMove,
				y: mobile ? 0 : movementY,
				// rotationX: (movementY / 3) * 1,
				// rotationY: (-movementX / 3) * 1,
				ease: Power2.easeOut,
			});

			gsap.to(selectItemsArray[resetItem], {
				duration: 0.3,
				overwrite: "auto",
				x: 0,
				y: 0,
				rotationX: 0,
				rotationY: 0,
				ease: Power2.easeOut,
			});
		}

		const comp_vid = document.querySelectorAll(
			`#comp-vid-${nextSelectState}`,
		);

		if (comp_vid.length > 0) {
			gsap.to("#comp-vid-" + nextSelectState, {
				duration: 0.3,
				x: mobile
					? gsap.utils.clamp(-20, 20, relXMove * 2)
					: relXMove * 2,
				y: mobile ? 0 : movementY * 2,
				// rotationX: (movementY / 3) * 2.5,
				// rotationY: (-movementX / 3) * 2.5,
				ease: "none",
			});
		}
	};

	const changeSelect = (nextSelectState) => {
		if (
			nextSelectState === currentSelect.current &&
			!selectionDisabled.current
		)
			return;
		currentSelect.current = nextSelectState;

		let nextPosition = -1;
		switch (nextSelectState) {
			case -1:
				break;
			case 0:
				nextPosition = 0;
				break;
			case 1:
				nextPosition = 100;
				break;
		}

		props.onmousemove?.(nextSelectState);

		gsap.killTweensOf(btnSelectorRef.current);
		if (nextPosition !== -1) {
			gsap.to(
				btnSelectorRef.current,
				selectionDisabled.current ? 0 : 0.3,
				{
					xPercent: nextPosition,
					autoAlpha: 1,
				},
			);
			selectionDisabled.current = false;
		}
	};

	const callParallax = (e, parent, mobile = false) => {
		parallaxIt(e, parent, parent, 20, mobile);
	};

	const mouseEnterBtn = (e) => {
		//e.preventDefault();
		props.onmousemove?.(currentSelect.current);
		context?.changeCursorState?.("idle");
		//this.props.changeCursorState("active");
		// this.activeHover = true;
		// this.activeHoverEl = e.target;

		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(btnOutline.current, {
			duration: 0.3,
			background: buttonGradient(1),
		});

		gsap.to(targetQuery, {
			duration: 0.3,
			scale: 1.1,
			overwrite: "auto",
			opacity: 1,
			x: 1,
			y: 1,
			rotationX: 1,
			rotationY: 1,
		});

		gsap.to(btnSelectorRef.current, 0.3, { autoAlpha: 1 });
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,
				overwrite: "auto",
				autoAlpha: 1,
			});
		}
		callParallax(e, e.target.querySelector(".btn-parallax"), true);
	};

	const disableSelection = () => {
		console.log("disableSelection");
		selectionDisabled.current = true;
		gsap.to(btnSelectorRef.current, 0.3, { autoAlpha: 0 });
	};

	const mouseLeaveBtn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.onmouseleave?.(e);
		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(btnOutline.current, {
			duration: 0.3,
			background: buttonGradient(0.25, true),
		});
		gsap.to(targetQuery, {
			duration: 0.3,
			rotationX: 0,
			rotationY: 0,
			x: 0,
			y: 0,
			scale: 1,
		});

		const selectItemsArray =
			targetQuery.querySelectorAll(".btnslider-option");

		gsap.to(selectItemsArray, {
			duration: 0.3,
			overwrite: "auto",
			x: 0,
			y: 0,
			rotationX: 0,
			rotationY: 0,
		});

		disableSelection();
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,

				autoAlpha: 0,
			});
		}
	};

	const mouseMoveBtn = (e) => {
		e.preventDefault();

		callParallax(e, e.target.querySelector(".btn-parallax"));
	};

	const preventAction = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const onClick = (e) => {
		var boundingRect = e.target.getBoundingClientRect();
		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		var relX = pageX - boundingRect.left;
		var relY = pageY - boundingRect.top;

		const nextSelectState = relX > boundingRect.width / 2 ? 1 : 0;
		changeSelect(nextSelectState);
		console.log("click", nextSelectState);
		props.onclick?.(nextSelectState);
	};

	const touchStart = (e) => {
		mouseEnterBtn(e);
	};

	const touchMove = (e) => {
		e.stopPropagation();
		callParallax(e, e.target.querySelector(".btn-parallax"), true);
	};

	useEffect(() => {
		if (isTouchDevice() && !props.isCurrentlyHovered) {
			disableSelection();
		}
	}, [props.isCurrentlyHovered]);

	const setText = (text, targetIndex = 0) => {
		const targets = [btn01Ref.current, btn02Ref.current];

		const selTarget = targets[targetIndex];
		if (selTarget) selTarget.innerHTML = text;
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				setText(text, index = 0) {
					setText(text, index);
				},
			};
		},
		[],
	);

	//Force Button to calculate bounds for before element
	useEffect(() => {
		const targets = innerRef.current.querySelector(".btn-parallax");
		gsap.to(targets, {
			x: 0,
			y: 0,
		});
	}, []);

	return (
		<>
			<div
				className="btn-parallax-container small-p landing"
				onTouchStart={isTouchDevice() ? touchStart : preventAction}
				onTouchMove={isTouchDevice() ? touchMove : preventAction}
				onTouchEnd={isTouchDevice() ? mouseLeaveBtn : preventAction}
				onMouseMove={isTouchDevice() ? preventAction : mouseMoveBtn}
				onMouseEnter={isTouchDevice() ? preventAction : mouseEnterBtn}
				onMouseLeave={isTouchDevice() ? preventAction : mouseLeaveBtn}
				//onClick={onClick}
				style={{
					cursor: "none",
					marginLeft: props.nomargin ? "none" : "auto",
					marginRight: props.nomargin ? "none" : "auto",
					pointerEvents: props.disabled ? "none" : "auto",
				}}
				ref={innerRef}
			>
				<div
					className={`btn-parallax landing btn-slider${
						props.addclass ? " " + props.addclass : ""
					} parallaxBtnOutline`}
					style={{
						opacity: props.defaulthide ? "0" : "1",
						visibility: props.defaulthide ? "hidden" : "inherit",
						border: "none",

						backgroundColor: props.filled
							? "rgba(241,3,136,1)"
							: null,
						pointerEvent: "none",
					}}
				>
					<div
						style={{
							position: "absolute",
							left: 0,
							right: 0,
							bottom: 0,
							top: 0,

							borderRadius: 300,

							border: "3px solid transparent",
							background: buttonGradient(0.25, true),
							WebkitMask: `linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)`,
							WebkitMaskComposite: "destination-out",
							maskComposite: "exclude",
						}}
						ref={btnOutline}
					/>
					<div className="btnslider-cnt">
						<div
							className="btnslider-selector"
							ref={btnSelectorRef}
							style={{ opacity: 0 }}
						>
							<div className="btnslider-selector-circle"></div>
						</div>
					</div>
					<div className="btnslider-option" ref={btn01Ref} style={{}}>
						{props.manualtext
							? null
							: props.activeSlideData?.["vid-title-0"]}
					</div>
					<div className="btnslider-option" ref={btn02Ref}>
						{props.manualtext
							? null
							: props.activeSlideData?.["vid-title-1"]}
					</div>
				</div>
			</div>
		</>
	);
});

export default React.memo(BtnParallax);
