import React, { useMemo, memo, useState } from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import VideoElement from "../../components/Elements/VideoElement";
import ImageElement from "../../components/Elements/ImageElement";
import ImageSlider from "../../components/Elements/ImageSlider";

import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import BtnParallax from "../../components/Elements/BtnParallax";

import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "KickzMooncourt";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);

export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			{/*<div
				className="section-div no-p"
				style={{
					marginTop: "100px",
					position: "relative",
					backgroundImage: `url("${bg_lines}")`,
					backgroundSize: "cover",
					paddingBottom: "150px",
					maxWidth: "none",
				}}
			>*/}
			<ArtileEl
				type={"text-right-half"}
				size={"xl"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					<div className="section-hero">
						<div className="section-title-sub grad-txt">
							An intergalactic ecosystem
						</div>
					</div>
				</Header>
				<Desc customType="desc">
					<White>
						Mooncourt Ballin is a world-first digital game
						experience uniting culture, gaming, and retail.
					</White>{" "}
					Featuring an outer space basketball tournament it is{" "}
					<White>
						a platform for brands to easily interact with Ballers
						and the Fortnite community.
					</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? "100%" : "25%"}
					customType="image"
					round
					flex
					backgroundSize={"cover"}
					backgroundPosition={"center"}
					ar={isMobile ? 16 / 9 : 1}
					outline={isMobile ? false : true}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_02.jpg`
							: `/img/cases/${shorthand}/image/img_01.jpg`
					}
				/>
			</ArtileEl>
			{/*</div>*/}
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"By uniting the baller community with the gaming world, we
					not only merge these two vibrant cultures but also offer
					brands an exclusive opportunity to join this newly created
					culturally relevant space."
				</Quote>
				<Author customType="author">
					<White>
						- Denis Horvat, <i> Co-CEO KICKZ </i>
						{/*{- Toan Nguyen, <i> CEO Jung von Matt Nerd</i>}*/}
					</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"With our world-first retail initiative we bring brands
					together - @KICKZ, @BMW, @PUMA, @KELLOGG'S and @JEFF
					HAMILTON prove that collaboration is the key to success in
					this dynamic digital landscape."
				</Quote>
				<Author customType="author">
					<White>
						- Toan Nguyen, <i> CEO Jung von Matt NERD</i>
					</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Fast-paced, action-packed, and competitive. That's what
					Mooncourt Ballin' is all about. We wanted gamers to feel
					like they're right there in the heat of a real basketball
					game with their friends."
				</Quote>
				<Author customType="author">
					<White>
						- Christine Niemann, <i>Co-Founder Stunning Studio</i>
						{/*{- Toan Nguyen, <i> CEO Jung von Matt Nerd</i>}*/}
					</White>
				</Author>
			</QuoteEl>
		</QuoteSliderSlick>
		{/*	<ArtileEl type={"text-right-half"} size={"m"} center>
			<Header customType="header">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						An intergalactic ecosystem
					</div>
				</div>
			</Header>
			<Desc customType="desc">
				<White>
					'Mooncourt Ballin' is a world-first digital game experience
					uniting culture, gaming, and retail.
				</White>{" "}
				Featuring an outer space basketball tournament it is{" "}
				<White>
					a platform for brands to easily interact with Ballers and
					the Fortnite community.
				</White>
			</Desc>

			<CustomImage
				maxWidth={isMobile ? "100%" : "25%"}
				customType="image"
				round={isMobile ? false : true}
				flex
				backgroundSize={"cover"}
				backgroundPosition={"center"}
				ar={isMobile ? 16 / 9 : 1}
				outline={isMobile ? false : true}
				src={
					isMobile
						? `/img/cases/${shorthand}/image/img_02.jpg`
						: `/img/cases/${shorthand}/image/img_01.jpg`
				}
			/>
		</ArtileEl>*/}
		{/*		<QuoteEl size="m" no-p-quote>
			<Quote customType="quote">
				"Fast-paced, action-packed, and competitive.
				<br />
				That's what Mooncourt Ballin' is all about. We wanted gamers to
				feel like they're right there in the heat of a real basketball
				game with their friends."
			</Quote>
			<Author customType="author">
				<White>
					- Maximilian Niemann, <i> Creative Director</i>
				</White>
			</Author>
		</QuoteEl>*/}
		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={isMobile ? 3 / 4 : 2.35 / 1}
					round
					shorthand={"mooncourt-preview"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Case"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					With the launch of Fortnite Creative 2.0 in March 2023,{" "}
					<White>KICKZ came up with an ambitious goal: </White>
					{"  "}
					<White>Build an entire playful ecosystem</White> and run a
					business model that is self-financing in the long term,{" "}
					<White>
						leveraging the possibilities of the Fortnite Editor.
					</White>
					<br />
					<br />
					Together with Jung von Matt NERD we introduced{" "}
					<White>
						'Mooncourt Ballin' - an intergalactic basketball
						tournament on an outer space court.
					</White>{" "}
					The first-of-its-kind game mechanics make players become
					basketballs themselves before they enter a five-hoop space
					court.
					<br />
					<br />
					<White>
						Permanently changing game modes are pushing players to
						skillfully out-dunk their opponents.
					</White>{" "}
					Only spaceproof dunkers learn to truly{" "}
					<White>
						master the strategical use of the branded power-ups to
						climb the first place on the podium.
					</White>{" "}
				</div>
			</div>
		</div>

		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/slider_KICKZ.jpg`,
						`/img/cases/${shorthand}/image/slider_BMW.jpg`,
						`/img/cases/${shorthand}/image/slider_Kellog.jpg`,
						`/img/cases/${shorthand}/image/slider_Puma.jpg`,
						`/img/cases/${shorthand}/image/slider_JeffHamilton.jpg`,
					]}
				/>
			</div>
		</ArticleElBackgroundWrapper>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						The World's First Multibrand Fortnite Island
					</div>
				</div>
				<div className="section-description">
					<White>
						A KICKZ tailored experience that isn't just about one
						brand – but about five
					</White>
					, with room for countless more collaborators down the line.{" "}
					<White>It's about revolutionizing retail</White> and tapping
					into the pulse of the community through culture, style, and
					groundbreaking gameplay.
				</div>
			</div>
		</div>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Digital Twins</Header>
			<Desc customType="desc">
				Each product from our partner brands has been carefully
				integrated into the game.{" "}
				<White>A tailor-made asset pipeline</White> that seamlessly
				merges photogrammetry and traditional 3D modeling,{" "}
				<White>achieved breathtaking results.</White>
			</Desc>
			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"mooncourt-pumascan"}
			/>
		</ArtileEl>
		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Firsthand Product Experience</Header>
			<Desc customType="desc">
				<White>Game-changing in-game power-ups</White> have been
				developed to empower players,{" "}
				<White>
					allowing them to enhance their scores and directly engage
					with the products.
				</White>{" "}
				Making players faster, better and stronger.
			</Desc>

			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"mooncourt-firsthand"}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Content Hub</Header>
			<Desc customType="desc">
				Utilizing our bespoken created assets{" "}
				<White>
					we established a Unreal Engine centric content hub,
				</White>{" "}
				consolidating our efforts to craft multiple cinematics, spots,
				and dynamic video assets{" "}
				<White>
					for social media, web platforms, and promotional materials -
				</White>{" "}
				all achieved with remarkable real-time efficiency.
			</Desc>
			<VideoElement
				customType="video"
				round
				aspectratio={1}
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"mooncourt-grid"}
			/>
		</ArtileEl>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Next Steps: Season 2.
					</div>
				</div>
				<div className="section-description">
					<White>'Mooncourt Ballin' launched with a big-bang,</White>{" "}
					organically featured in FORTNITE's <i>BEST OF UEFN</i>{" "}
					spotlight. And while players around the world are able to
					directly engage with all partner brands,{" "}
					<White>KICKZ is preparing the next season, </White>looking
					forward to welcoming the brands of the world on the
					Mooncourt.
				</div>
			</div>
			<div className="section-img" style={{ width: "100%" }}>
				<VideoElement
					aspectratio={isMobile ? 16 / 9 : 2.35 / 1}
					round
					shorthand={"mooncourt-result"}
				/>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Agency</div>
							<div className="credits-name">
								Jung von Matt NERD
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brands</div>
							<div className="credits-name">
								KICKZ, BMW, Kellogg&#x27;s, Puma &amp; Jeff
								Hamilton
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Postproduction</div>
							<div className="credits-name">
								colors made in germany
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
