import React, {
	useImperativeHandle,
	useRef,
	useEffect,
	useState,
	useContext,
} from "react";
import { useCookies } from "react-cookie";

import { gsap, Power2 } from "gsap";

import { GlobalContext } from "../../index";
import { isTouchDevice } from "../../hooks/utils";

export const buttonGradient = (opacity = 1, inverted = false) => {
	return inverted
		? `linear-gradient(245deg,rgb(255, 255, 255, ${opacity}) 0%,rgb(255, 255, 255, ${opacity}) 100%) border-box`
		: `linear-gradient(245deg,rgb(31, 137, 219, ${opacity}) 0%,rgb(241, 3, 136, ${opacity}) 100%) border-box`;
};

const BtnParallax = React.forwardRef((props, ref) => {
	const [refresh, setRefresh] = useState(Date.now);

	const btnOverlay = useRef(null);
	const btnText = useRef(null);
	const innerRef = useRef(null);

	const [context, setContext] = useContext(GlobalContext);

	const parallaxIt = (e, parent, target, movement) => {
		var boundingRect = parent.getBoundingClientRect();

		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		var relX = pageX - boundingRect.left;
		var relY = pageY - boundingRect.top;

		var movementX =
			((relX - boundingRect.width / 2) / boundingRect.width) * movement;
		var movementY =
			((relY - boundingRect.height / 2) / boundingRect.height) * movement;

		gsap.to(target, {
			duration: 0.3,
			overwrite: "auto",
			x: movementX,
			y: movementY,
			rotationX: (movementY / 3) * 2.5,
			rotationY: (-movementX / 3) * 2.5,
			ease: Power2.easeOut,
		});
	};

	const callParallax = (e, parent) => {
		parallaxIt(e, parent, parent, 20);
	};

	const mouseEnterBtn = (e) => {
		if (!props.blockcursorchange) context?.changeCursorState?.("none");
		e.preventDefault();
		props.onmouseenter?.(e);

		// this.props.changeCursorState("active");
		// this.activeHover = true;
		// this.activeHoverEl = e.target;

		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(targetQuery, {
			background: buttonGradient(1),
			...(props.invert ? { borderColor: "rgba(241,3,136,1)" } : {}),
			duration: 0.3,
			scale: 1.1,
			overwrite: "auto",
			opacity: 1,
			x: 1,
			y: 1,
			rotationX: 1,
			rotationY: 1,
		});
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,
				overwrite: "auto",
				autoAlpha: 1,
			});
		}
	};

	const mouseLeaveBtn = (e) => {
		e.preventDefault();
		props.onmouseleave?.(e);
		context?.changeCursorState?.("idle");
		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(targetQuery, {
			background: props.filled ? buttonGradient(1) : buttonGradient(0),
			...(props.invert
				? { borderColor: "rgba(255, 252, 250, .25)" }
				: {}),
			duration: 0.3,
			rotationX: 0,
			rotationY: 0,
			x: 0,
			y: 0,
			scale: 1,
		});
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,

				autoAlpha: 0,
			});
		}
	};

	const mouseMoveBtn = (e) => {
		e.preventDefault();
		props.onmousemove?.(e);

		callParallax(e, e.target.querySelector(".btn-parallax"));
	};

	const preventAction = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const setText = (text, targetIndex = 0) => {
		// console.log("text", text);
		const targets = [btnText.current];

		const selTarget = targets[targetIndex];
		if (selTarget ?? false) selTarget.innerHTML = text;
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				setText(text, index = 0) {
					setText(text, index);
				},
				innerRef: innerRef,
			};
		},
		[],
	);

	//Force Button to calculate bounds for before element
	useEffect(() => {
		const targets = innerRef.current.querySelector(".btn-parallax");
		gsap.to(targets, {
			x: 0,
			y: 0,
		});
	}, []);

	return (
		<div
			className={`btn-parallax-container${
				props.small ? " small-p" : ""
			} ${props.nav ? " nav" : ""}${
				props.landing ? " landing" : ``
			} mag-cursor`}
			// onTouchMove={preventAction}
			onMouseMove={isTouchDevice() ? preventAction : mouseMoveBtn}
			onMouseEnter={isTouchDevice() ? preventAction : mouseEnterBtn}
			onMouseLeave={isTouchDevice() ? preventAction : mouseLeaveBtn}
			onClick={props.onclick}
			style={{
				marginLeft: props.nomargin ? "none" : "auto",
				marginRight: props.nomargin ? "none" : "auto",
				pointerEvents: props.disabled ? "none" : "auto",
			}}
			ref={innerRef}
		>
			{
				{
					default: (
						<div
							className={`btn-parallax${
								props.addclass ? " " + props.addclass : ""
							}${props.landing ? " landing" : ""}`}
							style={{
								opacity: props.defaulthide ? "0" : "1",
								visibility: props.defaulthide
									? "hidden"
									: "inherit",
								border: "none",
								background: props.filled
									? buttonGradient(1)
									: buttonGradient(0),
							}}
						>
							<div
								style={{
									position: "absolute",
									left: 0,
									right: 0,
									bottom: 0,
									top: 0,

									borderRadius: 300,

									border: "3px solid transparent",
									background: buttonGradient(1),
									WebkitMask: `linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)`,
									WebkitMaskComposite: "destination-out",
									maskComposite: "exclude",
								}}
							/>
							<div style={{ padding: 3, display: "flex" }}>
								{props.filmicon ? (
									<div className="circle-x play-film-icon">
										<div
											className="circle-x play-film-icon overlay"
											style={{
												opacity: 0,
												visibility: "hidden",
											}}
											ref={btnOverlay}
										/>
									</div>
								) : null}
								<div
									className="btn-parallax-text"
									ref={btnText}
								>
									{props.manualtext ? null : props.text}
									{props.content}
								</div>
							</div>
						</div>
					),
					backArrow: (
						<div
							className="btn-parallax"
							style={{
								borderColor: "rgba(0,0,0,0)",
								opacity: props.defaulthide ? "0" : "1",
								visibility: props.defaulthide
									? "hidden"
									: "inherit",

								background: buttonGradient(0),
							}}
						>
							<div className="back-icon arrow" />
							<div className="back-txt-cnt">
								<div
									className="back-txt transparent alt"
									ref={btnText}
								>
									{props.manualtext ? null : props.text}
								</div>
							</div>
						</div>
					),
				}[props.variant || "default"]
			}
		</div>
	);
});

export default React.memo(BtnParallax);
