import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import caseData from "../data/cases/cases.json";

const useFilterFromLocation = () => {
	let location = useLocation();

	const getFilterFromLocation = (location) => {
		//check if is direct link to shorthand
		const maybeCaseType = caseData.find(
			(x) => x.shorthand === location?.pathname.replaceAll("/", ""),
		)?.type;

		//if direct link to shorthand return case type as filter
		if (maybeCaseType?.length) {
			return maybeCaseType;
		}

		const valid = location?.pathname.startsWith("/cases");
		const locationSplit =
			location?.pathname?.split("/").filter((str) => str !== "") || [];
		if (valid) {
			return locationSplit?.[1] || "all";
		}
		return "all";
	};

	const validateFilter = (filter) => {
		if (filter == "solution" || filter == "work") {
			return filter;
		} else if (filter == "solutions") {
			const out = filter.slice(0, -1);
			return out;
		}

		return "all";
	};

	const [filter, setFilter] = useState(
		validateFilter(getFilterFromLocation(location)),
	);

	useEffect(() => {
		setFilter(validateFilter(getFilterFromLocation(location)));
	}, [location]);

	return filter;
};

export default useFilterFromLocation;
