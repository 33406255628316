import ReactGA from "react-ga4";

export const initGA = (id) => {
  if (process.env.NODE_ENV === "production" || true) {
    console.log(id);
    ReactGA.initialize(id);
  } else {
    console.warn("GA is only working in production");
  }
};

export const sendGAPageView = (globalContext, path) => {
  const evData = {
    hitType: "pageview",
    page: path,
    "custom-timestamp": Date.now(),
    "session-id": globalContext.sessionID,
    "session-color": globalContext.sessionColor?.name,
  };
  console.log("GA Pageview", evData);
  ReactGA.send(evData);
};

export const sendGACustomEvent = (globalContext, action, value) => {
  const evData = {
    category: "interaction",
    action: action + (value?.length ? "_" + value : ""),
    label: value, // optional
    "custom-timestamp": Date.now(), // optional, must be a number
    "session-id": globalContext.sessionID,
    "session-color": globalContext.sessionColor?.name,
  };

  //console.log("GA Event", evData);
  ReactGA.event(evData);
};
