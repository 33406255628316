import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import BtnParallax from "../../components/Elements/BtnParallax";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageElement from "../../components/Elements/ImageElement";
import ImageSlider from "../../components/Elements/ImageSlider";

import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "GShockFiveMinutes";

const shorthand = cases.find((x) => x.component === componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen, handleVisitSite) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">A live-action game</Header>
				<Desc customType="desc">
					<White>
						Created for zombie-lovers, gamers and movie-goers alike
						- Five Minutes has garnered
					</White>{" "}
					a loyal following of enthusiasts worldwide and has earned{" "}
					<White>numerous accolades and awards.</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? null : "25%"}
					customType="image"
					round={isMobile ? true : true}
					flex
					backgroundSize={"cover"}
					backgroundPosition={"center"}
					ar={isMobile ? 16 / 9 : 1}
					outline={isMobile ? false : true}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_06.jpg`
							: `/img/cases/${shorthand}/image/fiveminutes_app_icon.jpg`
					}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					„You've never seen a zombiefilm like this one!”
				</Quote>
				<Author customType="author">
					<White>- moviepilot.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"It gives a G-Shock to the system."
				</Quote>
				<Author customType="author">
					<White>- shots.net</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Memento meets The Last of Us."
				</Quote>
				<Author customType="author">
					<White>- angstrated.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					FWA of the year - People's Choice Award
				</Quote>
				<Author customType="author">
					<White>- thefwa.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="s" no-p-quote no-p fullfill>
				<Quote customType="quote">
					„An action-packed experience, set to get that pulse racing
					and those nerves jangling.”
				</Quote>
				<Author customType="author">
					<White>- shortsoftheweek.com</White>
				</Author>
			</QuoteEl>
		</QuoteSliderSlick>

		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",

					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_07.jpg`
							: `/img/cases/${shorthand}/image/img_01.jpg`
					}
					round
					aspectratio={isMobile ? 3 / 4 : null}
					alt={`Award grid of FIVE MINUTES achievements`}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Case"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>
						Five Minutes is a unique and immersive film experience
					</White>{" "}
					that aims to engage both gamers and non-gamers alike.
					<br />
					<br /> It offers{" "}
					<White>
						an exciting blend of traditional film-making and
						interactive gameplay,
					</White>{" "}
					which elevates the emotional impact of the story and makes
					the viewer an active participant in the narrative. <br />
					<br />
					This experience is primarily{" "}
					<White>designed for mobile devices</White> and has been
					optimized to run seamlessly in browsers using HTML5 and
					Javascript.
				</div>
			</div>
		</div>

		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/slider_john.jpg`,
						`/img/cases/${shorthand}/image/slider_rotation_game.jpg`,
						`/img/cases/${shorthand}/image/slider_mia.jpg`,
						`/img/cases/${shorthand}/image/slider_tap_game.jpg`,
						`/img/cases/${shorthand}/image/slider_zombies.jpg`,
						`/img/cases/${shorthand}/image/slider_swipe_game.jpg`,
					]}
				/>
			</div>
		</ArticleElBackgroundWrapper>

		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Award winning.
						<br />
						4.2 Mio views.
						<br />
						Accessible on all devices.
					</div>
				</div>
				<div className="section-description">
					<White>
						We were responsible for the lead creative and lead tech
						development
					</White>{" "}
					- this unique combination enabled us to combine emotion and
					technology right from the get-go. <br /> <br />
					<White>Five Minutes was multi-awarded</White> for its
					innovative approach and compelling storytelling.
				</div>
			</div>
		</div>

		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					round
					aspectratio={2.35}
					alt={`Mockup of fiveminutes.gs website`}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Visit Site"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVisitSite();
							}}
						/>
					</div>
				</div>
			</div>
		</div>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">
								Filmakademie Baden-Wuerttemberg
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brand</div>
							<div className="credits-name">CASIO, G-SHOCK</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Creative Lead & Tech Lead
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
						<div className="credit-block">
							<div className="credits-title">Tech Company</div>
							<div className="credits-name">UNIT9</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Powered by</div>
							<div className="credits-name">CHOIZ.IO</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
