import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

function Slider(props) {
	const sliderMoverRef = useRef(null);
	const [curValue, setCurValue] = useState(0);
	useEffect(() => {
		gsap.killTweensOf(sliderMoverRef.current);
		gsap.to(sliderMoverRef.current, {
			ease: "none",
			duration: 1,
			xPercent: (props.value || 0) * 100,
			onUpdate: () => {
				setCurValue(
					gsap.getProperty(sliderMoverRef.current, "xPercent")
				);
			},
		});
	}, [props.value]);

	return (
		<div className="status-slider">
			<div className="complications-slider-mover" ref={sliderMoverRef}>
				<div className="complications-slider-indicator" />
			</div>
			<div className="complications-slider-txt">
				{(curValue / 100).toFixed(3)}
			</div>
		</div>
	);
}

const CompStatusSlider = React.forwardRef((props, ref) => {
	const [values, setValues] = useState(null);
	const animationTimeline = useRef(null);

	const complicationsRef = useRef(null);

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
		};
	}, [complicationsRef.current]);

	const updateValues = (newValues) => {
		setValues(newValues);
	};

	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
	};

	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useLayoutEffect(() => {
		if (props.duration && props.data) {
			const firstEntry = props.data[0];
			const lastEntry = props.data?.[props.data?.length - 1];

			if (firstEntry && lastEntry) {
				const duration = lastEntry.Time - firstEntry?.Time;
				const timefactor = props.duration / duration;
				animationTimeline.current = gsap.timeline({ repeat: -1 });

				props.data.forEach((timeentry, i) => {
					const previousTime = props.data?.[i - 1]?.Time || 0;

					const delay = (timeentry.Time - previousTime) * timefactor;

					animationTimeline.current.add(
						gsap.delayedCall(delay, updateValues, [timeentry])
					);
				});
			}
		}

		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
				animationTimeline.current = null;
			}
		};
	}, [props.data, props.duration]);

	return (
		<div
			className={`complication-el connection${
				props.transparent ? " transparent" : ""
			}${props.align ? " align-" + props.align : ""}`}
			ref={complicationsRef}
		>
			<div className="complication-title-cnt">
				<div className="complication-logo" />
				<div className="complication-title green">
					{props.title || "N/A"}
				</div>
			</div>
			<div className="compilation-slider-cnt">
				<div className="complications-status-cnt">
					<div className="complication-slider-txt-cnt">
						{Object.keys(props.data?.[0] || []).map((x, i) => {
							if (x == "Time") return null;
							return (
								<div
									key={"comp-slider-header_" + i}
									className="complication-slider-heading"
								>
									{x}
								</div>
							);
						})}
					</div>

					<div className="complication-slider-txt-cnt">
						{Object.keys(props.data?.[0] || []).map((x, i) => {
							if (x == "Time") return null;
							return (
								<Slider
									key={"comp-slider-slider_" + i}
									value={
										Object.values(values || {})?.[i] || 0
									}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
});

export default CompStatusSlider;
