import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import cases from "../cases/cases.json";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageElement from "../../components/Elements/ImageElement";
import ImageSlider from "../../components/Elements/ImageSlider";
import VideoElement from "../../components/Elements/VideoElement";
import { isMobile } from "../../hooks/isMobile";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

const componentName = "Fortnite";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Unleash your brands creativity and reach out to a 200M+
					active community."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>
		<ArticleElBackgroundWrapper>
			<ArtileEl
				type={"text-left-onethird"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">Your very own brand-land</Header>
				<Desc customType="desc">
					<White>
						Create a game arena tailored to your brand's identity.
					</White>{" "}
					<br />
					<br />
					From imposing castles to intricate mazes - within Fortnite
					it is possible to unleash your brand's creativity and craft
					a unique experience for your customer base.
				</Desc>
				<VideoElement
					customType="video"
					round
					shrink
					shorthand={"fortnite-letitshine"}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={isMobile ? 3 / 4 : 2.35 / 1}
					round
					shorthand={"uefn-postervideo"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Teaser 'Mooncourt Ballin'"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-left-onethird"} small-header small-desc>
			<Header customType="header">
				High-Quality Product Presentation
			</Header>
			<Desc customType="desc">
				<White>
					Fortnite provides an exceptional platform to present your
					products with top-notch visuals.
				</White>
				<br />
				<br />
				The direct integration with Unreal Engine and its high-quality
				offline renderer opens up endless possibilities to expand your
				reach across various social and traditional advertising
				channels.
			</Desc>
			<VideoElement
				customType="video"
				round
				shrink
				shorthand={"fortnite-letyourproductshine"}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-onethird"} small-header small-desc>
			<Header customType="header">
				Engage Your Audience with Island Events
			</Header>
			<Desc customType="desc">
				<White>
					Your exclusive island is the perfect venue to host
					cross-platform events.
				</White>{" "}
				<br />
				<br />
				Whether it's a virtual concert, an exciting contest, or a
				product launch, by live-streaming events your campaign will gain
				the recognition it deserves.
			</Desc>
			<VideoElement
				customType="video"
				round
				shrink
				shorthand={"fortnite-hostevents"}
			/>
		</ArtileEl>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">Technology in focus</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Platform</div>
							<div className="credits-name">Fortnite</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Sofware</div>
							<div className="credits-name">
								Unreal Engine, Blender, Houdini & Figma
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Streaming</div>
							<div className="credits-name">OBS Studio</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
