import additionalVideoData from "./additionalVideo.json";

export function getAdditionalVideo(shorthand, resolution = "720p") {
	const isOfflineMode = process.env.REACT_APP_OFFLINE === "offline_mode";

	if (isOfflineMode) {
		return `/offline/batch/additional/${shorthand}/landscape-pr-${resolution}.mp4`;
	} else {
		const videoData = additionalVideoData.find(
			(x) => x.shorthand === shorthand,
		);

		return videoData?.[`landscape-pr-${resolution}`] ?? "";
		// return (
		// 	`/offline/batch/additional/${shorthand}/landscape-pr-${resolution}.mp4` ??
		// 	""
		// ); //videoData?.[`landscape-pr-${resolution}`] ?? "";
	}
}

export function getAdditionalVideoPoster(shorthand, resolution = "720p") {
	const videoData = additionalVideoData.find(
		(x) => x.shorthand === shorthand,
	);

	let uri = videoData?.[`poster`] ?? "";
	const aspect = 16 / 9;

	if (uri.length) {
		uri = uri.replace(
			/-d_\d+x\d+\?/,
			`-d_${aspect * resolution.slice(0, -1)}`,
		);
	}

	return uri;
}
