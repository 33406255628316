import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import { gsap } from "gsap";

import { useEffectOnce, destroyVideo } from "../hooks/useEffectOnce";
import { isMobileOrTablet, isAndroid } from "../hooks/isMobile";

const sec2time = (timeInSeconds) => {
	var pad = function (num, size) {
			return ("000" + num).slice(size * -1);
		},
		time = parseFloat(timeInSeconds).toFixed(3),
		hours = Math.floor(time / 60 / 60),
		minutes = Math.floor(time / 60) % 60,
		seconds = Math.floor(time - minutes * 60);
	// milliseconds = time.slice(-3);

	return `${hours > 0 ? pad(hours, 2) + ":" : ""}${pad(minutes, 2)}:${pad(
		seconds,
		2,
	)}`;
};

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const VideoModal = React.forwardRef((props, ref) => {
	const [refreshDate, setRefreshDate] = useState(Date.now());
	const [vidSrc, setVidSrc] = useState(undefined);
	const [vidSubs, setVidSubs] = useState(undefined);

	const [open, setOpen] = useState(props.open || false);
	const [muted, setMuted] = useState(false);
	const [subed, setSubed] = useState(true);
	const [subsHaveBeenToogled, setSubsHaveBeenToogled] = useState(false);

	const [playing, setPlaying] = useState(false);

	const lastProgress = useRef(0);
	const vidRef = useRef(null);
	const vidControls = useRef(null);

	const timeElapsedTxt = useRef(null);
	const timeLeftTxt = useRef(null);
	const muteLogo = useRef(null);
	const subLogo = useRef(null);
	const playPauseIcon = useRef(null);
	const btnWrapper = useRef(null);
	const trackIndicator = useRef(null);

	const vidTrackProgressFill = useRef(null);

	const timelineDrag = useRef(null);

	const fadeOutTimeout = useRef(null);

	const progressTrack = useRef(null);

	const closeCallbackRef = useRef(null);

	const clearFadeoutTimeout = () => {
		if (fadeOutTimeout.current) {
			clearTimeout(fadeOutTimeout.current);
		}
	};

	const setFadeoutTimeout = () => {
		clearFadeoutTimeout();
		fadeOutTimeout.current = setTimeout(() => {
			handleControlVisiblity(false);
		}, 2000);
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				open(bool, closeCallbackFn = null) {
					setOpen(bool);
					if (bool) {
						if (vidRef.current) {
							vidRef.current.style.display = "inherit";
							vidRef.current.currentTime = 0;
							vidRef.current.muted = muted;

							if (!subsHaveBeenToogled & muted) {
								showVideoSubtitles(true);
							} else {
								showVideoSubtitles(subed);
							}

							//setProgress(0, vidRef.current.duration);
							setProgress(0, 0);
						}
						handlePlay();
						handleControlVisiblity(true, true);
						setFadeoutTimeout();
						timelineDrag.current = false;
						lastProgress.current = 0;
						if (closeCallbackFn) {
							closeCallbackRef.current = closeCallbackFn;
						} else {
							closeCallbackRef.current = null;
						}

						if (isMobileOrTablet) {
							requestFullscreen();
						}
					} else {
						// destroyVideo(vidRef.current);
						// vidRef.current.src = "";
						// vidRef.current.load();

						handlePause();
						timelineDrag.current = false;
						closeCallbackRef.current = null;
						lastProgress.current = 0;

						requestAnimationFrame(() => {
							if (vidRef.current) {
								vidRef.current.currentTime = 0;
								if (isMobileOrTablet)
									setRefreshDate(Date.now());
								// vidRef.current.muted = muted;
								//setProgress(0, 0);
							}
						});
					}
				},
			};
		},
		[open],
	);

	const requestFullscreen = () => {
		if (
			!document.fullscreenElement && // alternative standard method
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement &&
			!document.msFullscreenElement
		) {
			// current working methods
			if (vidRef.current.requestFullscreen) {
				vidRef.current.requestFullscreen().catch((e) => {});
			} else if (vidRef.current.msRequestFullscreen) {
				vidRef.current.msRequestFullscreen();
			} else if (vidRef.current.mozRequestFullScreen) {
				vidRef.current.mozRequestFullScreen();
			} else if (vidRef.current.webkitRequestFullscreen) {
				vidRef.current.webkitRequestFullscreen();
			}
		}
	};
	const handleTimeupdate = (automatic = false) => {
		if (timelineDrag.current) {
			lastProgress.current = 0;
			return;
		}
		const duration = vidRef.current.duration;
		const curTime = vidRef.current.currentTime;

		if (
			automatic &&
			lastProgress.current > curTime &&
			curTime - lastProgress.current > -1
		)
			return;
		if (!duration) return;

		requestAnimationFrame(() => {
			setProgress(curTime, duration);
		});

		lastProgress.current = curTime;
	};

	const handleVolumeChange = (e) => {
		if (!vidRef.current) return;
		const isMuted = vidRef.current?.muted || false;
		console.log(isMuted);
	};

	const toogleMuted = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!vidRef.current) return;

		vidRef.current.muted = !vidRef.current.muted;

		setMuted(vidRef.current.muted);

		if (vidRef.current.muted && !subsHaveBeenToogled) {
			showVideoSubtitles(true);
		}
	};

	const showVideoSubtitles = (bool) => {
		setSubed(bool);
		const subExist = vidRef.current?.textTracks?.[0]?.mode ?? false;
		if (!subExist) return;
		if (bool) {
			vidRef.current.textTracks[0].mode = "showing";
		} else {
			vidRef.current.textTracks[0].mode = "hidden";
		}
	};

	const toogleSubbed = (e) => {
		//todo: implement
		e.preventDefault();
		e.stopPropagation();
		if (!vidRef.current) return;

		setSubsHaveBeenToogled(true);

		let showSubsNext = !subed;
		showVideoSubtitles(showSubsNext);
	};
	const setProgress = (curTime, duration) => {
		const percent = duration === 0 ? 0 : curTime / duration;
		if (!vidTrackProgressFill.current) return;
		gsap.set(vidTrackProgressFill.current, {
			xPercent: -100 + percent * 100,
		});

		if (timeElapsedTxt.current) {
			timeElapsedTxt.current.innerText = sec2time(curTime);
		}

		if (timeLeftTxt.current) {
			timeLeftTxt.current.innerText =
				"-" + sec2time(Math.max(0, duration - curTime));
		}
	};

	const requestRef = useRef(null);

	const animate = (time) => {
		handleTimeupdate(true);
		// The 'state' will always be the initial value here
		requestRef.current = requestAnimationFrame(animate);
	};

	const isFullscreen = () => {
		return document.fullscreenElement != null;
	};

	const fullscreenchangeHandler = (e) => {
		if (!isFullscreen()) {
			handleExit();
		}
	};

	const exitHandler = () => {
		// Works, but I don't really understand why
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}

		if (!isFullscreen()) {
			vidRef.current.style.display = "none";
			closeCallbackRef.current?.();
		}
	};
	const handleExit = () => {
		vidRef.current.style.display = "none";
		closeCallbackRef.current?.();
	};

	useEffectOnce(() => {
		const vidEl = vidRef.current;
		return () => {
			destroyVideo(vidEl);
		};
	});
	const handleResize = (e) => {
		//Fix Android pinch-zoom bug
		if (isAndroid) fullscreenchangeHandler(e);
	};

	useEffect(() => {
		const _vidRefCurrent = vidRef.current;
		if (open) {
			requestRef.current = requestAnimationFrame(animate);
		}
		if (_vidRefCurrent) {
			_vidRefCurrent.addEventListener("volumechange", handleVolumeChange);
			_vidRefCurrent.addEventListener("play", handleOnPlay);
			_vidRefCurrent.addEventListener("pause", handleOnPause);
			_vidRefCurrent.addEventListener("ended", () => {
				if (!isMobileOrTablet) exitHandler();
			});

			// _vidRefCurrent.addEventListener(
			// 	"webkitendfullscreen",
			// 	exitHandler,
			// 	false
			// );

			_vidRefCurrent.addEventListener(
				"webkitendfullscreen",
				exitHandler,
				false,
			);
			_vidRefCurrent.addEventListener(
				"fullscreenchange",
				fullscreenchangeHandler,
				false,
			);
			_vidRefCurrent.addEventListener(
				"mozfullscreenchange",
				fullscreenchangeHandler,
				false,
			);
			_vidRefCurrent.addEventListener(
				"MSFullscreenChange",
				fullscreenchangeHandler,
				false,
			);
			_vidRefCurrent.addEventListener(
				"webkitfullscreenchange",
				fullscreenchangeHandler,
				false,
			);
		}

		window.addEventListener("resize", handleResize);

		return () => {
			cancelAnimationFrame(requestRef.current);
			window.removeEventListener("resize", handleResize);

			if (_vidRefCurrent) {
				_vidRefCurrent.removeEventListener(
					"volumechange",
					handleVolumeChange,
				);
				_vidRefCurrent.removeEventListener("play", handleOnPlay);
				_vidRefCurrent.removeEventListener("pause", handleOnPause);
				_vidRefCurrent.removeEventListener("ended", () => {
					if (!isMobileOrTablet) closeCallbackRef.current?.();
				});

				_vidRefCurrent.removeEventListener(
					"webkitendfullscreen",
					exitHandler,
					false,
				);
				_vidRefCurrent.removeEventListener(
					"fullscreenchange",
					fullscreenchangeHandler,
					false,
				);
				_vidRefCurrent.removeEventListener(
					"mozfullscreenchange",
					fullscreenchangeHandler,
					false,
				);
				_vidRefCurrent.removeEventListener(
					"MSFullscreenChange",
					fullscreenchangeHandler,
					false,
				);
				_vidRefCurrent.removeEventListener(
					"webkitfullscreenchange",
					fullscreenchangeHandler,
					false,
				);
			}
		};
	}, [open]); // Make sure the effect runs only once

	useEffect(() => {
		if (props.src !== vidSrc) {
			setVidSrc(props.src);
		}
	}, [props.src]);

	useEffect(() => {
		if (props.subs !== vidSubs) {
			setVidSubs(props.subs);
		}
	}, [props.subs]);

	const handleHover = (e, bool) => {
		if (!e.target) return;
		const hasHover = e.target.classList.contains("hover");
		if (bool && !hasHover) {
			e.target.classList.add("hover");
		} else if (!bool && hasHover) {
			e.target.classList.remove("hover");
		}
	};
	const enterFullScreen = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!vidRef.current) return;
		if (vidRef.current.requestFullscreen)
			vidRef.current.requestFullscreen();
		else if (vidRef.current.webkitRequestFullscreen)
			vidRef.current.webkitRequestFullscreen();
		else if (vidRef.current.msRequestFullScreen)
			vidRef.current.msRequestFullScreen();
	};

	const handleControlVisiblity = (bool, instant = false) => {
		gsap.killTweensOf(vidControls.current);
		gsap.killTweensOf([btnWrapper.current, playPauseIcon.current]);

		clearFadeoutTimeout();

		if (bool) {
			gsap.to(vidControls.current, instant ? 0 : 0.5, {
				autoAlpha: 1,
			});
			gsap.to(
				[btnWrapper.current, playPauseIcon.current],
				instant ? 0 : 0.3,
				{
					yPercent: 0,
				},
			);
		} else {
			gsap.to(vidControls.current, instant ? 0 : 0.5, {
				autoAlpha: 0,
			});
			gsap.to(
				[btnWrapper.current, playPauseIcon.current],
				instant ? 0 : 0.3,
				{
					yPercent: 40,
				},
			);
		}
	};

	const handlePlay = () => {
		if (!vidRef.current) return;
		const playPromise = vidRef.current.play();

		if (playPromise !== undefined) {
			playPromise
				.then((_) => {
					// Automatic playback started!
					// Show playing UI.
				})
				.catch((error) => {
					console.error(error);
					// Auto-play was prevented
					// Show paused UI.
				});
		}
	};
	const handlePause = () => {
		if (!vidRef.current) return;
		const playPromise = vidRef.current.pause();

		if (playPromise !== undefined) {
			playPromise
				.then((_) => {
					// Automatic playback started!
					// Show playing UI.
				})
				.catch((error) => {
					console.error(error);
					// Auto-play was prevented
					// Show paused UI.
				});
		}
	};

	const tooglePlayPause = (e) => {
		e.stopPropagation();
		if (!vidRef.current) return;
		if (e.target.classList.contains("vid-progress-cnt")) return;

		//Ignore in fullscreen mode
		if (isFullscreen()) return;

		const isPaused = vidRef.current.paused;

		if (isPaused) {
			handlePlay();
		} else {
			handlePause();
		}
	};

	const handleOnPlay = () => {
		setPlaying(true);
	};

	const handleOnPause = () => {
		setPlaying(false);
	};

	const updateIndicator = (e, visible) => {
		if (visible) {
			var rect = e.target.getBoundingClientRect();

			//x position within the element.
			var x = e.clientX - rect.left;

			gsap.to(trackIndicator.current, 0, { autoAlpha: 1, x: x });
		} else {
			gsap.to(trackIndicator.current, 0.3, { autoAlpha: 0 });
		}
	};

	const updateTimeFromDrag = (e) => {
		const vid = vidRef.current;
		if (!vid) return;

		const duration = vid.duration;
		if (!duration) return;
		const rect = e.target.getBoundingClientRect();
		const width = rect.width;
		const x = e.clientX - rect.left;

		const progress = x / width;
		const curTime = progress * duration;
		vid.currentTime = curTime;
		setProgress(curTime, duration);
	};

	const handleTouchStart = (e) => {
		e.stopPropagation();
		const vid = vidRef.current;
		if (!vid) return;
		//handlePause();
		timelineDrag.current = true;
		updateTimeFromDrag(e);
	};

	const handleTouchEnd = (e) => {
		preventDefault(e);
		timelineDrag.current = false;
	};

	const preventDefault = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleMouseMoveModal = (e) => {
		if (timelineDrag.current && progressTrack.current) {
			const vid = vidRef.current;
			if (!vid) return;

			const duration = vid.duration;
			if (!duration) return;

			const rect = progressTrack.current.getBoundingClientRect();
			const width = rect.width;
			const x = e.clientX - rect.left;

			const progress = clamp(x / width, 0, 1);
			const curTime = progress * duration;
			vid.currentTime = curTime;
			setProgress(curTime, duration);
		}
	};
	return (
		<div
			className="video-modal"
			style={{
				zIndex: 9,
				display: open ? "flex" : "none",
				transform: "translateZ(0)",
			}}
			onMouseMove={handleMouseMoveModal}
			onMouseUp={(e) => {
				updateIndicator(e, false);
				handleTouchEnd(e);
			}}
		>
			<div
				className="video-content"
				onMouseEnter={(e) => {
					handleControlVisiblity(true);
				}}
				onMouseLeave={(e) => {
					handleControlVisiblity(false);
				}}
				onMouseDown={tooglePlayPause}
			>
				<video
					key={vidSrc + "" + refreshDate}
					ref={vidRef}
					preload="none"
					className="modalVid"
					type={"video/mp4"}
					style={{
						width: "100%",
						height: "100%",
						maxHeight: "100vh",
						maxWidth: "100vw",
						minHeight: "100%",
						minWidth: "100%",
						opacity: 1,
					}}
					src={vidSrc}
				>
					{props.subtrack}
				</video>
				<div
					className="video-btn-wrapper"
					style={{
						transform: "translateZ(0)",
						display: isMobileOrTablet ? "none" : "flex",
					}}
					ref={vidControls}
				>
					<div
						className="vid-btn-loading"
						style={{ display: "none" }}
					></div>
					<div
						className={`vid-btn-play ${
							!playing ? "play" : "pause"
						}`}
						ref={playPauseIcon}
					></div>
					<div className="vid-lower-btn-wrapper" ref={btnWrapper}>
						<div className="vid-main-controls">
							<div
								className="vid-main-btn-wrapper"
								onClick={toogleMuted}
								onMouseDown={preventDefault}
								onMouseEnter={(e) => {
									handleHover(e, true);
								}}
								onMouseLeave={(e) => {
									handleHover(e, false);
								}}
								style={{ cursor: "none" }}
								// onMouseMove={(e) => {
								// 	updateIndicator(e, true);
								// 	handleTouchMove(e);
								// }}
							>
								<div
									style={{ pointerEvents: "none" }}
									className={`vid-main-button${
										muted ? " mute" : " mute-off"
									}`}
									ref={muteLogo}
								></div>
							</div>

							{props.subtrack ? (
								<div
									className="vid-main-btn-wrapper"
									onClick={toogleSubbed}
									onMouseDown={preventDefault}
									onMouseEnter={(e) => {
										handleHover(e, true);
									}}
									onMouseLeave={(e) => {
										handleHover(e, false);
									}}
									style={{ cursor: "none" }}
								>
									<div
										style={{ pointerEvents: "none" }}
										className={`vid-main-button${
											subed
												? " subtitle-on"
												: " subtitle-off"
										}`}
										ref={subLogo}
									></div>
								</div>
							) : null}
							<div
								className="vid-main-control-progress-cnt"
								style={{ pointerEvents: "none" }}
							>
								<div
									className="controls-elapsed-time-indicator"
									style={{ pointerEvents: "none" }}
								>
									<div
										className="elapsed-time-txt"
										ref={timeElapsedTxt}
									>
										02:21
									</div>
								</div>
								<div
									ref={progressTrack}
									className="vid-progress-cnt"
									// onClick={handleTimelineClick}
									onMouseDown={(e) => {
										handleTouchStart(e);
									}}
									onMouseEnter={(e) => {
										updateIndicator(e, true);
										props.changeCursorState("none");
									}}
									onMouseLeave={(e) => {
										updateIndicator(e, false);
										props.changeCursorState("idle");
									}}
									onMouseUp={(e) => {
										updateIndicator(e, false);
									}}
									onClick={preventDefault}
									style={{
										pointerEvents: "auto",
									}}
									onMouseMove={(e) => {
										updateIndicator(e, true);
									}}
								>
									<div
										className="vid-progress-track"
										style={{
											pointerEvents: "none",
											transform: "translateZ(0)",
										}}
									>
										<div
											className="vid-progress-fill"
											ref={vidTrackProgressFill}
											style={{ pointerEvents: "none" }}
										></div>
										<div
											className="vid-progress-hover-indicator inactive"
											ref={trackIndicator}
											style={{ pointerEvents: "none" }}
										></div>
									</div>
								</div>
								<div className="controls-elapsed-time-indicator">
									<div
										className="elapsed-time-txt"
										ref={timeLeftTxt}
									>
										-02:01
									</div>
								</div>
							</div>
							<div
								className="vid-main-btn-wrapper"
								onClick={enterFullScreen}
								onMouseDown={preventDefault}
								onMouseEnter={(e) => {
									handleHover(e, true);
								}}
								onMouseLeave={(e) => {
									handleHover(e, false);
								}}
								style={{ cursor: "none" }}
							>
								<div
									className="vid-main-button fullscreen"
									style={{ pointerEvents: "none" }}
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default VideoModal;
