import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import cases from "../cases/cases.json";

const componentName = "SocialTurnaround";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"React swiftly to cultural trends and generate buzz on
					social media."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>

		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Be the hype.</Header>
			<Desc customType="desc">
				At Stunning. we believe that brands should be able to create a
				positive influence on their audiences in real-time by leveraging
				the latest technologies to{" "}
				<White>craft content at the speed of culture.</White> Thanks to
				modern game engines, brands can become increasingly agile in
				volatile times so they can stay culturally relevant; setting
				trends before they happen.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.jpg`}
			/>
		</ArtileEl>

		<ArticleElBackgroundWrapper
			id={shorthand}
			variant={"article_top"}
			small-header
			small-desc
		>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">Dance in everybody's feed.</Header>
				<Desc customType="desc">
					Virtual influencers are the future of social media and the
					best part is, they never have a bad hair day! If you want to
					enhance your social presence, these digital characters are
					an excellent starting point. Having their own unique
					personalities, backstories and followers, they can{" "}
					<White>
						set new trends and fire up challenges on any social
						platform.
					</White>{" "}
					The possibilities of content creation are endless. Why not
					creating your own mini series with your digital friends or
					invent a whole brand universe?
				</Desc>
				<CustomImage
					customType="image"
					src={`/img/cases/${shorthand}/image/img_05.jpg`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<ArtileEl type={"text-left-onethird"} small-header small-desc>
			<Header customType="header">Pretty, simple.</Header>
			<Desc customType="desc">
				<White>Our process is simplified to the maximum.</White> Detect
				a trend on TikTok or any other social platform. Let us know and
				a few hours later, we’ll have an actor ready to shoot, and your
				virtual influencer will enter the social stage in the perfect
				moment.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">Technology in focus</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Platforms</div>
							<div className="credits-name">
								TikTok, Instagram, Youtube, Twitch, Snapchat,
								WhatsApp, Facebook
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
