import React, { Component, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import TopologyBackground from "../Elements/TopologyBackground";
import VideoElement from "./VideoElement";

import { isMobile } from "react-device-detect";



function BulletPoint(props) {
  return (
    <div>
      <h4
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          transition: "all 1s ease",

          whiteSpace: "nowrap",
          color:
            props.index == props.next
              ? "rgba(255,255,255,1)"
              : "rgba(255,255,255,0.5)",
        }}
      >
        {props.text}
      </h4>
    </div>
  );
}

function BulletPointSide(props) {
  return (
    <div
      style={{
        transition: "all 1s ease",

        whiteSpace: "nowrap",
        color:
          props.index == props.next
            ? "rgba(255,255,255,1)"
            : "rgba(255,255,255,0.5)",
      }}
      className="tab-bulletpoint-marker-cnt"
      onClick={props.onClick || null}
    >
      {props.text}
    </div>
  );
}

const CustomSlide = (props) => {
  const marginHorizontal = 5;
  const marginVertical = 5;

  return (
    <div
      {...props}
      style={{
        paddingTop: `${marginVertical}px`,

        marginLeft: `${marginHorizontal}px`,
        marginRight: `${marginHorizontal}px`,
      }}
    >
      <VideoElement
        nopreloader
        preload={props.preload ?? "none"}
        shorthand={props.videoident}
        resolution={isMobile?"540p":"720p"}
        blockfade
        round
        homeslider
      />
    </div>
  );
};

const MemoSlide = React.memo(CustomSlide, (prev, next) => {
  return true;
});

const data = [
  ["Metaverse & Games", "uefn-postervideo"],
  ["Commercials", "fortnite-letyourproductshine"],  
  ["Live-action Experiences", "homeslider-liveactiongames"],
  ["Spatial Experiences", "homeslider-liveevents"],
  // ["Virtual Influencers", "homeslider-virtualinfluencer"],
  ["Motion Capture", "homeslider-virtualinfluencer"],
  ["Unreal Engine", "homeslider-mercedes"],
];

const HomeVideoSlider = React.forwardRef((props, ref) => {
  const mainSlider = useRef(null);
  const bulletPoints = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const pauseAutoplay = () => {
    mainSlider.current.slickPause();
  };

  const resumeAutoplay = () => {
    mainSlider.current.slickPlay();
  };

  return (
    <div
      className="tab-wrapper"
      style={{ pointerEvents: "all" }}
      onTouchMove={pauseAutoplay}
      onMouseMove={pauseAutoplay}
      onMouseEnter={pauseAutoplay}
      onMouseLeave={resumeAutoplay}
      onClick={pauseAutoplay}
      ref={ref}
    >
      {/*<div className="glow-bg left" />*/}
      {/* <TopologyBackground
        variant={"background_one"}
        width={3}
        cover={true}
        id={"homeslider"}
        style={{
          transform: "translateZ(0) rotate(180deg)",
          position: "absolute",

          height: "200%",
          width: "200%",
          top: "-75%",
          left: "-50%",
          opacity: 0.15,
        }}
      /> */}
      <div className="tab-mainslider">
        <Slider
          asNavFor={bulletPoints.current}
          ref={mainSlider}
          arrows={false}
          autoplay={true}
          autoplaySpeed={3000}
          vertical={true}
          verticalSwiping={true}
          responsive={[
            {
              breakpoint: 990,
              settings: {
                vertical: false,
                verticalSwiping: false,
              },
            },
          ]}
          beforeChange={(current, next) => setActiveSlide(next)}
          afterChange={(current) => setActiveSlide2(current)}
        >
          {data.map((entry, i) => (
            <MemoSlide
              preload={props.preload}
              key={`nav-bullet-${i}`}
              videoident={entry[1]}
              index={i + 1}
            />
          ))}
        </Slider>
      </div>

      <div className="tab-spacer">
        <div className="glow-bg right" />
        <div className="tab-ruler-wrapper">
          <div className="ruler-line" style={{ opacity: 0.15 }} />
          <div className="ruler-line" style={{ opacity: 0.3 }} />
          <div className="ruler-line" style={{ opacity: 0.5 }} />
          <div className="ruler-line" style={{ opacity: 0.75 }} />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line">
            <div className="ruler-marker">10</div>
          </div>
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line">
            <div className="ruler-marker">5</div>
          </div>
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line">
            <div className="ruler-marker">0</div>
          </div>
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line">
            <div className="ruler-marker">5</div>
          </div>
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line">
            <div className="ruler-marker">10</div>
          </div>
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" />
          <div className="ruler-line" style={{ opacity: 0.75 }} />
          <div className="ruler-line" style={{ opacity: 0.5 }} />
          <div className="ruler-line" style={{ opacity: 0.3 }} />
          <div className="ruler-line" style={{ opacity: 0.15 }} />
          <div className="ruler-marker-cnt">
            <div className="ruler-maker-flex-wrapper">
              {data.map((entry, i) => (
                <div
                  key={"tab-bulletpoint-marker-cnt-" + i}
                  className="tab-bulletpoint-marker-cnt"
                  onClick={() => {
                    mainSlider.current.slickGoTo(i);
                  }}
                >
                  <div
                    className={`tab-bullet-marker${
                      i == activeSlide ? "" : " inactive"
                    }`}
                    style={{ pointerEvents: "none" }}
                  ></div>
                </div>
              ))}
            </div>
          </div>

          {/*     <div className="ruler-marker-cnt">
            <div className="ruler-maker-flex-wrapper">
              {data.map((entry, i) => (
                <div className="tab-bulletpoint">-</div>
              ))}
            </div>
          </div>*/}
        </div>
      </div>

      <div className="tab-bullet">
        {data.map((entry, i) => (
          <BulletPointSide
            key={`nav-bullet-${i}`}
            text={entry[0]}
            index={i}
            next={activeSlide}
            current={activeSlide2}
            onClick={() => {
              mainSlider.current.slickGoTo(i);
            }}
          />
        ))}
      </div>
      <div className="tab-navslider">
        <Slider
          class={"slider variable-width"}
          asNavFor={mainSlider.current}
          ref={bulletPoints}
          centerMode={true}
          swipeToSlide={true}
          focusOnSelect={true}
          variableWidth={true}
          adaptiveHeight={true}
          arrows={false}
        >
          {data.map((entry, i) => (
            <BulletPoint
              key={`nav-bullet-${i}`}
              text={entry[0]}
              index={i}
              next={activeSlide}
              current={activeSlide2}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
});

export default React.memo(HomeVideoSlider, (prev, next) => {
  return true;
});
