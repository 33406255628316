import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";

import * as Complications from "../data/complications";
import { isTouchDevice } from "../hooks/utils";

import { isTablet } from "../hooks/isMobile";

import useOnScreen from "../hooks/useOnScreen";
import { useEffectOnce, destroyVideo, destroyEl } from "../hooks/useEffectOnce";

import { verifyRedirects } from "../hooks/verifyRedirects";
import { Expo } from "gsap/all";

const SolutionSlide = React.forwardRef((props, ref) => {
	const slideRef = useRef(null);
	const isSlideOnScreen = useOnScreen(slideRef);
	const complicationsRef = useRef(null);
	const activeSlideTimer = useRef(null);

	const contentRef = useRef(null);

	const sliderContent = useRef(null);

	const [srcVidMain, setSrcVidMain] = useState(undefined);

	const [srcVidPreview, setSrcVidPreview] = useState(undefined);

	const [isPortraitMode, setIsPortraitMode] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);

	const [curMode, setCurMode] = useState(0); //0 preview, 1 split

	const vid = useRef(null);
	const vidPreview = useRef(null);
	const poster = useRef(null);

	const videoIsStopped = useRef(true);

	const activeVideo = useRef(props.activeVideo);
	const activeSlideRef = useRef(props.activeSlide);

	const isActiveSlide = () => {
		return props.activeSlide === props.index;
	};

	const handlePlayVideo = () => {
		let videoToPlay = undefined;
		videoIsStopped.current = false;
		//console.log("try play, curMode: " + curMode);
		if (curMode === 0) {
			videoToPlay = vidPreview;
		} else {
			videoToPlay = vid;
		}

		if (typeof videoToPlay?.current !== "undefined") {
			handlePlayVideoRef(videoToPlay);
		}
	};

	const handlePlayVideoRef = (videoRef) => {
		const playPromise = videoRef.current?.play?.();

		if (playPromise !== undefined) {
			playPromise
				.then((_) => {
					// Automatic playback started!
					// Show playing UI.
				})
				.catch((error) => {
					// Auto-play was prevented
					// Show paused UI.
				});
		}
	};

	const handleStopVideo = () => {
		if (!isPortraitMode) {
			setCurMode(0);
		}
		if (!videoIsStopped.current) {
			videoIsStopped.current = true;
			if (vid.current) {
				vid.current.pause();
				//vid.current.currentTime = 0;
			}

			if (vidPreview.current) {
				vidPreview.current.pause();
				//vidPreview.current.currentTime = 0;
			}
		}
	};

	const panVideo = () => {
		if (!vid?.current) return;

		if (props.debugComplication > -1) {
			activeVideo.current = props.debugComplication;
		}

		complicationsRef.current?.switchVideo?.(activeVideo.current);
		if (isPortraitMode) {
			switch (activeVideo.current) {
				/* eslint-disable no-fallthrough */
				case 0: {
					vid.current.style.webkitTransform = `translateX(${
						-50 / 3
					}%) translateY(${-50}%)`;
					vid.current.style.webkitTransform = `translateX(${
						-50 / 3
					}%) translateY(${-50}%)`;
					break;
				}
				case 1: {
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${-50}%)`;
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${-50}%)`;
					break;
				}
				case -1: {
				}
				case 2: {
					vid.current.style.webkitTransform = `translateX(${
						-1 * (25 / 3 + 75)
					}%) translateY(${-50}%)`;
					vid.current.style.webkitTransform = `translateX(${
						-1 * (25 / 3 + 75)
					}%) translateY(${-50}%)`;
					//statements;
					break;
				}

				default: {
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${-50}%)`;
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${-50}%)`;
					break;
				}
				/* eslint-enable no-fallthrough */
			}
		} else {
			switch (activeVideo.current) {
				default: {
				}

				/* eslint-disable no-fallthrough */
				case 0: {
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						(-(1 / 2) + 1 / 3) * 100
					}%) `;
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						(-(1 / 2) + 1 / 3) * 100
					}%) `;
					break;
				}
				case 1: {
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						-(1 / 2) * 100
					}%)`;
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						-(1 / 2) * 100
					}%)`;
					break;
				}
				case -1: {
				}
				case 2: {
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						(-(1 / 2) - 1 / 3) * 100
					}%) `;
					vid.current.style.webkitTransform = `translateX(${-50}%) translateY(${
						(-(1 / 2) - 1 / 3) * 100
					}%) `;

					//statements;
					break;
				}

				case 3: {
					vid.current.style.webkitTransform = `translateX(${-75}%) translateY(${-75}%)`;
					vid.current.style.webkitTransform = `translateX(${-75}%) translateY(${-75}%)`;
					break;
				}
				/* eslint-enable no-fallthrough */
			}

			if (
				activeVideo.current !== 2 &&
				activeVideo.current !== -1 &&
				curMode === 0
			) {
				setCurMode(1);
			}
		}
	};

	useEffect(() => {
		if (props.preloaded) {
			gsap.killTweensOf(slideRef.current);
			gsap.killTweensOf(poster.current);

			gsap.to(
				slideRef.current,

				{ duration: 0.3, autoAlpha: 1 },
			);

			gsap.fromTo(
				contentRef.current,
				0.66,
				{ autoAlpha: 0, xPercent: 100 },
				{ delay: 0.33, xPercent: 0, autoAlpha: 1, ease: Expo.easeOut },
			);
			gsap.fromTo(
				poster.current,
				0.66,

				{ autoAlpha: 0, xPercent: 100 },
				{
					autoAlpha: 1,
					xPercent: 0,
					ease: Expo.easeOut,
					force3D: true,
					delay: 0.5,
					onComplete: () => {
						if (vid.current && vidPreview.current) {
							gsap.set([vid.current, vidPreview.current], {
								visibility: "visible",
							});
						}
					},
				},
			);
		}
	}, [props.preloaded]);

	// useEffect(() => {
	// 	if (props.preloaded) {
	// 		console.log("test");
	// 		gsap.to(
	// 			slideRef.current,

	// 			{ duration: 0.3, autoAlpha: 1 }
	// 		);
	// 	}
	// });

	useEffect(() => {
		if (isActiveSlide() || props.activeVideo === -1) {
			if (activeVideo.current !== props.activeVideo) {
				activeVideo.current = props.activeVideo;
				panVideo();

				requestAnimationFrame(() => {
					// reflow(vid.current);
					// vid.current.currentTime = 0;
					// vid.current.requestVideoFrameCallback(() => {
					// 	panVideo();
					// });
					// setTimeout(() => {
					//
					// }, 0);
					// vid.current.play();
					//panVideo();
				});
			}
		}
	}, [props.activeVideo]);

	useEffect(() => {
		if (props.isEnabled !== isEnabled) {
			setIsEnabled(props.isEnabled);
		}
	}, [props.isEnabled]);

	useEffect(() => {
		if (props.main !== srcVidMain) {
			setSrcVidPreview(props.preview);
			setSrcVidMain(props.main);
		}
		if (props.isPortraitMode !== isPortraitMode) {
			setIsPortraitMode(props.isPortraitMode);
			panVideo();

			if (props.isPortraitMode) {
				setCurMode(1); //if portrait mode ignore preview video
			} else {
				setCurMode(0); //if changed to landscape, reset
			}
		}
	}, [props.main, props.isPortraitMode]);

	useEffect(() => {
		const _vidCurrent = vid.current;
		const _vidCurrentPreview = vidPreview.current;
		if (poster.current) {
			const fadeOutPosterMainVid = (e) => {
				if (!_vidCurrent) return;
				if (_vidCurrent.currentTime > 0) {
					// vid.current.removeEventListener(
					// 	"timeupdate",
					// 	fadeOutPosterMainVid
					// );
					panVideo();
					gsap.to(poster.current, {
						duration: 0,
						autoAlpha: 0,
						// yoyo: true,
						// repeat: -1,
						// duration: 0.5,
					});
					//handleStopVideo();
				}
			};

			const fadeOutPosterPreviewVid = (e) => {
				if (!_vidCurrentPreview) return;
				if (_vidCurrentPreview.currentTime > 0) {
					// vidPreview.current.removeEventListener(
					// 	"timeupdate",
					// 	fadeOutPosterPreviewVid
					// );
					panVideo();
					gsap.to(poster.current, { duration: 0, autoAlpha: 0 });
				}
			};

			if (curMode === 0) {
				_vidCurrentPreview.addEventListener(
					"timeupdate",
					fadeOutPosterPreviewVid,
				);
			} else {
				_vidCurrent.addEventListener(
					"timeupdate",
					fadeOutPosterMainVid,
				);
			}

			return () => {
				if (_vidCurrent) {
					_vidCurrent.removeEventListener(
						"timeupdate",
						fadeOutPosterMainVid,
					);
				}

				if (_vidCurrentPreview) {
					_vidCurrentPreview.removeEventListener(
						"timeupdate",
						fadeOutPosterPreviewVid,
					);
				}
			};
		}
	}, [poster.current, curMode]);

	useEffectOnce(() => {
		const _vidCurrent = vid.current;
		const _vidCurrentPreview = vidPreview.current;
		const _slide = slideRef.current;

		//Init Slide Opacity
		if (_slide) {
			gsap.set(_slide, { autoAlpha: 0 });
		}

		return () => {
			destroyVideo(_vidCurrent);
			destroyVideo(_vidCurrentPreview);
			destroyEl(_slide);
		};
	});

	useEffect(() => {
		if (activeSlideRef.current === props.activeSlide) return;
		activeSlideRef.current = props.activeSlide;

		if (isPortraitMode) {
			setCurMode(1);
		}

		if (props.activeSlide === props.index) {
			//console.log("play active slide " + props.index);

			setActiveSlideTimer();
		} else {
			clearActiveSlideTimer();

			handleStopVideo();
			if (isTablet && !props.isPortraitMode)
				gsap.to(poster.current, {
					duration: 0,
					autoAlpha: 1,
					// yoyo: true,
					// repeat: -1,
					// duration: 0.5,
				});
		}
	}, [props.activeSlide]);

	const clearActiveSlideTimer = () => {
		if (activeSlideTimer.current) {
			clearTimeout(activeSlideTimer.current);
		}
	};

	const setActiveSlideTimer = () => {
		clearActiveSlideTimer();
		activeSlideTimer.current = setTimeout(() => {
			handlePlayVideo();
			//check mode
			if (curMode === 0) {
				//try to play vidMain
				if (vid.current) {
					//console.log("play main video");
					handlePlayVideoRef(vid);
				}
			}
		}, 0);
	};

	const handleHover = (bool) => {
		if (!sliderContent.current || isTouchDevice()) return;
		const hasHover = sliderContent.current.classList.contains("hover");
		if (bool && !hasHover) {
			sliderContent.current.classList.add("hover");
		} else if (!bool && hasHover) {
			sliderContent.current.classList.remove("hover");
		}
	};

	const currentlyOnScreen = isActiveSlide() || isSlideOnScreen; //ERROR

	useEffect(() => {
		if (!isSlideOnScreen) {
			if (vidPreview.current) {
				vidPreview.current.pause();
			}
			if (vid.current) {
				vid.current.pause();
			}
		}
	}, [currentlyOnScreen]);

	return false ? null : (
		<div
			ref={slideRef}
			className={`slide${props.isPortraitMode ? " portrait" : ""}`}
			// style={{
			// 	opacity: 0,
			// 	visibility: "hidden",
			// }}
			onClick={(e) => {
				if (!isEnabled) return;
				//handlePlayVideo();
				props.onclick(e);
			}}
			onMouseMove={(e) => {
				e.preventDefault();
				if (!isEnabled) return;
				//e.stopPropagation();

				if (
					!props.onscroll.current &&
					!e.target.playing &&
					!isTouchDevice()
				) {
					handleHover(true);

					handlePlayVideo();
				}
			}}
			onMouseEnter={(e) => {
				e.preventDefault();
				if (!isEnabled) return;
				//e.stopPropagation();

				if (
					!props.onscroll.current &&
					!e.target.playing &&
					!isTouchDevice()
				) {
					handleHover(true);

					handlePlayVideo();
				}
			}}
			onMouseLeave={(e) => {
				e.preventDefault();

				e.stopPropagation();
				handleHover(false);
				if (isActiveSlide()) return;

				handleStopVideo();
			}}
		>
			<div
				className="content"
				style={{
					opacity: 0,
					visibility: "hidden",
					display: currentlyOnScreen ? "inherit" : "none",
				}}
				ref={contentRef}
			>
				<div
					className={`slider-txt-cnt${
						isTouchDevice() ? " hover" : ""
					}`}
					ref={sliderContent}
				>
					<div className="slider-title-cnt">
						<div className="slider-index">
							{props.index + 1 < 10
								? "0" + (props.index + 1)
								: props.index + 1}
						</div>
						<div className="slider-desc-main">{props.title}</div>
					</div>
					<div className="slider-title-cnt">
						<div className={`slider-tag-new ${props.type}`}>
							<div
								className={`div-block-45${
									props.type === "work" ? " work" : ""
								}`}
							/>
							<div className="slider-tag-new-txt">
								{props.type === "solution"
									? "solution"
									: "work"}
							</div>
						</div>
						{/*			{props.tags?.split(",").map((x, i) => (
							<div
								key={"tags_" + i}
								className="slider-tag-new pink"
							>
								<div className="text-block-18">{x}</div>
							</div>
						))}*/}
					</div>
				</div>
			</div>
			<div
				key={srcVidPreview}
				className="videoContainer"
				style={{
					opacity: 1,
					display: currentlyOnScreen ? "inherit" : "none",
				}}
			>
				<video
					ref={vid}
					style={{
						position: "absolute",
						//opacity: curMode === 0 ? 0 : 1,
						display: curMode === 0 ? "none" : "inherit",
						visibility: "hidden",
					}}
					// className="media split"
					className={`media split${
						props.isPortraitMode ? " portrait" : ""
					}`}
					muted
					preload="none"
					loop
					playsInline
					draggable="false"
					src={srcVidMain}
					type="video/mp4"
				/>

				<video
					ref={vidPreview}
					style={{
						position: "absolute",
						//display: "none",
						opacity: curMode === 1 ? 0 : 1,
						zIndex: 1,
						visibility: "hidden",

						//transform: "translateZ(0)",
					}}
					// className="media split"
					className={`media${
						props.isPortraitMode ? " portrait" : ""
					}`}
					muted
					preload="none"
					loop
					playsInline
					draggable="false"
					src={srcVidPreview}
					type="video/mp4"
				/>

				{
					{
						VirtualCarShoot: (
							<Complications.VirtualCarShoot
								curScale={props.curScale}
								isSmallScreen={props.isSmallScreen}
								solution={props.solution}
								isActiveSlide={isActiveSlide()}
								curVideo={vid}
								srcVidMain={srcVidMain}
								ref={complicationsRef}
							/>
						),
						Audio2Face: (
							<Complications.Audio2Face
								curScale={props.curScale}
								isSmallScreen={props.isSmallScreen}
								solution={props.solution}
								isActiveSlide={isActiveSlide()}
								curVideo={vid}
								srcVidMain={srcVidMain}
								ref={complicationsRef}
							/>
						),
						MotionCapture: (
							<Complications.MotionCapture
								curScale={props.curScale}
								isSmallScreen={props.isSmallScreen}
								solution={props.solution}
								isActiveSlide={isActiveSlide()}
								curVideo={vid}
								srcVidMain={srcVidMain}
								ref={complicationsRef}
							/>
						),
						SocialTurnaround: (
							<Complications.SocialTurnaround
								curScale={props.curScale}
								isSmallScreen={props.isSmallScreen}
								solution={props.solution}
								isActiveSlide={isActiveSlide()}
								curVideo={vid}
								srcVidMain={srcVidMain}
								ref={complicationsRef}
							/>
						),
						Fortnite: (
							<Complications.Fortnite
								curScale={props.curScale}
								isSmallScreen={props.isSmallScreen}
								solution={props.solution}
								isActiveSlide={isActiveSlide()}
								curVideo={vid}
								srcVidMain={srcVidMain}
								ref={complicationsRef}
							/>
						),
						default: null,
					}[props.solution?.component]
				}

				<div
					className="poster"
					ref={poster}
					style={{
						backgroundImage: 'url("' + props.poster + '")',
						visibility: "hidden",
						opacity: 0,
					}}
				/>
			</div>
		</div>
	);
});

export default React.memo(SolutionSlide);
