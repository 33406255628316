import React, {
  Component,
  useState,
  useRef,
  memo,
  useEffect,
  useImperativeHandle,
} from "react";

import { gsap } from "gsap";

const SliderBulletProgress = React.forwardRef((props, ref) => {
  const smallLength = 28;
  const enlargedLength = 78;
  let bulletsVisible = 6;
  bulletsVisible =
    bulletsVisible > props.entryLength ? props.entryLength : bulletsVisible;

  const maxWidth = (bulletsVisible - 1) * smallLength + enlargedLength;

  const updateProgress = (progress = 0) => {
    [...Array(props.entryLength)].forEach((entry, i) => {
      const RangeIn = i - 1;
      const RangeOut = i + 1;

      const subtractMulti = Math.abs(
        gsap.utils.mapRange(
          RangeIn,
          RangeOut,
          -1,
          1,
          gsap.utils.clamp(RangeIn, RangeOut, progress),
        ),
      );
      const subtract = -50 * subtractMulti;

      const moveMulti = -gsap.utils.clamp(
        0,
        props.entryLength - bulletsVisible, //end move if it's only bulletsVisible in view
        progress - bulletsVisible + 2, //start move on prelast bullet
      );

      const leftVisibility = gsap.utils.clamp(-1, 0, moveMulti + i);
      const rightVisibility = gsap.utils.clamp(
        -1,
        0,
        bulletsVisible - 1 - (moveMulti + i),
      );

      const alpha =
        1 * (1 - subtractMulti) + 0.5 + 1 * (leftVisibility + rightVisibility);
      gsap.set("#bulletprogress-bullet" + i, {
        x: moveMulti * smallLength,
        width: enlargedLength + -(enlargedLength - smallLength) * subtractMulti,
        autoAlpha: alpha,
        pointerEvents: alpha > 0 ? "all" : "none",
      });
    });
  };
  useEffect(() => {
    updateProgress(1);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return { updateProgress };
    },
    [props.entryLength],
  );

  return (
    <>
      <div
        className="progress-bullet-points-cnt"
        style={{
          ...props.style,
          maxWidth: maxWidth,
          height: 40,
        }}
      >
        {[...Array(props.entryLength)].map((entry, i) => (
          <div
            id={`bulletprogress-bullet` + i}
            key={`bulletprogress-bullet` + i}
            className={`progress-bullet-container`}
            onClick={() => {
              props.onclick?.(i);
            }}
          >
            <div
              className={`progress-bullet`}
              style={{ pointerEvents: "none", background: "white" }}
            ></div>
          </div>
        ))}
      </div>
    </>
  );
});

export default memo(SliderBulletProgress);
