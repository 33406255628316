import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import cases from "../cases/cases.json";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

const componentName = "VirtualCarShoot";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"As easy as playing a racing game."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>

		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-left-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">Real-time collaboration.</Header>
				<Desc customType="desc">
					The goal is to build a creative and dynamic working
					environment by using the real-time capabilities of a game
					engine. Clients and creators can{" "}
					<White>
						instantly see how changes in car position, lighting, and
						camera angles affect the final image
					</White>{" "}
					and already have a good feeling about the film's impact
					during the process.
				</Desc>
				<CustomImage
					customType="image"
					src={`/img/cases/${shorthand}/image/img_02.png`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Professionals in their field.</Header>
			<Desc customType="desc">
				We strongly believe in the analog and human factors to bring
				digital excellence to life. That's why we started to create a
				workflow that seamlessly translates the talent of a professional
				automotive film crew into the virtual space.{" "}
				<White>
					A camera crane operated by a real cameraman, a car driven by
					a skilled stunt driver.{" "}
				</White>
				All wrapped in realistic lighting that replicates the look of a
				real-life location.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_01.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Physical accurate.</Header>
			<Desc customType="desc">
				In order to create stunning visuals, it is imperative to ensure
				that the car's reactions to external factors in both the
				operator's actions and the virtual environment are physically
				accurate. To achieve this level of realism, we use sophisticated
				physical simulations that take into account a wide range of
				physical variables, such as weight, mass, tire friction, and
				aerodynamics. By accurately modeling these factors, the{" "}
				<White>
					virtual cars are able to respond realistically to changes in
					speed, direction, and road surface
				</White>
				.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.gif`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Sustainable and future-proof.</Header>
			<Desc customType="desc">
				Virtual car shoots provide a sustainable and innovative solution
				to traditional car shoots. They{" "}
				<White>
					reduce the need for extensive travel and transportation{" "}
				</White>
				and allow for a more controlled and efficient use of resources,
				resulting in cost savings and optimized production processes. As
				the industry continues to evolve and embrace new technologies,
				it's a new step towards a green production and lower emissions.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">Technology in focus</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Sofware</div>
							<div className="credits-name">
								Unreal Engine, Houdini, Blender, Maya
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Camera Tracking</div>
							<div className="credits-name">
								Stunning AR Cam App, Vive
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
