import React from "react";

export const Pink = (props) => {
	return <span className="text-pink">{props.children}</span>;
};
export const White = (props) => {
	return <span className="text-white">{props.children}</span>;
};

export const Desc = (props) => {
	return props.children;
};

export const Header = (props) => {
	return props.children;
};

export const Quote = (props) => {
	return props.children;
};
export const Author = (props) => {
	return props.children;
};

export const CustomImage = (props) => {
	return props.children;
};

export const CustomVideo = (props) => {
	return props.children;
};

const ArticleNew = (props) => (
	<div
		className={"section-div" + (props["no-p"] ? " no-p" : "")}
		style={{ ...props.style, ...(props.fullfill && { maxWidth: "none" }) }}
	>
		{props.children}
	</div>
);

export const maxWidth = (size) => {
	let out = null;

	if (size ?? false) {
		switch (size) {
			case "xl":
				out = {
					maxWidth: "none",
				};
				break;
			case "m":
				out = {
					maxWidth: "1100px",
				};
				break;
			case "s":
				out = {
					maxWidth: "980px",
				};
				break;
		}
	}
	return out;
};

const SectionContent = (props) => (
	<div
		className={"section-content bigger" + (props.left ? " left" : "")}
		style={{
			...(props.center && { justifyContent: "center" }),
			...props.style,
			pointerEvents: "none",

			...maxWidth(props.size),
		}}
	>
		{props.children}
	</div>
);

const ArticleEl = (props) => {
	//validate input

	const header =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "header",
		)?.[0] || "";

	const desc =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "desc",
		)?.[0] || "";

	const image =
		(
			React.Children.toArray(props.children).filter(
				(x) => x.props?.customType == "image",
			)?.[0] || ""
		)?.props || null;

	// const video =
	// 	(
	// 		React.Children.toArray(props.children).filter(
	// 			(x) => x.props?.customType == "video",
	// 		)?.[0] || ""
	// 	)?.props?.src || null;

	const videoEl =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "video",
		)?.[0] ||
		"" ||
		null;

	const imageEl =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "imageEl",
		)?.[0] ||
		"" ||
		null;

	const subheaderNew = (subheaderText) => {
		return (
			<h1
				className={`section-title-sub grad-txt${
					props["small-header"] ? " small-header" : ""
				}`}
				style={{
					pointerEvents: "none",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				{subheaderText}
			</h1>
		);
	};

	const descriptionNew = (descText) => {
		return (
			<div
				className={`section-description${
					props["small-desc"] ? " small-desc" : ""
				}`}
				style={{
					pointerEvents: "none",

					...(header ? null : { marginTop: "0px" }),
				}}
			>
				{descText}
			</div>
		);
	};

	const imageRenderNew = (props) => {
		return (
			<div
				className="section-content-item-grow"
				style={{
					pointerEvents: "none",
					...(props.width ? { width: props.width } : null),
					...(props.height ? { width: props.height } : null),

					...(props.flex
						? {
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
						  }
						: null),
					...(props.maxWidth ? { maxWidth: props.maxWidth } : null),
					margin: "25px",
				}}
			>
				<div
					className="section-item-img-cnt"
					style={{
						position: "relative",
						width: "100%",
						height: "100%",
					}}
				>
					{props.ar ? (
						<div
							className="section-item-img-aspect"
							style={{
								position: "relative",
								backgroundImage: `url("${props.src}")`,
								transform: "scale(1)",

								...(props.backgroundSize
									? {
											backgroundSize:
												props.backgroundSize,
									  }
									: null),
								...(props.backgroundPosition
									? {
											backgroundPosition:
												props.backgroundPosition,
									  }
									: null),

								...(props.ar
									? { aspectRatio: props.ar }
									: null),

								...(props.round
									? { borderRadius: "25px" }
									: null),
								...(props.borderRadius
									? { borderRadius: props.borderRadius }
									: null),
							}}
						/>
					) : (
						<div
							className="section-item-img"
							style={{
								position: "relative",
								backgroundImage: `url("${props.src}")`,
								transform: "scale(1)",
								...(props.backgroundSize
									? {
											backgroundSize:
												props.backgroundSize,
									  }
									: null),

								...(props.ar
									? { aspectRatio: props.ar }
									: null),

								...(props.round
									? { borderRadius: "25px" }
									: null),
								...(props.borderRadius
									? { borderRadius: props.borderRadius }
									: null),
							}}
						/>
					)}

					{props.outline ? (
						<div
							className="section-item-img-border"
							style={{
								position: "absolute",
								border: "solid",
								borderColor: "white",
								borderRadius: "40px",
								borderWidth: "5px",

								top: 0,
								left: 0,
								width: "calc( 100% + 30px)",
								height: "calc( 100% + 30px)",
								marginLeft: "-15px",
								marginTop: "-15px",
								...(props.borderRadiusOutline
									? {
											borderRadius:
												props.borderRadiusOutline,
									  }
									: null),
							}}
						/>
					) : null}
				</div>
			</div>
		);
	};

	const videoRenderNew = (playerChildren) => {
		return (
			<div
				className="section-content-item-grow"
				style={{
					pointerEvents: "none",
					flex: "1 1",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{playerChildren}
			</div>
		);
	};

	const Article = (props) => (
		<div className="article-el">{props.children}</div>
	);

	const Element = () => {
		let articleOut = null;

		switch (props.type) {
			case "text-left-onethird":
				articleOut = (
					<ArticleNew {...props}>
						<SectionContent {...props} left>
							<div className="section-content-item-half left one-third">
								{header ? subheaderNew(header) : null}
								{descriptionNew(desc)}
							</div>

							{imageEl
								? videoRenderNew(imageEl)
								: videoEl
								? videoRenderNew(videoEl)
								: imageRenderNew(image)}
						</SectionContent>
					</ArticleNew>
				);
				break;
			case "text-right-onethird":
				articleOut = (
					<ArticleNew {...props}>
						<SectionContent {...props}>
							{imageEl
								? videoRenderNew(imageEl)
								: videoEl
								? videoRenderNew(videoEl)
								: imageRenderNew(image)}
							<div className="section-content-item-half-copy right one-third">
								{header ? subheaderNew(header) : null}
								{descriptionNew(desc)}
							</div>
						</SectionContent>
					</ArticleNew>
				);
				break;
			case "text-left-half":
				articleOut = (
					<ArticleNew {...props}>
						<SectionContent {...props} left>
							<div className="section-content-item-half left">
								{header ? subheaderNew(header) : null}
								{descriptionNew(desc)}
							</div>
							{imageEl
								? videoRenderNew(imageEl)
								: videoEl
								? videoRenderNew(videoEl)
								: imageRenderNew(image)}
						</SectionContent>
					</ArticleNew>
				);
				break;
			case "text-right-half":
				articleOut = (
					<ArticleNew {...props}>
						<SectionContent {...props}>
							{imageEl
								? videoRenderNew(imageEl)
								: videoEl
								? videoRenderNew(videoEl)
								: imageRenderNew(image)}
							<div className="section-content-item-half right">
								{header ? subheaderNew(header) : null}
								{descriptionNew(desc)}
							</div>
						</SectionContent>
					</ArticleNew>
				);
				break;
			default:
				break;
		}
		return articleOut;
	};

	return <Element />;
};

export const QuoteEl = (props) => {
	const quote =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "quote",
		)?.[0] || "";

	const author =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "author",
		)?.[0] || "";

	let outEl = (
		<ArticleNew {...props} style={{ height: "100%" }}>
			<SectionContent
				{...props}
				left
				style={{
					height: "100%",
				}}
			>
				<div
					className={
						"section-hero quote" +
						(props["no-p-quote"] ? " no-p-quote" : "")
					}
					style={{
						display: "flex",
						height: "100%",
						flexDirection: "column",
					}}
				>
					<div
						className="section-title-sub quote center small-header grad-txt"
						style={{
							paddingLeft: 0,
							paddingRight: 0,
							flex: "1 0 auto",
						}}
					>
						{quote}
					</div>
					{author ? (
						<div
							className="section-description quote small-desc center"
							style={{
								bottom: 0,
								paddingLeft: 0,
								paddingRight: 0,
								width: "100%",
								maxWidth: "none",
							}}
						>
							{author}
						</div>
					) : null}
				</div>
			</SectionContent>
		</ArticleNew>
	);
	return outEl;
};

const flatten = (children, flat = []) => {
	flat = [...flat, ...React.Children.toArray(children)];

	if (children.props && children.props.children) {
		return flatten(children.props.children, flat);
	}

	return flat;
};
const simplify = (children) => {
	const flat = flatten(children);

	return flat.map(({ key, ref, type, props: { children, ...props } }) => ({
		key,
		ref,
		type,
		props,
	}));
};

const urlParams = new URLSearchParams(window.location.search);
const debugParam = urlParams.get("debug");

export default React.memo(ArticleEl, (prev, next) => {
	if (debugParam) {
		return false;
	} else {
		return (
			JSON.stringify(simplify(prev.children)) ===
			JSON.stringify(simplify(next.children))
		);
	}
});
