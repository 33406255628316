import React, { useRef, useState, useEffect } from "react";

import { LandingPageMemo } from "./LandingPage";
import Universe from "./components/Elements/Universe";
import VideoModal from "./components/VideoModal";
import BtnParallax from "./components/Elements/BtnParallax";
import VideoElement from "./components/Elements/VideoElement";
import HomeVideoSlider from "./components/Elements/HomeVideoSlider";
import ImageSlider from "./components/Elements/ImageSlider";
import useLocalStorage from "./hooks/useLocalStorage";

import { isMobile, isSafari, isIOS } from "./hooks/isMobile";

import useOnScreen from "./hooks/useOnScreen";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";

import welcomeVideoData from "./data/welcome-video/welcomeVideo.json";
import { getAdditionalVideo } from "./data/additional-video/getAdditionalVideoHelper";
import getWelcomeVideoHelper from "./data/welcome-video/getWelcomeVideoHelper";

import { useEffectOnce } from "./hooks/useEffectOnce";
import { sendGACustomEvent } from "./hooks/googleAnalytics";

import { White } from "./components/Elements/ArticleEl";

import "./webflow/home.css";

import vidLandingInfluencerSafari from "./vid/landing-influencer.mp4";
import vidLandingInfluencerOther from "./vid/landing-influencer.webm";

const Home = React.forwardRef((props, ref) => {
	const [fullAccess, setFullAccess] = useLocalStorage("full-access", true);

	const coverVideoRef = useRef(null);
	const videoModalRef = useRef(null);
	const isCoverVideoVisible = useOnScreen(coverVideoRef);
	let location = useLocation();

	const curWelcomeVideoData = useState(
		welcomeVideoData.find(
			(x) => x.shorthand === location?.pathname.replaceAll("/", ""),
		) || welcomeVideoData.find((x) => x.shorthand === "default"),
	);

	const handleChangeHeaderState = (newState, curCase = null) => {
		switch (newState) {
			case "typo":
				props.changeHeaderState("typo");
				break;
			// case "none":
			// 	props.changeHeaderState("logo", {
			// 		hideNav: true,
			// 	});
			// 	break;
			case "player":
				props.changeHeaderState("logo", {
					hideNav: true,
					customBack: {
						icon: isMobile ? "cross" : "cross",
						text: " ",
						fn: () => {
							handleVideoModalOpen(false);
						},
					},
				});
				break;
		}
	};

	const handleVideoModalOpen = (bool) => {
		if (!videoModalRef.current?.open) return;

		videoModalRef.current.open(bool, () => {
			handleVideoModalOpen(false);
		});

		if (bool) {
			handleChangeHeaderState("player");
			sendGACustomEvent(
				props.globalContext,
				"fs_welcome",
				curWelcomeVideoData?.[0]?.["name"],
			);
		} else {
			handleChangeHeaderState("typo");
			sendGACustomEvent(
				props.globalContext,
				"fe_welcome",
				curWelcomeVideoData?.[0]?.["name"],
			);
		}
	};

	useEffectOnce(() => {
		handleChangeHeaderState("typo");
	});

	// const parentDivRef = useRef(null);

	// const homesliderRef = useRef(null);

	// const absolutePosition = (el) => {
	// 	var found,
	// 		left = 0,
	// 		top = 0,
	// 		width = 0,
	// 		height = 0,
	// 		offsetBase = absolutePosition.offsetBase;
	// 	if (!offsetBase && document.body) {
	// 		offsetBase = absolutePosition.offsetBase =
	// 			document.createElement("div");
	// 		offsetBase.style.cssText = "position:absolute;left:0;top:0";
	// 		document.body.appendChild(offsetBase);
	// 	}
	// 	if (
	// 		el &&
	// 		el.ownerDocument === document &&
	// 		"getBoundingClientRect" in el &&
	// 		offsetBase
	// 	) {
	// 		var boundingRect = el.getBoundingClientRect();
	// 		var baseRect = offsetBase.getBoundingClientRect();
	// 		found = true;
	// 		left = boundingRect.left - baseRect.left;
	// 		top = boundingRect.top - baseRect.top;
	// 		width = boundingRect.right - boundingRect.left;
	// 		height = boundingRect.bottom - boundingRect.top;
	// 	}
	// 	return {
	// 		found: found,
	// 		left: left,
	// 		top: top,
	// 		width: width,
	// 		height: height,
	// 		right: left + width,
	// 		bottom: top + height,
	// 	};
	// };

	// // React.useEffect(() => {
	// // 	if (homesliderRef.current) {
	// // 		console.log(homesliderRef.current.getBoundingClientRect().bottom);
	// // 	}
	// // }, []);
	// const [searchResultTop, setSearchResultTop] = React.useState(0);
	// React.useLayoutEffect(() => {
	// 	function updatePosition() {
	// 		console.log(parentDivRef.current.scrollTop);
	// 		setSearchResultTop(
	// 			homesliderRef.current.offsetTop +
	// 				parentDivRef.current.offsetTop,
	// 		);

	// 		setSearchResultTop(
	// 			homesliderRef.current.getBoundingClientRect().top +
	// 				parentDivRef.current.scrollTop,
	// 		);
	// 		// console.log(absolutePosition(homesliderRef.current).top);
	// 		// console.log(homesliderRef.current.getBoundingClientRect());
	// 	}
	// 	window.addEventListener("resize", updatePosition);
	// 	updatePosition();
	// 	return () => window.removeEventListener("resize", updatePosition);
	// }, []);

	return (
		<>
			<div className="page-content scroll">
				<div
					className="landing-page scroll"
					ref={coverVideoRef}
					style={{
						visibility: isCoverVideoVisible ? "visible" : "hidden",
					}}
				>
					<LandingPageMemo
						fullAccess={fullAccess}
						key={props.cKey + "-landing"}
						currentPathname={props.currentPathname}
						window={props.window}
						initLoad={props.initLoad}
						changeCursorState={props.changeCursorState}
						changeHeaderState={props.changeHeaderState}
						closeSearch={props.closeSearch}
						isSearchOpen={props.isSearchOpen}
						changeView={props.changeView}
						appView={props.appView}
						deviceType={props.deviceType}
						forcehold={!isCoverVideoVisible}
						ref={ref}
					/>
				</div>
				{/*			<div
					className={"section-background centered"}
					style={{
						width: "100%",
						height: "100%",
						transform: "translateY(-35%) translateX(15%) scale(.5)",

						background: "red",
						position: "absolute",
						zIndex: -1,
						top: searchResultTop,
					}}
				/>*/}
				<div className="landing-scroll-el">
					<div className="section-wrapper">
			
						<div className="section-div">
							<div className="section-content">
								<div className="section-hero">
									<div className="section-hero">
										<div className="section-title-sub grad-txt">
											How we do it
										</div>
									</div>
								</div>
								<div className="section-description homeslider">
									<White>
										We harness the flexibility of real-time
										workflows to create a wide range of both
										dynamic and linear content - spanning
										from interactive experiences and games to commercials.
									</White>
								</div>
							</div>
						</div>
						<div className="section-div-wrapper">
							{/*<div className="section-background right" />*/}
							<div className="section-div homeslider no-p" style={{marginTop:20}}>
								<div className="section-content bigger">
								<HomeVideoSlider preload="metadata" />
								</div>
							</div>
						</div>
	
		
						<div className="section-div bigger">
							<div className="router-wrapper">
								<div className="router-row">
									<div className="router-item left">
										<div className="section-description router">
											See more
										</div>
										<div className="section-title-sub router">
											Lean back and explore our work.
										</div>

										<Link
											to={{
												pathname: "/cases/work",
											}}
											state={{
												from: location?.pathname || "/",
											}}
											style={{
												pointerEvents: "auto",
											}}
										>
											<BtnParallax text={"Work"} />
										</Link>

										<div className="section-router-spacer" />
										<div className="router-img work" />
									</div>
									<div className="router-item right">
										<div className="section-description router">
											Learn more
										</div>
										<div className="section-title-sub router">
											Get an insight into the solutions we
											offer.
											<br />
										</div>

										<Link
											to={{
												pathname: "/cases/solutions",
											}}
											state={{
												from: location?.pathname || "/",
											}}
											style={{
												pointerEvents: "auto",
											}}
										>
											<BtnParallax text={"Solutions"} />
										</Link>

										<div className="section-router-spacer" />
										<div className="router-img" />
									</div>
								</div>
								<div className="router-item full bottom">
									<div className="section-description router">
										Let's talk
									</div>
									<div className="section-title-sub router">
										Ready to dive in?
									</div>

									<Link
										to={{
											pathname: "/get-in-touch",
										}}
										state={{
											from: location?.pathname || "/",
										}}
										style={{
											pointerEvents: "auto",
										}}
									>
										<BtnParallax text={"Get in Touch"} />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<VideoModal
				ref={videoModalRef}
				changeCursorState={props.changeCursorState}
				// src={getWelcomeVideoHelper(
				// 	curWelcomeVideoData[0].shorthand,
				// 	"1080p",
				// 	"film",
				// )}
			/>
		</>
	);
});

export default React.memo(Home);
