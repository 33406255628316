import React, { useRef, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { initGA } from "../hooks/googleAnalytics";

import BtnParallax from "./Elements/BtnParallax";

import { Cookies } from "react-cookie-consent";

import { Link } from "react-router-dom";

const CookieConsent = React.forwardRef((props, ref) => {
	const [cookies, setCookie] = useCookies(["privacy_consent"]);
	const [closed, setClosed] = useState(false);

	const debug = props.debug || false;

	const initGoogleAnalytics = () => {
		console.log("init google analytics");
		if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
			initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
		} else {
			console.warn("Google Analytics ID missing");
		}
	};

	const handleAccept = () => {
		setCookie("privacy_consent", "accept_all", { path: "/" });
		initGoogleAnalytics();
		handleClose();
	};

	const handleDecline = () => {
		handleDeclineCookie();
		handleClose();
	};
	const handleClose = () => {
		setClosed(true);
	};

	const isAccepted = cookies.privacy_consent === "accept_all";

	useEffect(() => {
		const privacyConsent = cookies.privacy_consent === "accept_all";
		if (privacyConsent) {
			initGoogleAnalytics();
		}
	}, []);

	return (isAccepted && !debug) || closed ? null : (
		<div className="cookie-wrapper w-clearfix">
			<div className="cookie-background">
				<div className="cookie-consent-text">
					This website uses cookies for analysis purposes. By
					continuing to use this website you are giving consent to
					cookies being used. For information on cookies and how you
					can disable them visit our{" "}
					<Link to="/privacy" style={{ textDecoration: "underline" }}>
						privacy policy
					</Link>
					.
				</div>
				<BtnParallax text={"Continue"} onclick={handleAccept} />
			</div>
		</div>
	);
});

export const handleDeclineCookie = () => {
	// Remove Google Analytics cookies
	console.log(Cookies);
	Cookies.remove("privacy_consent");
	Cookies.remove("_ga");
	Cookies.remove("_gat");
	Cookies.remove("_gid");
};

export default CookieConsent;
