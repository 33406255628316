import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import cases from "../cases/cases.json";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

const componentName = "Audio2Face";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<QuoteSliderSlick>
			<QuoteEl size="xl" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Audio-to-Face technology is a cost-effective and efficient
					alternative to traditional facial performance capture."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>

		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-left-onethird"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">Real-time facial animation.</Header>
				<Desc customType="desc">
					By using deep neural networks, Audio-to-Face allows you to
					bring characters to life quickly and easily.{" "}
					<White>
						Record an audio track or use a live microphone to
						generate 3D face animations
					</White>
					. We use various toolkits and AI algorithms to ensure a
					perfect match between the audio and the animation.
				</Desc>
				<CustomImage
					customType="image"
					src={`/img/cases/${shorthand}/image/img_01.png`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Synthesize Emotions.</Header>
			<Desc customType="desc">
				<White>
					Cutting-edge machine learning turns speech into facial
					animations
				</White>{" "}
				by analyzing speech patterns and transforming them into a series
				of keyframes, which represent the facial movements and
				expressions. These keyframes are then refined to create a final
				animation that captures the speaker's emotions and expressions.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Babel.</Header>
			<Desc customType="desc">
				It's possible to processes any language, making it a versatile
				tool for all. Whether you're working on a project that involves
				multilingual characters or need to quickly generate facial
				animations{" "}
				<White>
					for a variety of languages, this technology has got you
					covered
				</White>
				. An ideal choice for a wide range of applications, from video
				game development to animated film production
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Built to scale.</Header>
			<Desc customType="desc">
				With the help of Audio-to-Face, we can bring entire scenes to
				life. Run multiple instances with as many characters as you
				want, each animated from a different audio track or all from
				one. Whether it's{" "}
				<White>
					a two-person dialogue, a musical showdown between a trio, or
					a synchronized mass performance, we bring your characters to
					life
				</White>{" "}
				with ease. And... action.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">Technology in focus</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Software</div>
							<div className="credits-name">
								Nvidia Omniverse, Metahuman Animator, Facegood
								Avatary & iClone
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
