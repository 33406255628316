import React from "react";

import caseData from "./data/cases/cases.json";
import welcomeVideoData from "./data/welcome-video/welcomeVideo.json";
import additionalVideoData from "./data/additional-video/additionalVideo.json";

const AdminMenu = React.forwardRef((props, ref) => {
	const createArrayFromData = (data) => {
		let outArr = [];
		const videoFiles = [
			"portrait-pr",
			"portrait",
			"landscape-pr",
			"landscape",
			"film",
		];
		const resolutions = ["360p", "540p", "720p", "1080p", "1440p", "2160p"];
		data.forEach((caseItem) => {
			videoFiles.forEach((videoFile) => {
				resolutions.forEach((resolution) => {
					// Check if Resolution is available
					const resPath = videoFile + "-" + resolution;
					const caseVideoResUrl = caseItem[resPath];

					if (caseVideoResUrl && caseVideoResUrl.length > 0) {
						outArr.push([
							caseItem.shorthand,
							resPath,
							caseVideoResUrl,
						]);
					}
				});
			});
		});

		return outArr;
	};

	const createArrFromWelcomeData = () => {
		return createArrayFromData(welcomeVideoData);
	};

	const createArrFromAdditionalData = () => {
		return createArrayFromData(additionalVideoData);
	};

	const createArrFromCaseData = () => {
		let outArr = [];
		const videoFiles = [
			"portrait-pr",
			"portrait",
			"landscape-pr",
			"landscape",
			"film",
		];
		const resolutions = ["720p", "1080p", "1440p", "2160p"];
		caseData.forEach((caseItem) => {
			videoFiles.forEach((videoFile) => {
				resolutions.forEach((resolution) => {
					// Check if Resolution is available
					const resPath = videoFile + "-" + resolution;
					const caseVideoResUrl = caseItem[resPath];

					if (caseVideoResUrl.length > 0) {
						outArr.push([
							caseItem.shorthand,
							resPath,
							caseVideoResUrl,
						]);
					}
				});
			});
		});

		return outArr;
	};

	const downloadCSV = (arrData, outputName) => {
		let csvContent =
			"data:text/csv;charset=utf-8," +
			arrData.map((e) => e.join(",")).join("\n");

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", outputName);
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".
	};
	const handleDownloadCasesDownloads = () => {
		const casesCsvData = createArrFromCaseData();

		downloadCSV(casesCsvData, "cases_download_data.csv");
	};

	const handleDownloadWelcomeDownloads = () => {
		const welcomeCsvData = createArrFromWelcomeData();
		downloadCSV(welcomeCsvData, "welcome_download_data.csv");
	};

	const handleDownloadAdditionalDownloads = () => {
		const additionalCsvData = createArrFromAdditionalData();
		downloadCSV(additionalCsvData, "additional_download_data.csv");
	};

	return (
		<div
			style={{
				position: "absolute",
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<button onClick={handleDownloadCasesDownloads}>
				Download Cases Videos csv
			</button>
			<button onClick={handleDownloadWelcomeDownloads}>
				Download Welcome Videos csv
			</button>
			<button onClick={handleDownloadAdditionalDownloads}>
				Download Additional Videos csv
			</button>
		</div>
	);
});

export default AdminMenu;
