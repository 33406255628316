import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";

import BtnParallaxGetInTouch from "./Elements/BtnParallaxGetInTouch";
import SearchBar from "./Elements/SearchBar";
import BtnParallax from "./Elements/BtnParallax";
import NavMenu from "./NavMenu";

import useFilterFromLocation from "../hooks/useFilterFromLocation";

import caseData from "../data/cases/cases.json";

const Header = React.forwardRef((props, ref) => {
	let location = useLocation();
	let curFilter = useFilterFromLocation();

	const loaded = useRef(false);

	const dataState = useRef(null);
	const lclState = useRef(null);

	const searchBarRef = useRef(null);
	const searchBarDropDownRef = useRef(null);
	const [dropOpen, setDropOpen] = useState(false);

	const headerRef = useRef(null);
	const logoRef = useRef(null);
	const lamaRef = useRef(null);
	const backBtnRef = useRef(null);
	const backTxtRef = useRef(null);
	const backTxtSpacerRef = useRef(null);
	const backTxtPrevRef = useRef(null);
	const backIcon = useRef(null);
	const contactCnt = useRef(null);

	const backTxtSpacerContainerRef = useRef(null);
	const backTxtContainerRef = useRef(null);

	useEffect(() => {
		// console.log(" Header remount");
	}, []);
	const switchHeaderState = (newState, newData = null) => {
		//console.log(newData);

		if (
			(newState == lclState.current &&
				JSON.stringify(newData) == JSON.stringify(dataState.current)) ||
			(JSON.stringify(newData?.searchBar) ==
				JSON.stringify(dataState.current?.searchBar) &&
				newData?.searchBar)
		)
			return;

		lclState.current = newState;
		dataState.current = newData;

		const durationTransition = 0.5;
		const durationTransitionBack = 0.25;

		switch (newState) {
			case "typo":
				gsap.to(logoRef.current, {
					duration: durationTransition,
					yPercent: 0,
					autoAlpha: 1,
					overwrite: true,
				});
				gsap.to(lamaRef.current, {
					duration: (durationTransition / 3) * 2,
					yPercent: 100,
					autoAlpha: 0,
					overwrite: true,
				});

				gsap.to(backBtnRef.current, {
					duration: (durationTransitionBack / 3) * 2,
					yPercent: 25,
					autoAlpha: 0,
					overwrite: true,
				});

				gsap.to([headerRef.current], {
					backgroundPosition: "0% 0%",
					//backgroundImage: "linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0))",
				});

				gsap.to(searchBarRef.current, {
					duration: durationTransitionBack,
					yPercent: 0,
					autoAlpha: 0,
					delay: 0,
					overwrite: true,
				});

				gsap.to(contactCnt.current, {
					duration: (durationTransition / 3) * 2,
					yPercent: 0,
					autoAlpha: 1,
					overwrite: true,
				});

				break;

			case "logo":
				let direction = 1;
				if (!newData?.hideNav) {
					gsap.to(lamaRef.current, {
						duration: durationTransition,
						yPercent: 0,
						autoAlpha: 1,
						overwrite: true,
					});
					gsap.to(logoRef.current, {
						duration: (durationTransition / 3) * 2,
						yPercent: -100,
						autoAlpha: 0,
						overwrite: true,
					});
					gsap.to(contactCnt.current, {
						duration: (durationTransition / 3) * 2,
						yPercent: 0,
						autoAlpha: 1,
						overwrite: true,
					});
				} else {
					gsap.to(lamaRef.current, {
						duration: (durationTransition / 3) * 2,
						yPercent: -100,
						autoAlpha: 0,
						overwrite: true,
					});
					gsap.to(logoRef.current, {
						duration: (durationTransition / 3) * 2,
						yPercent: -100,
						autoAlpha: 0,
						overwrite: true,
					});
					gsap.to(contactCnt.current, {
						duration: (durationTransition / 3) * 2,
						yPercent: -100,
						autoAlpha: 0,
						overwrite: true,
					});
					direction = -1;
				}

				gsap.killTweensOf(backBtnRef.current);

				const Switch = () => {
					let curIconClass = "back-icon";
					switch (newData?.customBack?.icon) {
						case "arrow":
							curIconClass = curIconClass + " arrow";
							break;
						case "cross":
							curIconClass = curIconClass + " arrow cross";
							break;
					}

					if (!newData?.customBack?.textPrev) {
						switch (newData?.customBack?.color) {
							case "active":
								curIconClass = curIconClass + " active";
								break;
						}
					}

					backIcon.current.className = curIconClass;

					let curTxtClass = "back-txt";
					switch (newData?.customBack?.color) {
						case "active":
							curTxtClass = curTxtClass + " active";
					}

					backTxtRef.current.innerText = newData?.customBack?.text;
					backTxtRef.current.className = curTxtClass;

					if (newData?.customBack?.textPrev) {
						backTxtPrevRef.current.innerText =
							newData?.customBack?.textPrev;
						backTxtSpacerRef.current.innerText = ">";
						gsap.set(
							[
								backTxtContainerRef.current,
								backTxtSpacerContainerRef.current,
							],
							{ opacity: 1, display: "block" },
						);
					} else {
						backTxtPrevRef.current.innerText = "";
						backTxtSpacerRef.current.innerText = "";
						gsap.set(
							[
								backTxtContainerRef.current,
								backTxtSpacerContainerRef.current,
							],
							{ opacity: 0, display: "none" },
						);
					}
				};

				if (!newData?.customBack?.text) {
					gsap.to(backBtnRef.current, {
						duration: durationTransitionBack,
						yPercent: 0,
						autoAlpha: 0,
						delay: 0,
						overwrite: true,
					});
				} else {
					gsap.to(backBtnRef.current, {
						duration: (durationTransitionBack / 3) * 2,
						yPercent: 25 * direction,
						autoAlpha: 0,
						overwrite: true,
						onComplete: () => {
							Switch();
							gsap.to(backBtnRef.current, {
								duration: (durationTransitionBack / 3) * 2,
								yPercent: 0,
								autoAlpha: 1,
								overwrite: true,
							});
						},
					});
				}

				if (!newData?.searchBar?.active) {
					handleDropDown(false, true);
					gsap.to(searchBarRef.current, {
						duration: durationTransitionBack,
						yPercent: -50,
						autoAlpha: 0,
						delay: 0,
						overwrite: true,
					});
				} else {
					gsap.to(searchBarRef.current, {
						duration: (durationTransitionBack / 3) * 2,
						yPercent: -50,
						autoAlpha: 0,
						overwrite: true,
						onComplete: () => {
							gsap.to(searchBarRef.current, {
								duration: (durationTransitionBack / 3) * 2,
								yPercent: 0,
								autoAlpha: 1,
								overwrite: true,
							});
						},
					});
				}

				gsap.to([headerRef.current], {
					backgroundPosition: "0% 0%",
					//backgroundImage:"linear-gradient(rgba(0, 0, 0, .75) 63%,rgba(0, 0, 0, 0))",
				});

				break;
			default:
				break;
		}
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				changeHeaderState(newState, newData) {
					switchHeaderState(newState, newData);
				},

				closeSearch() {
					handleDropDown(false);
				},
				isSearchOpen() {
					return dropOpen;
				},
			};
		},
		[dropOpen],
	);

	const handleEsc = (e) => {
		var isEscape = false;
		if ("key" in e) {
			isEscape = e.key === "Escape" || e.key === "Esc";
		} else {
			isEscape = e.keyCode === 27;
		}
		if (isEscape) {
			dataState?.current?.customBack?.fn?.();
		}
	};

	useEffect(() => {
		if (!loaded.current) {
			loaded.current = true;

			gsap.to(headerRef.current, 0.5, {
				autoAlpha: 1,
				startAt: { yPercent: -100 },
				yPercent: 0,
				delay: 1.5,
			});
		}

		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	const handleDropDown = (open, instant = false) => {
		const dropDuration = 0.3;

		if (open) {
			gsap.to(searchBarDropDownRef.current, {
				duration: instant ? 0 : dropDuration,
				autoAlpha: 1,
			});
			setDropOpen(true);
		} else {
			gsap.to(searchBarDropDownRef.current, {
				duration: instant ? 0 : dropDuration,
				autoAlpha: 0,
			});
			setDropOpen(false);
		}
	};

	const toogleDropOpen = () => {
		handleDropDown(!dropOpen);
	};

	const handleFilterItemHover = (e, bool) => {
		const hasHover = e.target.classList.contains("hover");
		if (bool && !hasHover) {
			e.target.classList.add("hover");
		} else if (!bool && hasHover) {
			e.target.classList.remove("hover");
		}
	};

	const handleFilterItemClick = (e) => {
		const clickedFilter = e.target?.dataset?.filter;
		const isCurFilter = curFilter == clickedFilter;

		if (isCurFilter) return;

		props.setFilter(clickedFilter);

		handleDropDown(false);
	};

	return (
		<>
			<div
				className="header_v3"
				style={{ opacity: 0, pointerEvents: "none" }}
				ref={headerRef}
			>
				<div className="header-logo-cnt-v3">
					<Link
						to="/"
						className={
							location.pathname === "/" ? "disabled-link" : "link"
						}
						style={{ pointerEvents: "auto" }}
					>
						<div className="header-logo" ref={logoRef}></div>
						<div className="lamalogo" ref={lamaRef}></div>
					</Link>
				</div>
				<div className="header-center-btn-cnt" ref={backBtnRef}>
					<div
						className="header-back-btn"
						style={{ pointerEvents: "auto" }}
						onClick={() => {
							dataState?.current?.customBack?.fn?.();
						}}
					>
						{/*<Link
						to="/"
						className={"header-back-btn"}
						style={{ pointerEvents: "auto" }}
					>*/}
						<div
							className="back-icon"
							ref={backIcon}
							style={{ pointerEvents: "none" }}
						/>
						<div
							className="back-txt-cnt"
							ref={backTxtContainerRef}
							style={{ pointerEvents: "none" }}
						>
							<div
								className="back-txt"
								ref={backTxtPrevRef}
							></div>
							<div className="nav-header-underline-cnt" />
						</div>
						<div
							className="back-txt-cnt"
							ref={backTxtSpacerContainerRef}
							style={{ pointerEvents: "none" }}
						>
							<div
								className="back-txt spacer"
								ref={backTxtSpacerRef}
							></div>
						</div>
						<div
							className="back-txt-cnt"
							style={{ pointerEvents: "none" }}
						>
							<div className="back-txt" ref={backTxtRef}></div>
						</div>
					</div>
					{/*</Link>*/}
				</div>

				<div
					className="searchbar-header-cnt"
					ref={searchBarRef}
					onClick={!dropOpen ? toogleDropOpen : null}
					style={{ pointerEvents: "auto" }}
				>
					<div className="searchbar-input">
						<div className="searchbar-logo" />
						{
							{
								all: (
									<>
										<div className="searchbar-tag-bar">
											Solutions
										</div>
										<div className="searchbar-tag-bar">
											Work
										</div>
									</>
								),
								solution: (
									<div className="searchbar-tag-bar">
										Solutions
									</div>
								),
								work: (
									<div className="searchbar-tag-bar">
										Work
									</div>
								),
							}[curFilter]
						}
					</div>

					<div
						className="searchbar-drop-down"
						ref={searchBarDropDownRef}
						style={{ zIndex: -1, opacity: 0, visibility: "hidden" }}
					>
						<div className="searchbar-dropdown-title">Cases</div>
						<div className="searchbar-filter-row">
							<div
								className={`searchbar-filter-item${
									curFilter == "all" ? " active" : ""
								}`}
								onMouseEnter={(e) =>
									handleFilterItemHover(e, true)
								}
								onMouseLeave={(e) =>
									handleFilterItemHover(e, false)
								}
								onClick={handleFilterItemClick}
								data-filter={"all"}
							>
								All
							</div>

							<div
								className={`searchbar-filter-item${
									curFilter == "solution" ? " active" : ""
								}`}
								onMouseEnter={(e) =>
									handleFilterItemHover(e, true)
								}
								onMouseLeave={(e) =>
									handleFilterItemHover(e, false)
								}
								onClick={handleFilterItemClick}
								data-filter={"solution"}
							>
								Solutions
							</div>
							<div
								className={`searchbar-filter-item${
									curFilter == "work" ? " active" : ""
								}`}
								onMouseEnter={(e) =>
									handleFilterItemHover(e, true)
								}
								onMouseLeave={(e) =>
									handleFilterItemHover(e, false)
								}
								onClick={handleFilterItemClick}
								data-filter={"work"}
							>
								Work
							</div>
						</div>
					</div>
				</div>

				<NavMenu reference={contactCnt} setFilter={props.setFilter} />
			</div>
		</>
	);
});

export default Header;
