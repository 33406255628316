import React, { useContext, useState } from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { HelmetProvider } from "react-helmet-async";

import { getUUID, randomColorName } from "./hooks/uniqueID";
import "./index.css";

import "./webflow/normalize.css";
import "./webflow/webflow.css";
import "./webflow/stunning-345464.webflow.css";
import "./webflow/additional.css";
import "./webflow/slider.css";
import "./webflow/modal-videoplayer.css";
import "./webflow/universe.css";
import "./webflow/sections.css";
import "./webflow/burger-icon.css";
import "./webflow/home-slider.css";
import "./webflow/videoelement.css";
import "./webflow/preloader.css";

import App from "./App";

if (process.env.NODE_ENV !== "development") console.log = () => {}; //disable console log in production

//Polyfills
if (![].at) {
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

export const GlobalContext = React.createContext();

export default function ContexWrapper() {
  const [globalContext, setGlobalContext] = useState({
    sessionID: getUUID(),
    //sessionColor: randomColorName(),
  });
  return (
    <GlobalContext.Provider value={[globalContext, setGlobalContext]}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </GlobalContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <ContexWrapper />
  </CookiesProvider>,
);
