import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";
import { isMobile } from "../../hooks/isMobile";
import BtnParallax from "../../components/Elements/BtnParallax";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageElement from "../../components/Elements/ImageElement";
import ImageSlider from "../../components/Elements/ImageSlider";

import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import cases from "../cases/cases.json";

const componentName = "KontraKNextToYou";

const shorthand = cases.find((x) => x.component === componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen, handleVisitSite) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					A worlds-first interactive music video
				</Header>
				<Desc customType="desc">
					<White>
						Kontra K's Next to You immerses the viewers into the
						scene of a crime.
					</White>{" "}
					As the centerpiece of the 'Labyrinth' album release,
					<White>
						{" "}
						it captivated fans and new audiences with its innovative
						and compelling storytelling.{" "}
					</White>{" "}
				</Desc>

				<CustomImage
					maxWidth={isMobile ? null : "25%"}
					customType="image"
					round={isMobile ? true : true}
					flex
					backgroundSize={"cover"}
					backgroundPosition={"center"}
					ar={isMobile ? 16 / 9 : 1}
					outline={isMobile ? false : true}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_03.jpg`
							: `/img/cases/${shorthand}/image/kontrak_app_icon.jpg`
					}
				/>

				{/*<CustomImage
					maxWidth={isMobile ? "45%" : "25%"}
					customType="image"
					flex
					backgroundPosition={"center"}
					backgroundSize={"cover"}
					ar={1}
					outline
					round
					src={`/img/cases/${shorthand}/image/kontrak_app_icon.jpg`}
				/>*/}
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"German rapper launches new album with [...] a world-first
					interactive music video."
				</Quote>
				<Author customType="author">
					<White>- shots.net</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"This is awesome! Type R 2.0 #witchhunter"
				</Quote>
				<Author customType="author">
					<White>
						- Paul Knott,<i> Creative Director at adam&eveDDB</i>
					</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Solve a murder mystery in Kontra K's amazing interactive
					music video."
				</Quote>
				<Author customType="author">
					<White>- adage.com</White>
				</Author>
			</QuoteEl>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">„The Best of Navigation”</Quote>
				<Author customType="author">
					<White>- awwwards.com</White>
				</Author>
			</QuoteEl>
			{/*		<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"[...] with the help of an interactive web player, the
					viewer controls the perspective in the video and thus has a
					direct influence on the action."
				</Quote>
				<Author customType="author">
					<White>- juice.de</White>
				</Author>
			</QuoteEl>*/}
		</QuoteSliderSlick>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					round
					aspectratio={isMobile ? 3 / 4 : null}
					alt={`Award grid of FIVE MINUTES achievements`}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Film"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>
						Next to You is an innovative take on the "Choose Your
						Own Adventure" genre. With a simple tap on the screen
					</White>{" "}
					or a press of the spacebar on desktop devices,{" "}
					<White>the audience is </White>drawn into the story and{" "}
					<White>
						able to switch between the past and the present,
					</White>{" "}
					as if using a time machine. <br />
					<br />
					Alongside a fisherman one has to investigate the scene of a
					crime and have to uncover clues that reveal what has
					happened the night before.
				</div>
			</div>
		</div>
		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/slider_02.jpg`,
						`/img/cases/${shorthand}/image/slider_06.jpg`,
						`/img/cases/${shorthand}/image/slider_05.jpg`,
						`/img/cases/${shorthand}/image/slider_03.jpg`,
						`/img/cases/${shorthand}/image/slider_01.jpg`,
					]}
				/>
			</div>
		</ArticleElBackgroundWrapper>

		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Award winning.
						<br />
						21.9 Mio views.
						<br />
						Accessible on all devices.
					</div>
				</div>
				<div className="section-description">
					<White>
						We developed the creative concept, film-directed both
						storylines, and created the initial tech approach
						including a functional prototype.
					</White>
					<br />
					<br />
					Kontra K was joined by a highly talented international cast.{" "}
					<White>
						Filmed at the prestigious location previously featured
						in the James Bond movie "Skyfall",
					</White>{" "}
					this interactive art piece assembles stunning visuals with
					an highly emotional narrative.
					<br />
					<br />
					<White>
						It was the centerpiece of Kontra K's album release
					</White>{" "}
					and generated a significant impact, by captivating fans and
					new audiences with its innovative and compelling
					storytelling. The interactive nature of the experience
					creates a powerful emotional connection with the audience,{" "}
					<White>
						leaving a mark as an unforgettable and unique addition
						to the music video genre.{" "}
					</White>{" "}
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					round
					alt={`Mockup of andeinerseite.video website`}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Visit Site"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVisitSite();
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">
								Filmakademie Baden-Wuerttemberg
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brand</div>
							<div className="credits-name">
								Kontra K, Four Music
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Creative Lead & <br /> Tech Prototype
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Tech Company</div>
							<div className="credits-name">UNIT9</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
