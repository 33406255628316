import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	QuoteEl,
	Quote,
	Author,
} from "../../components/Elements/ArticleEl";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import ImageElement from "../../components/Elements/ImageElement";
import VideoElement from "../../components/Elements/VideoElement";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageSlider from "../../components/Elements/ImageSlider";
import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "AmazonTakeASeat";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					A real-time spot for real-time delivery
				</Header>
				<Desc customType="desc">
					<White>This Unreal Engine-powered film showcases</White> a
					speedy and satisfying delivery experience. Viewers witness
					furniture seamlessly placed in modern loft settings,
					highlighting{" "}
					<White>Amazon's efficiency and reliability.</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? null : "33%"}
					customType="image"
					round
					flex
					backgroundPosition={"center"}
					backgroundSize={"cover"}
					ar={isMobile ? 2.4 : 3 / 4}
					src={`/img/cases/${shorthand}/image/img_04.jpg`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Lightning-fast delivery meets Real-time Filmmaking"
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>

		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={2.35}
					round
					shorthand={"amazon-preview"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Film"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>
						A funky beat and flying chairs set the tone for a unique
						and harmonious experience.{" "}
					</White>
					Customers watch in amazement as their furniture floats
					effortlessly into place in a stylish, lofty apartment and a
					cozy kitchen. <br /> <br />
					<White>
						This study investigates the collaboration between human
						creativity and real-time 3D animation and rendering
						technologies.
					</White>{" "}
					It assesses its impact on accelerating creative processes,
					improving visual fidelity, and broadening accessibility
					across digital media platforms.
					<br /> <br />
					<White>
						With its unique real-time capabilities Unreal Engine
						provided the perfect working environment for
					</White>{" "}
					fast turnaround times and{" "}
					<White>a playful creation approach. </White>{" "}
				</div>
			</div>
		</div>

		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						{" "}
						Unreal Engine.
						<br />
						Virtual Camera.
						<br />
						Real-time Filmmaking.
					</div>
				</div>
				<div className="section-description">
					<br />
					<br />
					<White>
						After an intense digital location scouting
					</White>{" "}
					through various high-quality asset libraries,{" "}
					<White>the perfect location was found</White> to showcase
					the products in the best possible light.{" "}
					<White>
						Animations of flying and floating furniture were
						carefully crafted by hand and combined with our
						human-operated virtual camera system,
					</White>{" "}
					creating an emotionally graceful viewing experience for
					every frame.
				</div>
			</div>
		</div>

		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					round
				/>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Postproduction</div>
							<div className="credits-name">
								colors made in germany
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
