import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

const sec2time = (timeInSeconds) => {
	var pad = function (num, size) {
			return ("000" + num).slice(size * -1);
		},
		time = parseFloat(timeInSeconds).toFixed(3),
		hours = Math.floor(time / 60 / 60),
		minutes = Math.floor(time / 60) % 60,
		seconds = Math.floor(time - minutes * 60),
		milliseconds = time.slice(-3);

	return (
		pad(minutes, 2) +
		":" +
		pad(seconds, 2) +
		"." +
		pad(Math.floor(milliseconds / 62) * 62, 2)
	);
};

const CompRecording = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	const curTime = useRef(0);
	const timeDisplay = useRef(null);
	const outerRing = useRef(null);

	const updateTime = () => {
		if (!timeDisplay.current) return;
		timeDisplay.current.innerText = sec2time(
			curTime.current + props.timeoffset || 0
		);
	};

	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
		// gsap.set(complicationsRef.current, { y: props.offsetY || 0 });
	};

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function

	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline();
			animationTimeline.current.add("start");
			animationTimeline.current.add(
				gsap.fromTo(
					curTime,
					{ current: 0 },
					{
						duration: props.duration,
						ease: "none",
						current: props.duration,
						onUpdate: updateTime,
						repeat: -1,
					}
				)
			);

			animationTimeline.current.add(
				gsap.fromTo(
					outerRing.current,
					{ scale: 0, autoAlpha: 1 },
					{ duration: 1.5, scale: 1.5, autoAlpha: 0, repeat: -1 }
				),
				"<"
			);
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	return (
		<div
			className={`complication-el recording${
				props.transparent ? " transparent" : ""
			}${props.align ? " align-" + props.align : ""}`}
			ref={complicationsRef}
		>
			<div className="complication-title-cnt">
				<div
					className={`complication-logo${
						props.secondary ? " ai" : " record"
					}`}
				></div>
				<div
					className={`complication-title${
						props.secondary ? " purple" : " pink"
					}`}
				>
					{props.title || "N/A"}
				</div>
			</div>
			<div className="compilation-row">
				<div
					className="complication-record-time"
					ref={timeDisplay}
				></div>
			</div>
			<div className="compilation-row center">
				<div className="record-btn">
					<div
						className={`record-btn-outer${
							props.secondary ? " purple" : " pink"
						}`}
					/>
					<div
						className={`record-btn-outer${
							props.secondary ? " purple" : " pink"
						}`}
						ref={outerRing}
					/>
					<div
						className={`record-btn-inner${
							props.secondary ? " purple" : " pink"
						}`}
					/>
				</div>
			</div>
		</div>
	);
});

export default CompRecording;
