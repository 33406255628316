import React, { useRef, useEffect, useState, useContext } from "react";

import { gsap, Power2 } from "gsap";

const BurgerIcon = React.forwardRef((props, ref) => {
	const top = useRef(null);
	const middle = useRef(null);
	const bottom = useRef(null);

	useEffect(() => {
		const duration = 0.3;
		gsap.killTweensOf(top.current);
		gsap.killTweensOf(middle.current);
		gsap.killTweensOf(bottom.current);

		if (props.open) {
			gsap.to(middle.current, { duration: duration, width: 0 });
			gsap.to(bottom.current, {
				duration: duration,
				x: 0,
				y: -7,
				rotate: -45,
				width: 25,
			});
			gsap.to(top.current, { duration: duration, y: 7, rotate: 45 });
		} else {
			gsap.to(bottom.current, {
				duration: duration,
				x: 2,
				y: 0,
				rotate: 0,
				width: 20,
			});
			gsap.to(middle.current, { duration: duration, width: 25 });
			gsap.to(top.current, { duration: duration, y: 0, rotate: 0 });
		}
	}, [props.open]);
	return (
		<div className="burger-con">
			<div className="burger-bar top" ref={top}></div>
			<div className="burger-bar middle" ref={middle}></div>
			<div className="burger-bar bottom" ref={bottom}></div>
		</div>
	);
});

export default React.memo(BurgerIcon);
