import {
	isMobile as reactIsMobile,
	isTablet as reactIsTablet,
	isAndroid as reactIsAndroid,
	isSafari as reactIsSafari,
	isFirefox as reactIsFirefox,
	isIOS as reactIsIOS,
} from "react-device-detect";

export const isMobile = reactIsMobile && !reactIsTablet;
export const isMobileOrTablet = reactIsMobile;
export const isTablet = reactIsTablet;
export const isAndroid = reactIsAndroid;
export const isSafari = reactIsSafari;
export const isFirefox = reactIsFirefox;
export const isIOS = reactIsIOS;
