import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import VideoElement from "../../components/Elements/VideoElement";
import ImageSlider from "../../components/Elements/ImageSlider";
import BtnParallax from "../../components/Elements/BtnParallax";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";

import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "PumaPlaymaker";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">A gamified sneaker</Header>
				<Desc customType="desc">
					<White>
						In honor of KICKZ's 30th anniversary in the fashion and
						basketball resale industry, they joined forces with Puma
						for an exclusive shoe collaboration.
					</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? "100%" : "500px"}
					customType="image"
					round
					flex
					backgroundSize={"cover"}
					backgroundPosition={"center"}
					ar={isMobile ? 16 / 9 : 1}
					src={
						isMobile
							? `/img/cases/${shorthand}/image/img_004.jpg`
							: `/img/cases/${shorthand}/image/img_007.jpg`
					}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>

		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"The first shoe collab in 30 years of KICKZ history!"
				</Quote>
				<Author customType="author">
					<White>- kickz.com</White>
				</Author>
			</QuoteEl>
		</QuoteSliderSlick>

		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={isMobile ? 3 / 4 : 2.35 / 1}
					round
					shorthand={"puma-preview16x9"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Film"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						A gamified campaign
					</div>
				</div>
				<div className="section-description">
					<White>The Puma Playermaker became</White> part of the
					'Mooncourt Ballin' campaign - a multibrand Fornite
					basketball tournament. Introduced as{" "}
					<White>a game-changing power-up,</White> players were able
					to experience the Playermaker firsthand. <br />
					<br />
					Puma and KICKZ sought to maintaining its intrinsic gamified
					allure while promote the Playermaker across social channels.
					To realize this vision,{" "}
					<White>
						we leveraged the real-time capabilities of Unreal
						Engine,
					</White>{" "}
					enhanced it with{" "}
					<White>
						high-fidelity simulations from Houdini, and rendered all
						within our cinematic Path Tracing pipeline
					</White>{" "}
					to achieve a captivating visual experience.
				</div>
			</div>
		</div>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Photogrammetry</Header>
			<Desc customType="desc">
				Digital twins of the different colorways were produced through{" "}
				<White>in-house photo scanning</White> and the creation of high
				and low-poly models,{" "}
				<White>
					resulting in an unparalleled flexibility and stunning beauty
					renders.
				</White>
			</Desc>
			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"mooncourt-pumascan"}
			/>
		</ArtileEl>
		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Houdini</Header>
			<Desc customType="desc">
				<White>
					We used Houdini to meticulously craft detailed particle and
					destruction effects.{" "}
				</White>
				The resulting simulations offer a tangible level of
				satisfaction,{" "}
				<White>
					impressively showcasing the Playmaker's raw power.
				</White>
			</Desc>

			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"puma-houdini"}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Unreal Engine</Header>
			<Desc customType="desc">
				Harmonizing the flexibility of{" "}
				<White>Unreal Engine's real-time workflow with</White> the
				remarkable capabilities of the{" "}
				<White>Path Tracer renderer, </White>we introduced a{" "}
				cost-effective way{" "}
				<White>
					to achieve swiftly stage scenes and visually captivating
					outcomes.
				</White>
			</Desc>
			<VideoElement
				customType="video"
				aspectratio={1370 / 1080}
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"puma-unreal"}
			/>
		</ArtileEl>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						LED Floor Photoshooting
					</div>
				</div>
				<div className="section-description">
					In addition{" "}
					<White>
						we supplied the 3D content used for the live-action
						product shooting
					</White>{" "}
					that was executed on an advanced LED basketball court
					manufactured by ASB Glass Floor.
				</div>
			</div>
		</div>
		<div className="section-div">
			<ImageSlider
				credits={
					"Photo Credit: Dennis Faßoth, Philip Olschowa, Kevin Linnecke"
				}
				data={[
					`/img/cases/puma-playmaker/image/img_002.jpg`,
					`/img/cases/puma-playmaker/image/img_003.jpg`,
					`/img/cases/puma-playmaker/image/img_004.jpg`,
					`/img/cases/puma-playmaker/image/img_005.jpg`,
				]}
			/>
		</div>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Agency</div>
							<div className="credits-name">
								Jung von Matt NERD
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brands</div>
							<div className="credits-name">KICKZ &amp; Puma</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Postproduction</div>
							<div className="credits-name">
								colors made in germany
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
