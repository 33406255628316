import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

import waveform from "../../../img/waveform.png";
import waveformBaseline from "../../../img/waveform-baseline.png";

const CompVoiceRecording = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);
	const waveformFiller = useRef(null);

	const waveformWidth = 250;
	//restart Function

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function
	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
	};
	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline({ repeat: -1 });
			animationTimeline.current.add("start");
			animationTimeline.current.add(
				gsap.fromTo(
					waveformFiller.current,
					{ attr: { x: -waveformWidth } },
					{ attr: { x: 0 }, duration: props.duration, ease: "none" }
				),
				"<"
			);
			animationTimeline.current.add(
				gsap.fromTo(
					waveformFiller.current,

					{ autoAlpha: 1 },
					{ autoAlpha: 0, duration: 0.3 }
				),
				"-=0.3"
			);
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	const parentId = complicationsRef.current?.parentNode.id;
	return (
		<div
			className={`complication-el ${
				props.transparent ? " transparent" : ""
			}${props.align ? " align-" + props.align : ""}`}
			ref={complicationsRef}
		>
			<div className="complication-title-cnt">
				<div
					className={`complication-logo${
						props.secondary ? " playback" : " microphone"
					}`}
				/>
				<div
					className={`complication-title${
						props.secondary ? " purple" : " pink"
					}`}
				>
					{props.title || "N/A"}
				</div>
			</div>
			<div className="compilation-row">
				<div className="complication-title">{props.track || "N/A"}</div>
			</div>

			<div className="compilation-row">
				<div className="comp-wave-cnt" style={{ width: waveformWidth }}>
					<svg
						id="demo"
						xmlns="http://www.w3.org/2000/svg"
						height="55px"
						width="100%"
					>
						<defs>
							<mask id={"theMask" + parentId}>
								<rect
									ref={waveformFiller}
									id="theSquare"
									x={`-${waveformWidth}`}
									y="0"
									width="100%"
									height="100%"
									fill="#fff"
								/>
							</mask>
						</defs>

						<g
							id="toBeRevealed"
							mask={"url(#theMask" + parentId + ")"}
						>
							<image href={waveform} width="100%" height="55px" />
						</g>

						<image
							href={props.secondary ? waveform : waveformBaseline}
							width="100%"
							height="55px"
							style={{ opacity: 0.3, zIndex: -1 }}
						/>
					</svg>

					{/*<div className="comp-waveform" ref={waveformFiller} />*/}
				</div>
			</div>
		</div>
	);
});

export default CompVoiceRecording;
