import namedColors from "color-name-list/dist/colornames.json";
import nearestColor from "nearest-color";
import { v4 as uuid } from "uuid";

// export const getColorName = nearestColor.from(colors);

export const getUUID = () => {
	const unique_id = uuid();
	//const small_id = unique_id.slice(0, 8);
	return unique_id;
};

const colors = namedColors.reduce(
	(o, { name, hex }) => Object.assign(o, { [name]: hex }),
	{}
);

const getColorName = nearestColor.from(colors);

export const randomColorName = () => {
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	console.log(getColorName("#" + randomColor));
	return getColorName("#" + randomColor);
};
