import React, {
	useEffect,
	useState,
	useLayoutEffect,
	useImperativeHandle,
	useRef,
} from "react";
import { gsap } from "gsap";

const Tag = ({ type, txt }) => {
	return (
		<span
			className={`${
				{
					noBg: "tag-span no-bg",
					more: "tag-span more no-bg more",
					moreAbsolute: "tag-span more no-bg more absolute",
					bg: "tag-span",
					work: "tag-span icon work",
					solution: "tag-span icon",
				}[type]
			}`}
			style={{
				pointerEvents: "none",
				...(type == "more" || type == "moreAbsolute"
					? { color: "#f10388" }
					: {}),
			}}
		>
			{txt}
			{type == "more" || type == "moreAbsolute" ? (
				<span className="moreBtnSelector">
					<span>↓</span>
					<span className={"more"} style={{ opacity: 1 }}>
						{" "}
						Learn more
					</span>
				</span>
			) : null}
		</span>
	);
};

const TagContainer = React.forwardRef((props, ref) => {
	const tagContainerRef = useRef(null);
	const [refresh, setRefresh] = useState(Date.now);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setRefresh(Date.now());
		});

		return () => {
			window.removeEventListener("resize", () => {
				setRefresh(Date.now());
			});
		};
	}, []);

	const refreshTagLayout = (external) => {
		//console.log("refreshTagLayout", external);
		let allChildren = [];
		tagContainerRef?.current?.children?.forEach((x) => {
			allChildren.push(x);
		});
		if (allChildren.length > 0) {
			let nextLine = 0;
			let lastTop = -1;

			allChildren.forEach((x, i) => {
				const rect = x.getBoundingClientRect();

				const top = rect.top;
				if (top > lastTop) {
					nextLine++;
					lastTop = top;
				}
				x.setAttribute("data-line", nextLine);
				x.setAttribute("data-width", rect.width);

				// if (i === allChildren.length - 1) {
				// 	x.setAttribute("data-more", "absolute"); // more button is last item, set data-line -1
				// } else if (i === allChildren.length - 2) {
				// 	x.setAttribute("data-more", "inline"); // more button is last item, set data-line -1
				// }
			});
		}

		if (!external) {
			props.refreshVisibility();
		}

		// gsap.killTweensOf(".moreBtnSelector");
		// gsap.fromTo(
		// 	".moreBtnSelector",
		// 	{ opacity: 1 },
		// 	{
		// 		duration: 1,
		// 		repeat: -1,
		// 		opacity: 0,
		// 		yoyo: true,
		// 		ease: "Power1.easeInOut",
		// 	},
		// );
	};

	useLayoutEffect(() => {
		refreshTagLayout(false);
	}, [refresh, props.solution]);

	const getRef = () => {
		return tagContainerRef;
	};

	// useImperativeHandle(ref, () => ({
	// 	refreshTagLayout,
	// 	children,
	// }));

	useImperativeHandle(ref, () => {
		return {
			refreshTagLayout(external) {
				refreshTagLayout(external);
			},
			getRef() {
				return getRef();
			},
		};
	});

	return (
		<div
			key={`tag-container-${props.solution?.name}`}
			className="section-tag-container article"
			ref={tagContainerRef}
			style={{ justifyContent: "center" }}
		>
			<Tag
				key={`tag-type-${props.solution?.name}`}
				type={props.solution?.type}
				txt={props.solution?.type == "solution" ? "solution" : "work"}
			/>
			{props.solution?.tags?.split(",").map((x, i) => {
				return (
					<Tag
						key={`tag-${i}-${x}`}
						type={x?.trimStart().startsWith("#") ? "noBg" : "bg"}
						txt={x}
					/>
				);
			})}
		</div>
	);
});

export default TagContainer;
