import React, { createRef, Component } from "react";
import { gsap, Power2, Power0, Expo, TimelineMax } from "gsap";
import { Link } from "react-router-dom";
import { FpsView } from "react-fps";

import Videoplayer from "./components/videoPlayer";
import BtnParallax from "./components/Elements/BtnParallax";
import TopologyBackground from "./components/Elements/TopologyBackground";
import LandingBtn from "./components/landingBtn";
import { viewsEnum } from "./enums";

import { isMobile, isMobileOrTablet } from "./hooks/isMobile";
// import { Helmet } from "react-helmet-async";

import Logo from "./img/preloader/Logo.png";
import Ring_00 from "./img/preloader/Ring_00.png";
import Ring_01 from "./img/preloader/Ring_01.png";
import Ring_02 from "./img/preloader/Ring_02.png";
import Ring_03 from "./img/preloader/Ring_03.png";
import Ring_04 from "./img/preloader/Ring_04.png";
import Ring_05 from "./img/preloader/Ring_05.png";
import Ring_06 from "./img/preloader/Ring_06.png";
import Ring_07 from "./img/preloader/Ring_07.png";
import Ring_08 from "./img/preloader/Ring_08.png";

import menu_background from "./img/menu_background.svg";

export default class LandingPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      view: -1,
      forceplay: -1,
      videoStarted: false,
      window: { ww: -1, wh: -1 },
      startTime: Date.now(),
      isMobileOrTablet: false,
      appView: viewsEnum.LANDING,
      preloadFinished: false,
      preloadAnimFinished: false,
      activeHover: false,
    };

    this.mouseMoveEl = this.mouseMoveEl.bind(this);
    this.mouseLeaveEl = this.mouseLeaveEl.bind(this);
    this.mouseEnterEl = this.mouseEnterEl.bind(this);

    this.callParallaxBtn = this.callParallaxBtn.bind(this);

    this.callParallax = this.callParallax.bind(this);
    this.parallaxIt = this.parallaxIt.bind(this);

    this.initPlay = this.initPlay.bind(this);
    this.preloadDone = this.preloadDone.bind(this);

    this.checkIdle = this.checkIdle.bind(this);

    this.mousePos = { x: 0, y: 0 };
    this.lastMove = Date.now();
    this.activeHover = false;
    this.hideTrans = false;

    this.pageContent = createRef();
    this.vidContainer = createRef();
    this.overlayContainer = createRef();
    this.vidPlayer = createRef();

    this.landingBtnRef = createRef();

    this.landingBtnRef02 = createRef();

    // this.learnMoreBtnRef = createRef();

    this.headerBtnRef = createRef();

    this.preloaderLogoCover = createRef();
    this.preloaderMain = createRef();

    this.refAwardContainer = createRef();

    this.gradRefAds = createRef();
    this.gradRefSocial = createRef();
    this.gradRefExperiences = createRef();
    this.gradRefRealtime = createRef();

    this.activeHoverEl = null;
    this.btnActive = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== this.prevProps) {
      //HIDE VIEW
      if (
        prevProps.appView !== this.props.appView &&
        this.props.appView !== this.state.appView
      ) {
        this.hideTrans = true;
        gsap.killTweensOf([
          this.vidContainer.current,
          this.overlayContainer.current,
          // this.allRefs,

          this.allAwards.current,
          this.landingBtnRef.current.innerRef.current,
          this.landingBtnRef02.current.innerRef.current,
          this.preloaderMain.current.parentElement,
          this.preloaderMain.current,
          this.preloaderLogoCover.current,
        ]);
        // console.log(this.state.appView, "hide");
        gsap.to(this.vidContainer.current, { duration: 0.75, opacity: 0 });

        gsap.to([this.allRefs, this.preloaderMain.current.parentElement], {
          duration: 0.4,
          opacity: 0,
          // yPercent: -40,
          // stagger: 0.025,
          delay: 0,
          overwrite: true,
        });

        gsap.to(this.allAwards, {
          duration: 0.5,
          opacity: 0,
          // yPercent: -40,
          // stagger: 0.025,
          delay: 0,
          overwrite: true,
        });

        gsap.to(
          [
            this.landingBtnRef.current.innerRef.current,
            this.landingBtnRef02.current.innerRef.current,
          ],
          {
            onStart: () => {
              this.btnActive = false;
            },
            duration: 0.5,
            opacity: 0,
            // yPercent: -40,
            overwrite: true,
          },
        );
      }

      if (this.state.window !== this.props.window) {
        this.setState({
          window: this.props.window,
          isMobileOrTablet: this.props.window.ww <= 767,
        });
      }
    }
  }

  componentWillUnmount() {
    console.log("debug unmount landing");
    gsap.ticker.remove(this.checkIdle);
    this.autoPlayTimeline.kill();
    clearTimeout(this.fallbackTimeout);
  }

  rebuildTimeLineAndStart() {
    if (this.autoPlayTimeline) {
      this.autoPlayTimeline.kill();
    }

    const allGradientRefs = [
      this.gradRefAds.current,
      this.gradRefSocial.current,
      this.gradRefExperiences.current,
      this.gradRefRealtime.current,
    ];

    const resetGradient = (target) => {
      gsap.set(target, {
        display: "flex",
        position: "static",
        backgroundImage:
          "linear-gradient(270deg,rgba(31, 137, 219, 1) 0%,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
        backgroundPosition: "0% 0%",
        backgroundSize: "200% 100%",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        color: "transparent",
      });
    };
    const addEntry = (target, viewIndex) => {
      // resetGradient(target)
      // gsap.set(target, {
      //   backgroundImage:
      //     "linear-gradient(270deg,rgba(31, 137, 219, 1) 0%,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
      //   backgroundPosition: "0% 0%",
      //   backgroundSize: "200% 100%",
      //   backgroundClip: "text",
      //   color: "transparent",
      // });
      this.autoPlayTimeline
        .call(() => {
          this.setView(viewIndex, true);

          resetGradient(target);
        })
        .to(target, 3, {
          backgroundPosition: "-100% 0%",
          ease: Power0,
        })
        .to(target, 0.2, {
          backgroundPosition: "-200% 0%",
          ease: Power0,
        });
    };

    this.autoPlayTimeline = gsap.timeline({
      paused: true,
      repeat: -1,

      ease: Power0,
      onStart: () => {
        if (!this.state.videoStarted) this.autoPlayTimeline.pause();

        allGradientRefs.forEach((entry) => {
          resetGradient(entry);
        });

        // gsap.set([this.mainEls, this.allIndex], {
        //   backgroundImage: "linear-gradient(white,white)",
        // });
      },
    });

    //

    allGradientRefs.forEach((entry, index) => {
      addEntry(entry, index + 1);
    });

    //todo add reset

    this.autoPlayTimeline.play();
  }

  componentDidMount() {
    console.log("debug mount landing");
    this.props.changeView(this.state.appView);
    this.allRefs =
      this.pageContent.current.querySelectorAll(".landing-text-el");

    this.allAwards = this.pageContent.current.querySelectorAll(".aw_base");

    this.mainEls = this.pageContent.current.querySelectorAll(".main-el");

    gsap.set(this.allRefs, { opacity: 0, yPercent: -50 });
    gsap.set(this.allAwards, { opacity: 0, yPercent: 35 });
    gsap.set(
      [
        this.landingBtnRef.current.innerRef.current,
        this.landingBtnRef02.current.innerRef.current,
      ],
      {
        opacity: 0,
        yPercent: 35,
      },
    );

    this.allIndex = this.pageContent.current.querySelectorAll(".index");
    // gsap.set(this.allIndex, { opacity: 0, yPercent: -35 });

    //set preloader
    gsap.set(this.preloaderLogoCover.current, { yPercent: 100 });

    // gsap.killTweensOf(".moreBtnSelector");
    // gsap.fromTo(
    //   ".moreBtnSelector",
    //   { opacity: 1 },
    //   {
    //     duration: 1,
    //     repeat: -1,
    //     opacity: 0,
    //     yoyo: true,
    //     ease: "Power1.easeInOut",
    //   },
    // );

    // gsap.set(this.learnMoreBtnRef.current, { autoAlpha: 0 });

    // this.props.changeHeaderState("typo");
    this.rebuildTimeLineAndStart();
    gsap.ticker.add(this.checkIdle);

    let delay = 0;
    let duration = 0.75;
    gsap.fromTo(
      [".preloader-ring"],
      duration,
      { rotation: 0 },
      {
        scale: 1.2,
        rotation: 360,
        yPercent: 0,
        stagger: (index, target, list) => {
          delay = delay + 0.05;
          // your custom logic here. Return the delay from the start (not between each)
          return index * 0.05;
        },
        repeat: -1,
        yoyo: true,
        ease: Expo.easeInOut,
      },
    );
    gsap.set(this.overlayContainer.current, { autoAlpha: 0 });

    gsap.to(".preloader-logo", duration, {
      scale: 0.5,
      yPercent: 0,
      rotation: 360,

      repeat: -1,
      repeatDelay: delay - 0.05,
      yoyo: true,
      ease: Expo.easeInOut,
    });

    //store path values:

    // gsap.fromTo(
    //   "path.landingRings",
    //   2,
    //   { scale: 0 },
    //   { delay: 1, stagger: 0.1, scale: 1, ease: Expo.easeOut },
    // );
    // gsap.to("path.landingRings", 1, {
    //   delay: 2,
    //   stagger: 0.1,
    //   autoAlpha: 0.25,
    //   yoyo: true,
    //   repeat: -1,
    // });

    this.fallbackTimeout = setTimeout(() => {
      if (!this.state.preloadFinished) {
        // console.log("preload Failed");
        this.initPlay("fallbackTimeout");
      } else {
        // console.log("preload already Done");
      }
    }, 3000);

    // gsap.to("path", 1, {
    //   stagger: 0.025,

    //   autoAlpha: 0.25,
    //   yoyo: true,
    //   repeat: -1,
    //   ease: Power0,
    // });
  }

  isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  checkIdle() {
    const now = Date.now();

    if (
      (!this.activeHover && now - this.lastMove > 1800) ||
      (!this.activeHover && this.props.deviceType) === "touchOnly"
    ) {
      this.startAutoPlay();
    } else if (this.activeHover) {
      this.lastMove = now;
    }
  }

  restartTimeline(dispatcher) {
    //console.log(dispatcher);

    // Restart video on desktop
    if (!isMobileOrTablet && dispatcher === "initPlay") {
      this.resetVideoTime(dispatcher);
    }
    this.rebuildTimeLineAndStart();
  }

  resetVideoTime(dispatcher) {
    // console.log(dispatcher, "resetVideoTime");

    document.querySelector("#landing-video").currentTime = 0;
  }
  startAutoPlay() {
    if (this.autoPlayTimeline.paused() && this.state.videoStarted) {
      console.log("restartTimeline");
      this.restartTimeline("startAutoPlay");
    }
  }

  stopAutoPlay() {
    this.lastMove = Date.now();
    this.autoPlayTimeline.pause();

    gsap.killTweensOf(this.autoPlayTimeline);

    //todo reset gradient to white
  }

  preloadDone() {
    this.setState({ preloadAnimFinished: true });

    //todo fade in index's

    gsap.to(this.allIndex, {
      opacity: 1,
      duration: 2,

      delay: 1,
      stagger: 0.05,
      ease: Expo.easeOut,
      onStart: () => {
        this.setState({ preloadAnimFinished: true });
      },
    });

    gsap.to(
      [
        this.allRefs,
        this.landingBtnRef.current.innerRef.current,
        this.landingBtnRef02.current.innerRef.current,
      ],
      {
        duration: 2,
        opacity: 1,
        yPercent: 0,
        stagger: 0.05,
        delay: 0,
        ease: Expo.easeOut,
      },
    );
    gsap.to([this.allAwards], {
      duration: 2,
      opacity: 1,
      yPercent: 0,
      stagger: 0.05,
      delay: 0,
      ease: Expo.easeOut,
    });

    //gsap.to(this.learnMoreBtnRef.current, { delay: 2.5, autoAlpha: 1 });
  }

  mouseMoveEl(e) {
    e.preventDefault();
    if (
      !this.state.videoStarted ||
      !this.state.preloadAnimFinished ||
      this.hideTrans ||
      this.activeHoverEl !== e.target ||
      this.props.deviceType === "touchOnly"
    )
      return;

    this.lastMove = Date.now();
    if (this.isTouchDevice()) return;
    this.callParallax(e, e.target);
  }

  initPlay(dispatcher = "N/A") {
    // console.log("playing");

    if (dispatcher === "video" && !this.state.videoStarted) {
      this.setState({ videoStarted: true });
      this.restartTimeline("initPlay");
    }
    clearTimeout(this.fallbackTimeout);

    if (this.state.preloadFinished) return;
    this.setState({ preloadFinished: true });
    gsap.to(".preloader", 0.25, {
      delay: 0.25,
      scale: 3,
      autoAlpha: 0,

      onComplete: () => {
        if (this.preloaderMain.current) {
          gsap.set(this.preloaderMain.current.parentElement, {
            display: "none",
          });
          gsap.set(".preloader", { opacity: 0 });
          this.preloadDone(dispatcher === "video");
          this.props.initLoad();
        }
      },
    });
    gsap.to(".preloader-logo", 0.25, { delay: 0.25, autoAlpha: 0 });
    gsap.to(".preloader-ring", 0.25, { autoAlpha: 0, stagger: 0.05 });

    gsap.to(
      this.overlayContainer.current,
      2,

      { autoAlpha: 0.15 },
    );
    // gsap.to(this.preloaderLogoCover.current, 0.5, {
    //   yPercent: 0,
    //   delay: 0.3,
    //   onComplete: () => {
    //     if (this.preloaderMain.current) {
    //       gsap.to(this.preloaderMain.current, 0.5, {
    //         yPercent: 100,
    //         onComplete: () => {
    //           if (this.preloaderMain.current) {
    //             gsap.set(this.preloaderMain.current.parentElement, {
    //               display: "none",
    //             });
    //             gsap.set(".preloader", { opacity: 0 });
    //             this.preloadDone(dispatcher === "video");
    //             this.props.initLoad();
    //           }
    //         },
    //       });
    //     }
    //   },
    // });
  }

  mouseLeaveEl(e) {
    e.preventDefault();
    if (
      !this.state.videoStarted ||
      !this.state.preloadAnimFinished ||
      this.hideTrans
    )
      return;

    this.activeHover = false;
    this.activeHoverEl = null;

    this.props.changeCursorState("load");

    const targetQuery = e.target.querySelector(".landing-text-el");
    gsap.to(targetQuery, {
      //color: "#fff",
      duration: 0.3,
      x: 0,
      y: 0,
      scale: 1,
      backgroundPosition: "0% 0%",
    });
    //todo resetGradient

    // gsap.to(targetQuery.querySelectorAll(".landing-text-el"), {
    //   duration: 0.3,
    //   backgroundPosition: "0% 0%",
    // });

    gsap.to(this.vidContainer.current, {
      opacity: 0,
      duration: 0.15,
      delay: 0.3,
      onComplete: () => {
        // gsap.delayedCall(1, this.resetVideoTime, ["mouseLeaveEl"]);
      },
    });
    gsap.to(this.allRefs, { opacity: 1 });

    //reset bar overlay
    if (this.props.deviceType !== "touchOnly") this.btnActive = true;
    gsap.to(
      [
        this.landingBtnRef.current.innerRef.current,
        this.landingBtnRef02.current.innerRef.current,
      ],
      {
        backgroundColor: "rgba(241,3,136,0)",
        opacity: 1,
        duration: 0.3,
        rotationX: 0,
        rotationY: 0,
        x: 0,
        y: 0,
        scale: 1,
        overwrite: true,
        onComplete: () => {
          if (this.props.deviceType === "touchOnly") this.btnActive = true;
          this.setState({ activeHover: false });
        },
      },
    );

    gsap.to(this.overlayContainer.current, {
      overwrite: true,
      delay: 0.5,
      duration: 1,
      opacity: 0.15,
    });

    gsap.to(this.refAwardContainer.current, {
      duration: 0.3,
      opacity: 1,
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
      scale: 1,
      overwrite: true,
    });

    // gsap.to(this.learnMoreBtnRef.current, {
    //   opacity: 1,
    // });
  }

  mouseEnterEl(e) {
    e.preventDefault();
    if (
      !this.state.videoStarted ||
      !this.state.preloadAnimFinished ||
      this.hideTrans
    )
      return;
    // console.log("mouseEnterEL");

    /*this.landingBtnRefLink.current.classList.add("disabled-link");*/
    this.props.changeCursorState("active");
    this.setState({ activeHover: true });
    this.activeHover = true;
    this.activeHoverEl = e.target;

    this.stopAutoPlay();

    const targetQuery = e.target.querySelector(".landing-text-el");

    gsap.to(targetQuery, {
      duration: 0.3,
      scale: 1.1,
      opacity: 1,
      x: 1,
      y: 1,
      overwrite: true,
    });

    //todo mark selected
    gsap.to(targetQuery, 0.1, { backgroundPosition: "100% 0%" });

    // gsap.to(targetQuery.querySelectorAll(".main-el,.index"), {
    //   duration: 0.3,
    //   backgroundImage: "linear-gradient(rgb(241,3,136),rgb(241,3,136))",
    // });

    //fadeout all others
    this.allRefs.forEach((queryEl) => {
      if (targetQuery !== queryEl) {
        gsap.to(queryEl, {
          duration: 0.1,
          opacity: 0.1,
          scale: 1,
          x: 0,
          y: 0,
          backgroundPosition: "0% 0%",
          overwrite: true,
        });
      }
    });

    gsap.to(
      [
        this.landingBtnRef.current.innerRef.current,
        this.landingBtnRef02.current.innerRef.current,
      ],
      {
        onStart: () => {
          this.btnActive = false;
        },
        duration: 0.3,
        opacity: 0,
        backgroundColor: "rgba(241,3,136,0)",

        rotationX: 0,
        rotationY: 0,
        x: 0,
        y: 0,
        overwrite: true,
      },
    );

    gsap.to(this.overlayContainer.current, {
      duration: 0.3,
      overwrite: true,
      opacity: 0,
    });

    gsap.to(this.refAwardContainer.current, {
      duration: 0.3,
      opacity: 0,
      backgroundColor: "rgba(241,3,136,0)",
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
    });

    // gsap.to(this.learnMoreBtnRef.current, {
    //   opacity: 0,
    // });

    this.setView(e.target.dataset.view);
  }

  setView(view, autoplay = false) {
    if (typeof view !== "undefined" && !this.hideTrans) {
      const opacity = autoplay ? 0.25 : 1;

      gsap.to(this.vidContainer.current, {
        opacity: opacity,
        duration: 0.25,
        overwrite: true,
      });
    } else {
      gsap.to(this.vidContainer.current, {
        opacity: 0,
        duration: 0.25,
        overwrite: true,
      });
    }
    this.setState({
      view: view,
      forceplay: Date.now(),
    });
  }

  callParallax(e, parent) {
    this.parallaxIt(e, parent, parent.querySelector(".landing-text-el"), 20);
  }

  callParallaxBtn(e, parent) {
    this.parallaxIt(e, parent, this.landingBtnRef.current.innerRef.current, 20);
  }

  parallaxIt(e, parent, target, movement) {
    var boundingRect = parent.getBoundingClientRect();
    const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
    const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
    var relX = pageX - boundingRect.left;
    var relY = pageY - boundingRect.top;

    var movementX =
      ((relX - boundingRect.width / 2) / boundingRect.width) * movement;
    var movementY =
      ((relY - boundingRect.height / 2) / boundingRect.height) * movement;
    gsap.to(target, {
      duration: 0.3,
      x: movementX,
      y: movementY,
      rotationX: (movementY / 3) * 2.5,
      rotationY: (-movementX / 3) * 2.5,
      ease: Power2.easeOut,
    });
  }

  preventOnActiveHover(e) {}

  render() {
    //console.log(this.props.currentPathname === "/get-in-touch");
    return (
      <React.Fragment>
        {/*        <Helmet>
          <title>home - Stunnıng.</title>

          <meta
            name="description"
            content="We craft tomorrow's ads, social and events in real-time today. Stunning real-time studio - Hamburg, Germany."
          />
        </Helmet>*/}

        <div
          className="preloader"
          style={{
            width: isMobile ? "100px" : "128px",
            height: isMobile ? "100px" : "128px",
            marginLeft: isMobile ? "-50px" : "-64px",
            marginTop: isMobile ? "-50px" : "-64px",
            borderRadius: 100,
            padding: 8,
            borderColor: "#fffcfa",
            borderWidth: "2px",
            borderStyle: "solid",
          }}
        >
          <div
            style={{
              borderRadius: 100,
              overflow: "hidden",
              position: "relative",
              width: "100%",
              height: "100%",
              background: "black",
            }}
          >
            {[
              Ring_00,
              Ring_01,
              Ring_02,
              Ring_03,
              Ring_04,
              Ring_05,
              Ring_06,

              Ring_07,
            ].map((url, i) => (
              <div
                key={"preloader-ring-" + i}
                className={"preloader-ring"}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundPosition: "center",

                  backgroundSize: "200%",
                  backgroundImage: `url("${url}")`,

                  width: "100%",
                  height: "100%",
                }}
              />
            ))}

            <div
              className={"preloader-logo"}
              id={"preloader-logo"}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundPosition: "center",

                backgroundSize: "200%",
                backgroundImage: `url("${Logo}")`,

                width: "100%",
                height: "100%",
              }}
            />

            <div
              className="s-logo"
              style={{
                display: "none",
                // background: "rgba(0,0,0,0)",
                backgroundSize: "contain",
                //backgroundImage: `url("${logo}")`,
                borderRadius: 0,
              }}
              ref={this.preloaderMain}
            >
              <div
                className="s-logo-cover"
                style={{
                  display: "none",
                  borderRadius: 0,
                  background: "rgba(0,0,0,1)",
                }}
                ref={this.preloaderLogoCover}
              ></div>
            </div>
          </div>
        </div>

        {isMobileOrTablet ? (
          <div
            className="page-content-container-spacer"
            style={{ maxHeight: "0%" }}
          />
        ) : null}

        <div ref={this.pageContent}>
          <div className="page-content-container">
            <div className="landing-text-el nohover left">We craft tmrws</div>
            <div className="landing-text-el spacer">/</div>
          </div>
          <div className="page-content-container">
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={1}
            >
              <div className="landing-text-el" ref={this.gradRefAds}>
                <div className="index">01</div>
                <div className="main-el">ads</div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>

            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={2}
            >
              <div className="landing-text-el" ref={this.gradRefSocial}>
                <div className="index">02</div>
                <div className="main-el">social</div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={3}
            >
              <div className="landing-text-el" ref={this.gradRefExperiences}>
                <div className="main-el">&#38;</div>
                <div className="index">03</div>
                <div className="main-el">
                  {isMobileOrTablet ? "xps" : "experiences"}
                </div>
              </div>
            </div>

            <div
              className="landing-text-el spacer"
              // style={{ visibility: "hidden" }}
            >
              /
            </div>
          </div>
          <div className="page-content-container">
            <div className="landing-text-el nohover left">in</div>
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={4}
            >
              <div className="landing-text-el" ref={this.gradRefRealtime}>
                <div className="index">04</div>
                <div className="main-el">real-time</div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>

            <div className="landing-text-el">
              <div className="main-el higher">today.</div>
            </div>
          </div>
          <div className="page-content-container landing">
            <Link
              to={{
                pathname: "/cases/work",
              }}
              style={{
                pointerEvents: "auto",
              }}
              onClick={(e) => {
                if (this.state.activeHover) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            >
              <BtnParallax
                blockcursorchange={this.state.activeHover}
                addclass={"mobilefixed"}
                landing
                text={"See Cases"}
                ref={this.landingBtnRef}
              />
            </Link>
            <Link
              style={{
                pointerEvents: "auto",
              }}
              onClick={(e) => {
                if (this.state.activeHover) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                document
                  .querySelector("#welcome-video")
                  .scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              <BtnParallax
                blockcursorchange={this.state.activeHover}
                addclass={"mobilefixed"}
                landing
                filled
                text={"About Stunnıng."}
                ref={this.landingBtnRef02}
              />
            </Link>
          </div>
          <div className="page-content-container">
            <div className="award-container" ref={this.refAwardContainer}>
              <div className="aw_base aw_lovie"></div>
              <div className="aw_base aw_clio"></div>
              <div className="aw_base aw_adc"></div>
              <div className="aw_base aw_fwa"></div>
              <div className="aw_base aw_13th"></div>
              <div className="aw_base aw_nyf"></div>
              <div className="aw_base aw_newmedia"></div>
              <div className="aw_base aw_aww"></div>
              <div className="aw_base aw_dda"></div>
            </div>
          </div>
        </div>

        <div className="background-video">
          <div
            style={{
              opacity: 0,
              overflow: "hidden",
            }}
            className="virtual-influencer w-embed"
            ref={this.vidContainer}
          >
            <Videoplayer
              fullAccess={this.props.fullAccess}
              key={this.props.cKey + "current_videoPlayer"}
              forcehold={this.props.forcehold}
              windowProps={this.state.window}
              forceplay={this.state.forceplay}
              loop={this.state.vidLoop}
              view={this.state.view}
              ref={this.vidPlayer}
              initPlay={this.initPlay}
            />
          </div>
        </div>

        <TopologyBackground
          ref={this.overlayContainer}
          id={"landing_bg_01"}
          width={4}
        />
        {/*<FpsView />*/}
      </React.Fragment>
    );
  }
}

export const LandingPageMemo = React.memo(LandingPage);
