import React, { useEffect, useRef, useState, useCallback } from "react";
import { gsap, Expo } from "gsap";

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { viewsEnum } from "./enums";

import { Helmet } from "react-helmet-async";

import Obfuscate from "react-obfuscate";

import ImagesLoaded from "react-images-loaded";

import Img0 from "./img/emo_c.png";
import Img1 from "./img/emo_e.png";
import Img2 from "./img/emo_g.png";
import Img3 from "./img/emo_j.png";
import Img4 from "./img/emo_m.png";
import Img5 from "./img/emo_r.png";

import Img6 from "./img/ofb_circle_v022x.png";

import { isMobile } from "./hooks/isMobile";

import { useLocation, useNavigate } from "react-router-dom";

gsap.registerPlugin(MotionPathPlugin);

const Contact = React.forwardRef((props, ref) => {
	let location = useLocation();

	const navigate = useNavigate();
	const handleOnClickGoStart = useCallback(
		() => navigate(location?.state?.from || "/", { replace: true }),
		[navigate, location?.state?.from],
	);

	const [preloadFinished, setPreloadFinished] = useState(false);

	const realtimeForCreators = useRef(null);
	const realtimeForCreatorsDesc = useRef(null);
	const contactBox = useRef(null);

	const fallbackTimeout = useRef(null);

	useEffect(() => {
		// console.log("mount contatct");
		props.changeView(viewsEnum.CONTACT);

		const rotationTime = 8;
		const emoCTween = gsap.to(".emo-c", {
			duration: rotationTime,
			repeat: -1,
			ease: "power0",
			motionPath: {
				path: "#path",
				align: "#path",

				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoCTween.seek((1 * rotationTime) / 6);
		const emoRTween = gsap.to(".emo-r", {
			duration: rotationTime,
			repeat: -1,
			ease: "power0",
			motionPath: {
				path: "#path",
				align: "#path",
				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoRTween.seek((4 * rotationTime) / 6);
		const emoMTween = gsap.to(".emo-m", {
			duration: rotationTime,
			repeat: -1,
			ease: "power0",
			motionPath: {
				path: "#path",
				align: "#path",
				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoMTween.seek((2 * rotationTime) / 6);
		const emoJTween = gsap.to(".emo-j", {
			duration: rotationTime,
			repeat: -1,
			ease: "power0",
			motionPath: {
				path: "#path",
				align: "#path",
				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoJTween.seek((3 * rotationTime) / 6);

		const emoETween = gsap.to(".emo-e", {
			duration: rotationTime,
			repeat: -1,

			ease: "power0",
			motionPath: {
				path: "#path",

				align: "#path",
				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoETween.seek((5 * rotationTime) / 6);
		const emoGTween = gsap.to(".emo-g", {
			duration: rotationTime,
			repeat: -1,
			ease: "power0",
			motionPath: {
				path: "#path",
				align: "#path",
				autoRotate: false,
				alignOrigin: [0.5, 0.5],
			},
		});
		emoGTween.seek((6 * rotationTime) / 6);

		gsap.to("#contact-box-bg", {
			duration: rotationTime * 3,
			repeat: -1,
			ease: "power0",
			rotation: -360,
		});

		gsap.set(realtimeForCreators.current, {
			opacity: 0,
			yPercent: -35,
		});
		gsap.set(realtimeForCreatorsDesc.current, {
			opacity: 0,
			yPercent: -35,
		});
		gsap.set(contactBox.current, { opacity: 0, yPercent: 40 });
		props.changeHeaderState("logo");

		props.initLoad();
		fallbackTimeout.current = setTimeout(() => {
			if (!preloadFinished) {
				preloadDone();
				// console.log("preload failed");
			} else {
				// console.log("preload alreay done");
			}
		}, 2000);

		let backToText = "Start";
		if (location?.state?.solution?.name) {
			backToText = location?.state?.solution?.name;
		}
		props.changeHeaderState("logo", {
			customBack: {
				icon: "arrow",
				text: `Go back`,
				fn: handleOnClickGoStart,
			},
		});

		return () => {
			clearTimeout(fallbackTimeout.current);
		};
	}, []);

	const preloadDone = () => {
		clearTimeout(fallbackTimeout.current);
		if (preloadFinished) return;
		setPreloadFinished(true);

		gsap.to(
			[
				realtimeForCreators.current,
				realtimeForCreatorsDesc.current,
				contactBox.current,
			],
			{
				duration: 2,
				opacity: 1,
				yPercent: 0,
				stagger: 0.05,
				delay: 0.3,
				ease: Expo.easeOut,
			},
		);
	};

	console.log(location);

	return (
		<>
			<Helmet>
				<title>contact - Stunnıng.</title>

				<meta
					name="description"
					content="Let's create something together. Start by saying hello."
				/>
			</Helmet>
			<ImagesLoaded
				elementType={"div"}
				done={preloadDone}
				background=".image" // true or child selector
			>
				<img src={Img0} alt={"hover-img"} />
				<img src={Img1} alt={"hover-img"} />
				<img src={Img2} alt={"hover-img"} />
				<img src={Img3} alt={"hover-img"} />
				<img src={Img4} alt={"hover-img"} />
				<img src={Img5} alt={"hover-img"} />
				<img src={Img6} alt={"hover-img"} />
			</ImagesLoaded>

			<div className="page-content scroll">
				<div
					className="landing-scroll-el"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						minHeight: "100%",
					}}
				>
					<div
						className="section-div no-p"
						style={{ paddingTop: "100px", paddingBottom: "100px" }}
					>
						<div className="section-content contact">
							<div
								className="section-hero center"
								ref={realtimeForCreators}
							>
								<div className="section-title-sub grad-txt">
									{!isMobile ? (
										"Get in Touch"
									) : (
										<div
											className="contact-box-logo"
											style={{ padding: 0, margin: 0 }}
										/>
									)}
								</div>
							</div>
							<div
								className="section-description center contact"
								ref={realtimeForCreatorsDesc}
							>
								We believe in the power of collaboration and
								offer custom solutions to supercharge your
								business with real-time today. <br />
								<br />
								<span className="text-white">Let's go.</span>
							</div>
						</div>
						<div className="section-div center no-p">
							<div
								className="contact-box"
								ref={contactBox}
								style={{ opacity: 0, paddingTop: 50 }}
							>
								<div className="contact-box-bg black" />
								<div
									id="contact-box-bg"
									className="contact-box-bg"
								>
									<svg viewBox="50 50 100 100">
										<g>
											<path
												id="path"
												d="M 50, 100 a 50,50 0 1,1 100,0 a 50,50 0 1,1 -100,0"
											/>
										</g>
									</svg>
								</div>
								<div className="contact-center-content">
									{!isMobile ? (
										<div className="contact-box-logo" />
									) : null}
									<div className="contact-box-text">
										<span className="open-for-busi">
											<Obfuscate tel="+ 49 40 7291 62 31" />
										</span>
										<br />
										{/*	<span className="open-for-busi">
											<Obfuscate href="https://wa.me/4915736408332">
												WhatsApp
											</Obfuscate>
										</span>
										<br />*/}
										<Obfuscate email="cheers@stunning.studio" />
										<br />
										Hamburg, Germany
									</div>
								</div>
								<div className="emo-c" />
								<div className="emo-m" />
								<div className="emo-j" />
								<div className="emo-r" />
								<div className="emo-g" />
								<div className="emo-e" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export default Contact;
