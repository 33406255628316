import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

const sec2time = (timeInSeconds) => {
	return parseInt(timeInSeconds) + "s";
};

const CompCountdown = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	const curTime = useRef(0);
	const timeDisplay = useRef(null);
	const outerRing = useRef(null);

	const updateTime = () => {
		if (!timeDisplay.current) return;
		timeDisplay.current.innerText = sec2time(
			-curTime.current + props.timeoffset || 0,
		);
	};

	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
		// gsap.set(complicationsRef.current, { y: props.offsetY || 0 });
	};

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function

	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline();
			animationTimeline.current.add("start");
			animationTimeline.current.add(
				gsap.fromTo(
					curTime,
					{ current: 0 },
					{
						duration: props.duration,
						ease: "none",
						current: props.duration,
						onUpdate: updateTime,
						repeat: -1,
					},
				),
			);
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	return (
		<>
			<div
				className={`complication-el${
					props.transparent ? " transparent" : ""
				}${props.align ? " align-" + props.align : ""}${
					props.nobg ? " nobg" : ""
				}`}
				ref={complicationsRef}
			>
				<div className="complication-title-cnt center">
					<div className="complication-leaderboard-title">
						{props.title || "N/A"}
					</div>
				</div>
				<div
					className="complication-leaderboard-title white"
					style={{ textAlign: "center" }}
					ref={timeDisplay}
				/>
			</div>
		</>
	);
});

export default CompCountdown;
