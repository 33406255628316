import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	QuoteEl,
	Quote,
	Author,
} from "../../components/Elements/ArticleEl";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import ImageElement from "../../components/Elements/ImageElement";
import VideoElement from "../../components/Elements/VideoElement";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageSlider from "../../components/Elements/ImageSlider";
import { isMobile } from "../../hooks/isMobile";

import cases from "../cases/cases.json";

const componentName = "VodafoneEltonJohn";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">
					A spatial live-concert experience
				</Header>
				<Desc customType="desc">
					<White>
						Elton John's farewell tour showcased a connected
						augmented reality experience,{" "}
					</White>{" "}
					powered by Vodafone UK, as a heartfelt tribute to COVID-19
					service workers,
					<White>
						{" "}
						highlighted by a captivating rendition of Elton's famous
						song "I'm Still Standing".
					</White>
				</Desc>

				<CustomImage
					maxWidth={isMobile ? "45%" : "25%"}
					customType="image"
					round
					flex
					backgroundPosition={"center"}
					backgroundSize={"cover"}
					outline
					ar={1}
					borderRadiusOutline={"25%"}
					src={`/img/cases/${shorthand}/image/icon.png`}
				/>
			</ArtileEl>
		</ArticleElBackgroundWrapper>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"When you look at the first shows I did at the Troubadour
					back in the 70s, to what’s possible now, it’s completely
					transformed, and I’m fascinated about what this technology
					could mean for the future."
				</Quote>
				<Author customType="author">
					<White>- Elton John</White>
				</Author>
			</QuoteEl>
			{/*		<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"Elton John and Vodafone wow Hyde Park crowd with augmented
					reality. "
				</Quote>
				<Author customType="author">
					<White>- forbes.com</White>
				</Author>
			</QuoteEl>*/}
		</QuoteSliderSlick>
		<div className="section-div">
			<div
				className="section-img"
				style={{
					pointerEvents: "all",
					width: "100%",
					marginTop: 0,
					paddingTop: 0,
				}}
			>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_09.jpg`}
					round
					aspectratio={isMobile ? 3 / 4 : null}
				/>

				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Watch Case"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">The Idea</div>
				</div>
				<div className="section-description">
					<White>A unique tech-led tribute</White> to the service
					workers who kept the world safe and running during the COVID
					pandemic. During Elton John's impressive concert in Hyde
					Park, the audience had the unique opporunity to experience
					an AR enhanced performance of "I'm Still Standing",
					<White>
						{" "}
						featuring virtual dancers from the NHS, Royal Mail, and
						public transportation sector.
					</White>
				</div>
			</div>
		</div>
		<ArticleElBackgroundWrapper
			id={"slider" + shorthand}
			variant={"article_slider"}
		>
			<div className="section-div no-p">
				<ImageSlider
					data={[
						`/img/cases/${shorthand}/image/img_03.jpg`,
						`/img/cases/${shorthand}/image/img_06.jpg`,
						`/img/cases/${shorthand}/image/img_04.jpg`,
						`/img/cases/${shorthand}/image/img_07.jpg`,

						`/img/cases/${shorthand}/image/img_09.jpg`,
					]}
				/>
			</div>
		</ArticleElBackgroundWrapper>
		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						A legend. Enhanced with AR.
					</div>
				</div>
				<div className="section-description">
					The performance embodies the essence of Elton John's iconic
					'I'm Still Standing' music video, paying homage to its
					enduring legacy.
				</div>
			</div>
		</div>

		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">High Fidelity Motion Capture</Header>
			<Desc customType="desc">
				We crafted a bespoke dance performance, capturing over{" "}
				<White>300 minutes of exclusive motion data</White> with the
				exceptional skills of two professional dancers.
			</Desc>

			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"vodafone-motioncapture"}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">Live & In Sync</Header>
			<Desc customType="desc">
				The experience was{" "}
				<White>live and synchronized with the on-stage</White>{" "}
				performance, creating a connected experience for all.
			</Desc>

			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"vodafone-insync"}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-half"} small-header small-desc>
			<Header customType="header">Accessible for everyone</Header>
			<Desc customType="desc">
				We engineered a{" "}
				<White>hassle-free AR mode that requires no calibration</White>{" "}
				and works in any lighting, ensuring a seamless experience for
				users of all tech backgrounds on various devices.
			</Desc>

			<VideoElement
				customType="video"
				round
				outline
				// customscale={0.5}
				// centerh
				shrink
				shorthand={"vodafone-accessible"}
			/>
		</ArtileEl>

		<div className="section-div">
			<div
				className="section-content bigger left"
				style={{ flexDirection: "column" }}
			>
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Elton John.
						<br />
						Live Concert.
						<br />
						Augumented Reality.
					</div>
				</div>
				<div className="section-description">
					<White>
						The return of live music was a special moment for
						everyone, and it's crucial that we acknowledge those who
						made it possible.{" "}
					</White>
					"I'm Still Standing" is a song about overcoming adversity
					and coming back stronger.
				</div>
			</div>
			<div className="section-img" style={{ width: "100%" }}>
				<ImageElement
					src={`/img/cases/${shorthand}/image/img_08.jpg`}
					round
					aspectratio={isMobile ? 3 / 4 : null}
				/>
			</div>
		</div>

		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">A collaboration of</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Agency</div>
							<div className="credits-name">Fuse</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Brand</div>
							<div className="credits-name">Vodafone UK</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								Production Company
							</div>
							<div className="credits-name">UNIT9</div>
						</div>
					</div>

					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">
								<p style={{ margin: 0, padding: 0 }}>
									Creative Direction &
								</p>{" "}
								Motion Capture
							</div>
							<div className="credits-name">Stunning Studio</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
