import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
	Quote,
	Author,
	QuoteEl,
} from "../../components/Elements/ArticleEl";

import cases from "../cases/cases.json";

import { getAdditionalVideo } from "../../data/additional-video/getAdditionalVideoHelper";
import BtnParallax from "../../components/Elements/BtnParallax";
import QuoteSliderSlick from "../../components/Elements/QuoteSliderSlick";
import ImageElement from "../../components/Elements/ImageElement";
import ImageSlider from "../../components/Elements/ImageSlider";
import VideoElement from "../../components/Elements/VideoElement";
import { isMobile } from "../../hooks/isMobile";
import { ArticleElBackgroundWrapper } from "../../components/Elements/ArticleElBackgroundWrapper";

const componentName = "MotionCapture";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default (handleVideoModalOpen) => (
	<>
		<QuoteSliderSlick>
			<QuoteEl size="m" no-p-quote no-p fullfill>
				<Quote customType="quote">
					"With mocap you can create realistic and engaging digital
					characters that will grab the attention of the audience."
				</Quote>
			</QuoteEl>
		</QuoteSliderSlick>

		<ArticleElBackgroundWrapper id={shorthand} variant={"article_top"}>
			<ArtileEl
				type={"text-right-half"}
				size={"m"}
				center
				style={{
					paddingTop: 0,
					paddingBottom: 0,
					margin: 0,
				}}
			>
				<Header customType="header">Rich animations. Fast.</Header>
				<Desc customType="desc">
					We use cutting-edge technology to craft{" "}
					<White>high-fidelity performance capture.</White> Utilizing
					advanced Motion Capture Suits alongside AI technology, we{" "}
					<White>
						create hyper-realistic characters and movements{" "}
					</White>
					to leave a lasting impression on the audience.
				</Desc>
				<CustomImage
					customType="image"
					src={`/img/cases/${shorthand}/image/img_01.png`}
				/>
			</ArtileEl>{" "}
		</ArticleElBackgroundWrapper>

		<div className="section-div">
			<div
				className="section-img"
				style={{ pointerEvents: "all", width: "100%", marginTop: 0 }}
			>
				<VideoElement
					aspectratio={isMobile ? 3 / 4 : 2.35 / 1}
					round
					shorthand={"realtime-postervideo"}
				/>
				<div className="section-vid-overlay-play">
					<div
						className="vid-overlay-flex-center"
						style={{ paddingBottom: "20px" }}
					>
						<BtnParallax
							landing
							filled
							text={"Behind the Scenes"}
							filmicon
							addclass={"btn-parallax-sol-pseudo"}
							small
							onclick={() => {
								handleVideoModalOpen(true, false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-left-onethird"} small-header small-desc>
			<Header customType="header">Move. Free.</Header>
			<Desc customType="desc">
				Our mocap suits record highly precise and accurate data - they
				have state-of-the-art sensors placed throughout the suit to
				track the movement of the actors' joints.{" "}
				<White>
					No need for markers and dozens of cameras. Location
					independent. In and Outdoors.
				</White>
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-onethird"} small-header small-desc>
			<Header customType="header">Say Cheese!</Header>
			<Desc customType="desc">
				Using lightweight camera rigs, we can{" "}
				<White>
					record the actor's expressions simultaneously or separately{" "}
				</White>
				from the body capture. This creates flexibility and scalability
				for every project. Adding a smile, not only on your characters
				face.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-half"} small-header small-desc>
			<Header customType="header">
				Now. <br />
				Nower. <br />
				<Pink>Real-time</Pink>.
			</Header>
			<Desc customType="desc">
				The motion-capture data can be displayed on a screen as it is
				being captured. This allows a more efficient and flexible
				animation process, as{" "}
				<White>
					actors and directors can see the results of their work in
					real-time
				</White>{" "}
				and make adjustments as needed.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>
		<div className="section-div">
			<div className="section-content bigger left credits">
				<div className="credit-headline">
					<div className="section-title-sub">Technology in focus</div>
					<div className="credit-divider"></div>
				</div>
				<div className="credits">
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Motion Capture</div>
							<div className="credits-name">
								Xsens Link, Markerless Motion Capture
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Face Capture</div>
							<div className="credits-name">
								AR Kit, Facegood 4D
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Sofware</div>
							<div className="credits-name">
								Xsens Animate, Unreal Engine, Motionbuilder,
								Character Creator & iClone
							</div>
						</div>
					</div>
					<div className="credits-column">
						<div className="credit-block">
							<div className="credits-title">Camera Tracking</div>
							<div className="credits-name">
								Stunning AR Cam App, Vive
							</div>
						</div>
					</div>
				</div>
				<div className="credit-headline"></div>
			</div>
		</div>
	</>
);
