import React, {
	useEffect,
	useRef,
	useLayoutEffect,
	useImperativeHandle,
	useState,
	useCallback,
	memo,
} from "react";

import { gsap } from "gsap";

import { Expo } from "gsap/all";

import useResizeObserver from "@react-hook/resize-observer";

import * as solutionArticles from "../data/articles";

import { Link } from "react-router-dom";
import BtnParallax from "./Elements/BtnParallax";
import TagContainer from "./Elements/TagContainer";

import { useLocation } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";

import { isMobileOrTablet } from "../hooks/isMobile";

HTMLCollection.prototype.forEach = Array.prototype.forEach;
const useSize = (target) => {
	const [size, setSize] = React.useState();

	React.useLayoutEffect(() => {
		setSize(target.current.getBoundingClientRect());
	}, [target]);

	// Where the magic happens
	useResizeObserver(target, (entry) => setSize(entry.contentRect));
	return size;
};

const FullscreenSolution = React.forwardRef((props, ref) => {
	let query = useQuery();
	let debug = query.get("debug");

	const numberOfVisibileLines = useRef(isMobileOrTablet ? 3 : 2);

	const oneLineMobile = useRef(false);

	let location = useLocation();

	const [article, setArticle] = useState(null);
	const [solution, setSolution] = useState([]);

	const headerLineHeight = useRef(-1);
	const bottomOffset = useRef(-1);
	const bottomOffsetCalc = useRef(-1);

	const textVisibility = useRef(false);

	const txtContentRef = useRef(null);
	const header = useRef(null);
	const title = useRef(null);

	const tagContainer = useRef(null);

	const size = useSize(txtContentRef);

	const curIndexRef = useRef(null);

	const removeEntryFromArr = (arr, entry) => {
		const index = arr.indexOf(entry);
		if (index > -1) {
			// only splice arr when item is found
			console.log("found", entry);
			arr.splice(index, 1); // 2nd parameter means remove one item only
		}
	};

	const killAllTweensOfArr = (arr) => {
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				if (x) {
					gsap.killTweensOf(x);
				}
			});
		}
	};

	const refreshVisibility = (
		bool = textVisibility.current,
		instant = true,
	) => {
		//console.log("refreshVisibility", bool);

		tagContainer.current?.refreshTagLayout?.(true);
		let curTags = [];

		curTags =
			tagContainer.current
				?.getRef()
				?.current?.querySelectorAll(".tag-span") ?? [];

		const firstLine = [];
		const secondLine = [];
		const restLine = [];
		const moreBtnTag = [];
		const moreBtnTagAbsolute = [];

		curTags.forEach((tag) => {
			const tagLine = tag.dataset.line;
			const tagMore = tag.dataset.more;
			if (tag.dataset.more === "inline") {
				// moreBtnTag.push(tag);
			} else if (tag.dataset.more === "absolute") {
				// moreBtnTagAbsolute.push(tag);
			} else if (tagLine == 1) {
				firstLine.push(tag);
			} else if (tagLine == 2) {
				restLine.push(tag);
			} /*else if (tagLine == -1) {
				moreBtnTag.push(tag);
			}*/ else {
				restLine.push(tag);
			}
		});

		let fadeAwayTags = [];

		let allVisTags = [];
		if (isMobileOrTablet && !oneLineMobile.current) {
			if (moreBtnTag?.[0]?.dataset.line > 2) {
				//only remove tags if more tag is out of bound
				allVisTags = [...firstLine, ...secondLine];
			}
		} else {
			if (moreBtnTag?.[0]?.dataset.line > 1) {
				//only remove tags if more tag is out of bound
				allVisTags = [...firstLine];
			}
		}

		let accSize = 0;
		const moreBtnWidth = parseFloat(moreBtnTag?.[0]?.dataset.width);
		for (let i = allVisTags.length - 1; i >= 0; i--) {
			if (accSize < moreBtnWidth) {
				const curTag = allVisTags[i];
				accSize += parseFloat(curTag.dataset.width);

				fadeAwayTags.push(curTag);
			}
		}

		// const moreAbsoluteVis = fadeAwayTags.length > 0;
		// if (moreAbsoluteVis) {
		// 	const lastFadeItem = fadeAwayTags[fadeAwayTags.length - 1];
		// 	const offset = {
		// 		left: lastFadeItem.offsetLeft,
		// 		top: lastFadeItem.offsetTop,
		// 	};
		// 	gsap.set(moreBtnTagAbsolute, {
		// 		left: offset.left,
		// 		top: offset.top,
		// 	});
		// }

		gsap.killTweensOf(".section-div");

		if (bool) {
			gsap.to(".section-div", {
				delay: 0,
				duration: instant ? 0 : 0.3,
				opacity: 1,
			});
			killAllTweensOfArr(firstLine);
			killAllTweensOfArr(secondLine);
			killAllTweensOfArr(restLine);
			killAllTweensOfArr(fadeAwayTags);

			[
				...(firstLine.length ? firstLine : []),
				...(secondLine.length ? secondLine : []),
				...(restLine.length ? restLine : []),
				...(fadeAwayTags.length ? fadeAwayTags : []),
			].forEach((item) => {
				if (item) {
					gsap.to(item, {
						autoAlpha: 1,
						duration: instant ? 0 : 0.1,
					});
				}
			});

			// killAllTweensOfArr(moreBtnTag);
			// killAllTweensOfArr(moreBtnTagAbsolute);
			// gsap.to([moreBtnTag, moreBtnTagAbsolute], {
			// 	autoAlpha: 0,
			// 	duration: instant ? 0 : 0.1,
			// });
			return;
		} else {
			gsap.to(".section-div:not(.sol-header-div)", {
				delay: 0.0,
				duration: instant ? 0 : 0.1,
				opacity: 0,
			});
			killAllTweensOfArr(firstLine);
			killAllTweensOfArr(secondLine);
			killAllTweensOfArr(restLine);
			killAllTweensOfArr(fadeAwayTags);

			[
				...(firstLine.length > 0 ? firstLine : []),
				...(secondLine.length > 0 &&
				isMobileOrTablet &&
				!oneLineMobile.current
					? secondLine
					: []),
			].forEach((item) => {
				if (item) {
					gsap.to(item, {
						autoAlpha: 1,
						duration: instant ? 0 : 0.1,
					});
				}
			});

			[
				...(restLine.length ? restLine : []),
				...(secondLine.length &&
				(!isMobileOrTablet || oneLineMobile.current)
					? secondLine
					: []),
				...(fadeAwayTags.length ? fadeAwayTags : []),
			].forEach((item) => {
				if (item) {
					gsap.to(item, {
						//background: "red",
						autoAlpha: 0,
						duration: instant ? 0 : 0.1,
					});
				}
			});

			// killAllTweensOfArr(moreBtnTag);
			// killAllTweensOfArr(moreBtnTagAbsolute);

			// if (moreAbsoluteVis) {
			// 	gsap.to([moreBtnTag], {
			// 		autoAlpha: 0,
			// 		duration: instant ? 0 : 0.1,
			// 	});
			// 	gsap.to([moreBtnTagAbsolute], {
			// 		autoAlpha: 1,
			// 		duration: instant ? 0 : 0.1,
			// 	});
			// } else {
			// 	if (moreBtnTag.length) {
			// 		gsap.to([moreBtnTag], {
			// 			autoAlpha: 1,
			// 			duration: instant ? 0 : 0.1,
			// 		});
			// 	}
			// 	if (moreBtnTagAbsolute.length) {
			// 		gsap.to([moreBtnTagAbsolute], {
			// 			autoAlpha: 0,
			// 			duration: instant ? 0 : 0.1,
			// 		});
			// 	}
			// }
			return;
		}
	};

	useLayoutEffect(() => {
		// if (lastSize.current == size) return;
		// lastSize.current = size;
		// console.log("size change");

		headerLineHeight.current = gsap.getProperty(
			header.current,
			"lineHeight",
		);

		if (
			title.current.getBoundingClientRect().height >
			headerLineHeight.current
		) {
			oneLineMobile.current = true;
		} else {
			oneLineMobile.current = false;
		}

		bottomOffsetCalc.current =
			props.isPortraitMode || props.windowSize.vw < 767
				? 70
				: (props.windowSize.vh -
						props.windowSize.vw * 0.3654843 * (9 / 16)) /
				  2 /
				  2;

		bottomOffset.current = props.windowSize.vh - bottomOffsetCalc.current;

		document.documentElement.style.setProperty(
			"--bottomOffset",
			`${bottomOffsetCalc.current}px`,
		);
		document.documentElement.style.setProperty(
			"--bottomOffsetCalc",
			`${bottomOffset.current}px`,
		);

		document.documentElement.style.setProperty(
			"--headerLineHeight",
			`${headerLineHeight.current}px`,
		);

		document.documentElement.style.setProperty(
			"--numberOfVisibileLines",
			`${numberOfVisibileLines.current}`,
		);

		if (props.isPortraitMode) {
			document.documentElement.style.setProperty(
				"--sectionDivPointerEvents",
				`none`,
			);
		} else {
			document.documentElement.style.setProperty(
				"--sectionDivPointerEvents",
				`auto`,
			);
		}

		refreshVisibility(textVisibility.current, true);

		//disable pointerEvents in PortraitMode
	}, [size, props.windowSize, solution]);

	useImperativeHandle(
		ref,
		() => {
			return {
				handleClickMoreBtn() {
					handleClickMoreBtn();
				},
				textVisibility(bool) {
					if (bool !== textVisibility.current) {
						textVisibility.current = bool;
						refreshVisibility(textVisibility.current, false);
					}
				},
				initText() {
					gsap.to(".section-div:not(.sol-header-div)", {
						delay: 0.0,
						duration: 0,
						opacity: 0,
					});
				},
			};
		},
		[],
	);

	useEffect(() => {
		if (props.solution) {
			if (props.solution.component !== article) {
				setArticle(props.solution);
				setSolution(props.solution);

				//debug scrolltop 0
				if (!debug) {
					props.fullscreenText_v2.current.scrollTop = 0;
				}
			}
			curIndexRef.current = props.index;
		}
	}, [props.solution]);

	const handleClickMoreBtn = () => {
		props?.fullscreenText_v2?.current?.scrollTo?.({
			top:
				(bottomOffset.current -
					numberOfVisibileLines.current * headerLineHeight.current -
					40) /
				2,
			behavior: "smooth",
		});
	};

	const RouterItem = ({
		position,
		description,
		titlesub,
		btntext = "",
		onclick = () => {},
		btnalt = false,
		image = "",
	}) => (
		<div className={`router-item ${position}`}>
			<div className="section-description router">{description}</div>
			<div className="section-title-sub router">{titlesub}</div>

			{btntext.length ? (
				<BtnParallax
					onclick={onclick || null}
					text={btntext}
					variant={btnalt ? "backArrow" : null}
					// alt={btnalt}
				/>
			) : null}

			<div className="section-router-spacer" />
			<div className={`router-img${image.length ? " " + image : ""}`} />
		</div>
	);

	return (
		<div
			className="fs-txt-content"
			ref={txtContentRef}
			onClick={(e) => e.preventDefault()}
			style={{
				pointerEvents: !props.isPortraitMode ? "auto" : "none",
				overflow: !props.overlayInTrans ? "" : "hidden",
			}}
		>
			<div
				className="top-filler"
				style={{
					width: "100%",
					minHeight: `${
						parseFloat(
							getComputedStyle(
								document.documentElement,
							).getPropertyValue("--bottomOffsetCalc"),
						) -
						numberOfVisibileLines.current *
							parseFloat(
								getComputedStyle(
									document.documentElement,
								).getPropertyValue("--headerLineHeight"),
							) -
						0
					}px`,
				}}
			/>

			<div
				className={`section-div no-p sol-header-div${
					isMobileOrTablet ? " center" : " center"
				}`}
				style={{
					pointerEvents: "auto",
					display: "flex",
				}}
				onClick={handleClickMoreBtn}
				ref={header}
			>
				<div
					className="section-content"
					style={{ pointerEvents: "none" }}
				>
					<div className="section-title-sub" ref={title}>
						<span key={"text_el_"} className={`text-white-header`}>
							<div style={{ display: "inline-block" }}>
								<span className="text-white-header-index">
									{curIndexRef.current + 1 < 10
										? "0" + (curIndexRef.current + 1)
										: curIndexRef.current + 1}
								</span>
								{solution?.name}
							</div>
						</span>
					</div>
					<TagContainer
						// handleClickMoreBtn={handleClickMoreBtn}
						solution={solution}
						refreshVisibility={refreshVisibility}
						ref={tagContainer}
					/>
				</div>
			</div>

			{article
				? solutionArticles[`${article.component}`]?.(
						props.handleVideoModalOpen,
						props.handleVisitSite,
				  )
				: null}

			<div className="section-div bigger">
				<div className="router-wrapper">
					<div className="router-row">
						{
							{
								all: !props.isPortraitMode ? (
									<>
										<RouterItem
											position="left"
											description="Learn more"
											titlesub="Get an insight into the solutions we offer."
											btntext="Solutions"
											btnalt={false}
											image={""}
											onclick={() => {
												if (!props.isPortraitMode)
													props.handleSlideExit();
												props.setNewFilter("solution");
											}}
										/>

										<RouterItem
											position="right"
											description="See more"
											titlesub="Lean back and explore our work."
											btntext="Work"
											btnalt={false}
											image={"work"}
											onclick={() => {
												if (!props.isPortraitMode)
													props.handleSlideExit();
												props.setNewFilter("work");
											}}
										/>
									</>
								) : (
									<>
										<RouterItem
											position="left"
											description="Discover more"
											titlesub="Swipe to discover more solutions and work."
											image={"swipe"}
										/>
										<RouterItem
											position="right"
											description="Learn more"
											titlesub="Get an insight into the solutions we offer."
											btntext="Solutions"
											btnalt={false}
											image={""}
											onclick={() => {
												if (!props.isPortraitMode)
													props.handleSlideExit();
												props.setNewFilter("solution");
											}}
										/>
										<RouterItem
											position="right"
											description="See more"
											titlesub="Lean back and explore our work."
											btntext="Work"
											btnalt={false}
											image={"work"}
											onclick={() => {
												if (!props.isPortraitMode)
													props.handleSlideExit();
												props.setNewFilter("work");
											}}
										/>
									</>
								),
								solution: (
									<>
										{!props.isPortraitMode ? (
											<RouterItem
												position="left"
												description="Learn more"
												titlesub="Go back and discover more solutions."
												btntext="Solutions"
												btnalt={true}
												image={""}
												onclick={props.handleSlideExit}
											/>
										) : (
											<RouterItem
												position="left"
												description="Learn more"
												titlesub="Swipe to discover more solutions."
												image={"swipe"}
											/>
										)}

										<RouterItem
											position="right"
											description="See more"
											titlesub="Lean back and explore our work."
											btntext="Work"
											btnalt={false}
											image={"work"}
											onclick={() => {
												props.handleSlideExit();
												props.setNewFilter("work");
											}}
										/>
									</>
								),
								work: (
									<>
										{!props.isPortraitMode ? (
											<RouterItem
												position="left"
												description="See more"
												titlesub="Go back and discover more work."
												btntext="Work"
												btnalt={true}
												image={"work"}
												onclick={props.handleSlideExit}
											/>
										) : (
											<RouterItem
												position="left"
												description="See more"
												titlesub="Swipe to discover more work."
												image={"swipe"}
											/>
										)}

										<RouterItem
											position="right"
											description="Learn more"
											titlesub="Get an insight into the solutions we offer."
											btntext="Solutions"
											btnalt={false}
											image={""}
											onclick={() => {
												props.handleSlideExit();
												props.setNewFilter("solution");
											}}
										/>
									</>
								),
							}[props.caseFilter]
						}
					</div>
					<div className="router-item full bottom">
						<div className="section-title-sub router">
							Got a project in mind?
						</div>
						<div className="section-content">
							<div className="section-description">
								No matter what point you're at in your project
								or campaign,{" "}
								<span className="text-white">
									we're eager to learn about your vision
								</span>
								. We strongly believe in the benefits of working
								together and we're committed{" "}
								<span className="text-white">
									to support you
								</span>{" "}
								finding the execution that suits your creative
								best.
							</div>
						</div>

						<Link
							to={{
								pathname: "/get-in-touch",
							}}
							state={{
								from: location?.pathname || "/",
							}}
							style={{
								pointerEvents: "auto",
							}}
						>
							<BtnParallax text={"Get in Touch"} />
						</Link>
					</div>
				</div>
				{/*	<div
					className="filler"
					style={{
						minHeight: `${bottomOffsetCalc.current}px`,
					}}
				/>*/}
			</div>
		</div>
	);
});

export default memo(FullscreenSolution);
