import React from "react";

const ImageElement = React.forwardRef((props, ref) => {
	return (
		<>
			<img
				src={props.src}
				style={{
					width: "100%",
					...(props.round && { borderRadius: 25 }),
					...(props.aspectratio && {
						aspectRatio: props.aspectratio,
						objectFit: "cover",
					}),
					...props.style,
				}}
				alt={props.alt}
			/>
		</>
	);
});

export default React.memo(ImageElement);
