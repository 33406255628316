import React, { Component, useState, useRef, memo, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import { gsap } from "gsap";

import blurryBg from "../../img/blurry_bg.svg";

import { isFirefox } from "../../hooks/isMobile";

const QuoteSlider = React.forwardRef((props, ref) => {
  const mainSlider = useRef(null);
  const bulletPoints = useRef(null);

  const [hover, setHover] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);
  const activeSlideRef = useRef(0);

  const keySlider =
    "qslider-key_" +
      props.children?.[0]?.props?.children?.[0]?.props?.children ?? "";

  const init = () => {
    startProgressAnimation("useEffect", 0);
    setActiveSlide(0);
    activeSlideRef.current = 0;
    setHover(false);
  };

  useEffect(() => {
    init();
  }, [keySlider]);

  const pauseAutoplay = (evt) => {
    pauseFill();

    setHover(true);
    mainSlider.current.slickPause();
  };

  const pauseFill = () => {
    gsap.killTweensOf(".slider-bullet-fill");
  };
  const resetFill = () => {
    pauseFill();
    //gsap.to("#slider-bullet-fill-" + activeSlide, 0, { xPercent: -110 });
    gsap.to(".slider-bullet-fill", 0, { xPercent: -110 });
  };
  const resumeAutoplay = () => {
    startProgressAnimation("resumeAutoplay");

    setHover(false);
    mainSlider.current.slickPlay();
  };

  const startProgressAnimation = (
    dispatcher,
    current = activeSlideRef.current,
  ) => {
    //console.log(dispatcher, current);
    resetFill();
    //gsap.to(".slider-bullet-fill", 0, { xPercent: -110 });
    gsap.fromTo(
      "#slider-bullet-fill-" + current,
      5,
      {
        xPercent: -110,
        autoAlpha: 1,
      },
      { xPercent: 0, ease: "none" },
    );
  };

  const beforeChange = (current, next) => {
    //console.log("nextSlide will be: " + next);
    activeSlideRef.current = next;
    setActiveSlide(next);

    resetFill();
    startProgressAnimation("beforeChange", next);
  };

  const afterChange = (current) => {};

  return (
    <div
      className="section-div"
      {...props}
      style={{
        position: "relative",
        maxWidth: "none",
        ...props.style,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "300%",

          backgroundImage: `url("${blurryBg}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          overflowX: "hidden",

          transform: "translateY(0%) translateX(0%)",
          opacity: isFirefox ? 0.25 : 0.75,
        }}
      ></div>
      <div
        className="tab-wrapper"
        style={{
          pointerEvents: "all",
          flexDirection: "column",
          padding: 0,
          margin: 0,
        }}
        onTouchMove={pauseAutoplay}
        onMouseMove={pauseAutoplay}
        onMouseEnter={pauseAutoplay}
        onMouseLeave={resumeAutoplay}
        onTouchEnd={resumeAutoplay}
        onClick={pauseAutoplay}
        onTouchStart={pauseAutoplay}
        ref={ref}
      >
        {/*<div className="glow-bg left" style={{ opacity: 0.25 }} />*/}

        <div className="tab-mainslider quote" style={{ maxWidth: "none" }}>
          <Slider
            key={keySlider}
            ref={mainSlider}
            arrows={false}
            autoplay={true}
            autoplaySpeed={5000}
            vertical={false}
            verticalSwiping={false}
            beforeChange={beforeChange}
            afterChange={afterChange}
          >
            {React.Children.map(props.children, (entry, i) => (
              <div
                key={"quote-slide" + i}
                className="preventHorizontalSwipe"
                style={{ pointerEvents: "none" }}
              >
                {entry}
              </div>
            ))}
          </Slider>
        </div>

        <div
          className="btn-parallax-container slider"
          style={{
            ...(typeof props.children.length === "undefined" && {
              display: "none",
            }),
          }}
        >
          <div className="btn-parallax landing no-outline slider">
            <div
              className="slider-bullet-points-cnt"
              style={
                {
                  // borderStyle: "solid",
                  // borderWidth: "3px",
                  // borderColor: "white",
                  // padding: "10px",
                  // borderRadius: 100,
                }
              }
            >
              {React.Children.map(props.children, (entry, i) => (
                <div
                  key={`quoteslider-bullet` + i}
                  style={{
                    pointerEvents: "all",
                  }}
                  className={`slider-bullet-container${
                    i == activeSlide ? " active" : ""
                  }`}
                  onClick={() => {
                    mainSlider.current.slickGoTo(i);
                  }}
                >
                  <div
                    className={`slider-bullet${
                      i == activeSlide && hover ? " hover" : ""
                    }`}
                    style={{ pointerEvents: "none" }}
                  >
                    <div
                      id={`slider-bullet-fill-${i}`}
                      className={`slider-bullet-fill`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default memo(QuoteSlider);
