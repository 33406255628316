import React, { Component, createRef } from "react";
import { useEffectOnce, destroyVideo } from "../hooks/useEffectOnce";
import { gsap } from "gsap";

import { isMobileOrTablet, isFirefox } from "../hooks/isMobile";

import "video-react/dist/video-react.css";

import { getAdditionalVideo } from "../data/additional-video/getAdditionalVideoHelper";

export default class PlayerControlExample extends Component {
  constructor(props, context) {
    super(props, context);
    this.newRef = React.createRef();

    this.state = { video: null };

    this.panVideo = this.panVideo.bind(this);
    this.videoPlayer = createRef();

    this.onDurationChange = this.onDurationChange.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onSuspend = this.onSuspend.bind(this);

    this.scale = { w: -1, h: -1 };
    this.curView = 1;
    this.duration = -1;
    this.initPlay = false;

    this.lowBat = false;
  }

  getRef() {
    return this.videoPlayer;
  }

  getLandingVideo(firefox = false, fullAccess = false) {
    return isMobileOrTablet
      ? getAdditionalVideo("landing-welcome_9x16", "1440p")
      : getAdditionalVideo("landing-welcome_16x9", firefox ? "1080p" : "2160p");
  }

  componentDidMount() {
    console.log("debug mount videoPlayer");


    // console.log(getAdditionalVideo("landing-welcome_16x9", "2160p"));
    /*    window.addEventListener("resize", this.onResize);
    this.onResize();*/
    // const isFirefox = typeof InstallTrigger !== "undefined";

    const isFullAccess =
      this.props.fullAccess ||
      window.location?.pathname?.startsWith("/welcome");

    setTimeout(() => {
      this.videoPlayer.current.src = this.getLandingVideo(
        isFirefox,
        isFullAccess,
      );
    }, 500);
    this.panVideo();
  }

  onTimeUpdate(e) {
    const curTime = this.videoPlayer.current.currentTime;
    if (this.duration > 0 && curTime + 0.5 > this.duration) {
      // console.log("loop", curTime);
      this.videoPlayer.current.currentTime = 0;
    }
    if (!this.initPlay) {
      this.initPlay = true;
      this.props.initPlay("video");

      gsap.to(this.videoPlayer.current, 0.5, { autoAlpha: 1 });
    }
  }

  onDurationChange(e) {
    const duration = e.target.duration;
    if (!isNaN(duration) && duration > 2) {
      this.duration = duration;
    }
  }

  onSuspend() {
    this.lowBat = true;
  }
  panVideo() {
    switch (this.curView) {
      case 1: {
        if (isMobileOrTablet) {
          this.videoPlayer.current.style.transform = `translate(${-12.5}%, ${-50}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${-12.5}%, ${-50}%)`;
        } else {
          this.videoPlayer.current.style.transform = `translate(${-25}%, ${-25}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${-25}%, ${-25}%)`;
        }

        break;
      }
      case 2: {
        if (isMobileOrTablet) {
          this.videoPlayer.current.style.transform = `translate(${
            -12.5 - 25
          }%, ${-50}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${
            -12.5 - 25
          }%, ${-50}%)`;
        } else {
          this.videoPlayer.current.style.transform = `translate(${
            -25 - 50
          }%, ${-25}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${
            -25 - 50
          }%, ${-25}%)`;
        }

        break;
      }
      case 3: {
        if (isMobileOrTablet) {
          this.videoPlayer.current.style.transform = `translate(${
            -12.5 - 50
          }%, ${-50}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${
            -12.5 - 50
          }%, ${-50}%)`;
        } else {
          this.videoPlayer.current.style.transform = `translate(${-25}%, ${
            -25 - 50
          }%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${-25}%, ${
            -25 - 50
          }%)`;
        }

        break;
      }
      case 4: {
        if (isMobileOrTablet) {
          this.videoPlayer.current.style.transform = `translate(${
            -12.5 - 75
          }%, ${-50}%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${
            -12.5 - 75
          }%, ${-50}%)`;
        } else {
          this.videoPlayer.current.style.transform = `translate(${-25 - 50}%, ${
            -25 - 50
          }%)`;
          this.videoPlayer.current.style.webkitTransform = `translate(${
            -25 - 50
          }%, ${-25 - 50}%)`;
        }

        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  componentWillUnmount() {
    // console.log("debug unmount videoPlayer");
    destroyVideo(this.videoPlayer.current);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState !== this.state) {
    }

    if (this.props !== prevProps) {
      if (this.props.windowProps !== prevProps.windowProps) {
        this.panVideo();
      }

      if (this.props.forceplay !== prevProps.forceplay) {
        this.videoPlayer.current.play();
      }

      if (this.props.forceHold !== prevProps.forceHold) {
        if (this.props.forceHold) {
          this.videoPlayer.current.pause();
        } else {
          this.videoPlayer.current.play();
        }
      }
      if (this.props.view !== prevProps.view) {
        // console.log(this.props.view);

        const curView = this.props.view;

        if (typeof curView !== "undefined") {
          this.curView = parseInt(curView, 10);
          // console.log(curView);
          this.panVideo();
        }
      }
    }
  }

  render() {
    return (
      <video
        className={`landing-video${isMobileOrTablet ? " mobile" : ""}`}
        id="landing-video"
        onDurationChange={this.onDurationChange}
        onTimeUpdate={this.onTimeUpdate}
        onSuspend={this.onSuspend}
        playsInline
        muted
        autoPlay
        loop
        ref={this.videoPlayer}
      />
    );
  }
}
